import { Fragment, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Stack, Text, styled, useTheme } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { ObituarySearchForm } from '@ltvco/refresh-lib/v1';
import { LocalFlorist } from '@mui/icons-material';

const TextWithIcon = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,

  svg: {
    marginRight: theme.spacing(1),
  },
}));

export function ObituarySearchPage() {
  const { isMobile } = useScreenSize();
  const theme = useTheme();
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);

  const [searchParams] = useSearchParams();

  trackEvent('view obituary search page', 'view', 'obituary search view');

  function trackGA4() {
    trackEventGA4({
      event_name: 'report_search',
      report_type: 'obituary',
      search_by: 'obituary',
      search_from: `obituary_search_page`,
    });
  }

  return (
    <Fragment>
      <Stack gap={2}>
        <TextWithIcon variant="h1">
          <LocalFlorist sx={{ color: theme.palette.primary.main}} fontSize="large" /> Obituary
          Search
        </TextWithIcon>
        <ObituarySearchForm
          miniView={isMobile}
          trackEvent={trackGA4}
          defaults={searchParams}
        />
      </Stack>
    </Fragment>
  );
}
