import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const StrengthsWeaknessesWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'stretch',
  width: '95%',
  marginTop: '20px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StrengthsWeaknessesContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  width: '100%',
  border: '1px solid lightgray',
  borderRadius: '4px',
  padding: '16px',
  margin: '8px 8px 8px 0',
}));

export const StrengthsWeaknessesUnorderedList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: '24px',
}));

export const StrengthsWeaknessesListItem = styled('li')(({ theme }) => ({
  marginTop: '16px',
}));
