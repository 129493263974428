import { Text, Grid, Card, styled } from '@ltvco/refresh-lib/theme';
import {
  unclaimedMoneyFaq,
  unclaimedMoneyHelpfulTips,
} from './unclaimedMoneyConstants';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  HelpfulTips,
  Faq,
  UnclaimedMoneyHeader,
  SearchNewUnclaimedMoneyForm,
} from '@ltvco/refresh-lib/v1';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#F9F7FF',
  border: '0.5px solid',
  borderColor: theme.palette.success.main,
  padding: '15px',
  boxShadow: theme.palette.secondary.main,
}));

function useQueryParams(params: string) {
  return useMemo(() => new URLSearchParams(params), [params]);
}

export function UnclaimedMoney() {
  const { search } = useLocation();
  const queryParams = useQueryParams(search);
  const fname = queryParams.get('fname') || '';
  const lname = queryParams.get('lname') || '';
  const state = queryParams.get('state') || '';
  const [stateList, setStateList] = useState<string[]>(state ? [state] : []);

  useEffect(() => {
    document.title = 'Unclaimed Money - BeenVerified';
  }, []);

  return (
    <>
      <UnclaimedMoneyHeader />
      <StyledCard>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item width={'100%'}>
            <Text variant="h5" sx={{ fontWeight: 'bold', mt: 2.5, mb: 1.5 }}>
              There’s an estimated $70 billion worth of unclaimed money in the
              United States.
            </Text>
            <Text variant="h2">How much could be yours?</Text>
          </Grid>
        </Grid>
        <SearchNewUnclaimedMoneyForm
          fname={fname}
          lname={lname}
          stateList={stateList}
        />
        <br />
        <br />
        <br />
        <HelpfulTips tips={unclaimedMoneyHelpfulTips} />
      </StyledCard>
      <br />
      <br />
      <Faq faqItems={unclaimedMoneyFaq} />
    </>
  );
}
