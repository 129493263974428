import { Stack, styled, Text } from '@ltvco/refresh-lib/theme';

export const PreTestTopSection = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  wdith: '100%',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

export const PreTestTopSubtitle = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  width: '75%',
  marginBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'left',
  },
}));

export const PreTestBottomSection = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginTop: '36px',
  width: '95%',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const PreTestSuggestionContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  width: '30%',
  padding: 2,
  justifyContent: 'space-evenly',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const PreTestFourMainPersonalityTypesContainer = styled(Stack)(
  ({ theme }) => ({
    width: '95%',
    borderRadius: '8px',
    backgroundColor: '#4A3B8F',
    padding: '24px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const PreTestFourMainPersonalityTypesLeftSection = styled(Stack)(
  ({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const PreTestFourMainPersonalityTypesRightSection = styled(Stack)(
  ({ theme }) => ({
    width: '50%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const PreTestFourMainPersonalityTypesRightSubSection = styled(Stack)(
  ({ theme }) => ({
    width: '45%',
    height: '50%',
    borderRadius: '8px',
    margin: '8px',
    padding: '16px',
    backgroundColor: 'white',
  })
);
