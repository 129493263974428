import Lottie from 'react-lottie';
import { constants } from 'appConstants';

const PersonalityTraitsLoading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: constants.animations.personalityTraitsLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default PersonalityTraitsLoading;
