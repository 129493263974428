import { Stack, Button, Text } from '@ltvco/refresh-lib/theme';
import { publish } from '@ltvco/refresh-lib';
import { SearchReport } from 'icons';

export function NullState() {
  return (
    <Stack spacing={3.5}>
      <Stack spacing={1}>
        <Text variant="body2" fontWeight="bold">
          No reports found
        </Text>
        <Stack spacing={0.5}>
          <Text variant="body2">You haven't viewed any reports yet.</Text>
          <Text variant="body2">
            Start exploring by using the search bar above to find and view
            reports.
          </Text>
          <Text variant="body2">
            Once you've viewed a report, it will appear here for easy access in
            the future.
          </Text>
        </Stack>
      </Stack>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        width="100%"
        justifyContent={{ md: 'space-between' }}
        spacing={{ xs: 1.5 }}
        alignItems="center"
        border="1px solid #DDDDDD"
        borderRadius={4}
        paddingBlock={{ md: 0.5, xs: 1.5 }}
        paddingTop={{ xs: 0.5 }}
        paddingInline={1.5}
      >
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          width="100%"
          spacing={2.5}
          alignItems="center"
        >
          <SearchReport sx={{ fontSize: '7.25rem' }} />
          <Stack spacing={1}>
            <Text variant="body2" fontWeight="bold">
              Tips to get started:
            </Text>
            <Stack spacing={0.5} paddingLeft={0.5}>
              <Text>
                <Text component="span" marginRight={1}>
                  •
                </Text>
                <Text variant="body2" component="span">
                  Use specific keywords related to the report you're looking
                  for.
                </Text>
              </Text>
              <Text>
                <Text component="span" marginRight={1}>
                  •
                </Text>
                <Text variant="body2" component="span">
                  Try adding filters to narrow down results.
                </Text>
              </Text>
              <Text>
                <Text component="span" marginRight={1}>
                  •
                </Text>
                <Text variant="body2" component="span">
                  Browse through categories to discover new reports.
                </Text>
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          sx={{
            height: 46,
            minWidth: 115,
            fontWeight: 'bold',
            paddingInline: 1.75,
            paddingBlock: 1.5,
          }}
          onClick={() => publish('openPersonSearch')}
        >
          Search now
        </Button>
      </Stack>
    </Stack>
  );
}
