import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { PTProgressBar } from './PTProgressBar/PTProgressBar';
import { useState } from 'react';
import {
  PersonalityTraitDescriptionBox,
  PersonalityTraitResultContainer,
} from './components';
import { PersonalityStrengthWeakness } from 'routes/Dashboard/PersonalityTraits/usePersonalityDescriptions/types';
import { StrengthsWeaknesses } from './StrengthsWeaknesses/StrengthsWeaknesses';
import { ExpandLess, ExpandCircleDown } from '@mui/icons-material';

type PersonalityTraitResultProps = {
  personalityTraitResultType: string;
  personalityTraitResultTypeLabel: string;
  personalityTraitResultTypeDescription: string[];
  personalityTraitResultPercentrage: number;
  personalityTraitResultStrengths?: PersonalityStrengthWeakness[];
  personalityTraitResultWeaknesses?: PersonalityStrengthWeakness[];
};

export const PersonalityTraitResult = ({
  personalityTraitResultType,
  personalityTraitResultTypeLabel,
  personalityTraitResultTypeDescription,
  personalityTraitResultPercentrage,
  personalityTraitResultStrengths,
  personalityTraitResultWeaknesses,
}: PersonalityTraitResultProps) => {
  const [expandedState, setExpandedState] = useState(false);

  const handleExpand = () => {
    setExpandedState(!expandedState);
  };

  const renderDescriptions = () => {
    return personalityTraitResultTypeDescription.map((description, index) => (
      <Text key={index} marginBottom="8px">
        {description}
      </Text>
    ));
  };

  return (
    <Stack
      onClick={handleExpand}
      sx={(theme) => ({
        cursor: 'pointer',
        marginBottom: '8px',
        [theme.breakpoints.down('md')]: {
          marginBottom: '16px',
        },
      })}
    >
      <PersonalityTraitResultContainer>
        <Stack width="30%" flexDirection="row" alignItems="center">
          {expandedState ? <ExpandLess /> : <ExpandCircleDown />}
          <Text sx={{ marginLeft: '8px', textWrap: 'nowrap', width: '100%' }}>
            {personalityTraitResultType}
          </Text>
        </Stack>
        <PTProgressBar percentage={personalityTraitResultPercentrage} />
      </PersonalityTraitResultContainer>
      {expandedState && (
        <>
          <PersonalityTraitDescriptionBox>
            <Text variant="h4">{personalityTraitResultTypeLabel}</Text>
            {renderDescriptions()}
          </PersonalityTraitDescriptionBox>
        </>
      )}
    </Stack>
  );
};
