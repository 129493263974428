import { useContext, useEffect, useState } from 'react';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  Stack,
  Text,
  Grid,
  useTheme,
  useMediaQuery,
} from '@ltvco/refresh-lib/theme';
import { useSearchParams } from 'react-router-dom';
import { PersonFilters } from './SearchFilter/PersonFiltersSidebar';
import { BoxCardContainer, ConsolidatedStickyContainer } from './components';
import SearchHeaderCarousel from './SearchFilter/SearchHeader/SearchHeaderCarousel';
import { SearchHeaderDesktop } from './SearchFilter/SearchHeader/SearchHeaderDesktop';
import {
  Record,
  SerializedContactTeaserData,
  SerializedObituaryDetails,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  CollectionType,
  OptionType,
  getSearchQueryParams,
} from '@ltvco/refresh-lib/v2';
import { PersonResultsContainer } from './ResultsContainers/PersonResultsContainer';
import { ObituaryResultsContainer } from './ResultsContainers/ObituaryResultsContainer';
import { ResidentResultsContainer } from './ResultsContainers/ResidentResultsContainer';
import { ContactResultsContainer } from './ResultsContainers/ContactResultsContainer';
import { FamilySearchResultsContainer } from './ResultsContainers/FamilySearchResultsContainer';
import { PersonFetcher } from './Fetchers/PersonFetcher';
import { ObituaryFetcher } from './Fetchers/ObituaryFetcher';
import { FamilySearchFetcher } from './Fetchers/FamilySearchFetcher';
import { ContactFetcher } from './Fetchers/ContactFetcher';
import { ResidentFetcher } from './Fetchers/ResidentFetcher';
import {
  formattedFamilySearchData,
  RawFamilySearchPersonResults,
} from '@ltvco/refresh-lib/fmt';
import { capitalizeEachWord } from '@ltvco/refresh-lib/utils';

export function SearchPersonResultsv2() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [searchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(
    CollectionType.PUBLIC_RECORDS
  );
  const [manualSwitch, setManualSwitch] = useState<boolean>(false);

  const [personResults, setPersonResults] = useState<Record[] | undefined>(
    undefined
  );
  const [obituaryResults, setObituaryResults] = useState<
    SerializedObituaryDetails | undefined
  >(undefined);
  const [contactResults, setContactResults] = useState<
    SerializedContactTeaserData | undefined
  >(undefined);
  const [residentResults, setResidentResults] = useState<
    SerializedPropertyReport | undefined
  >(undefined);
  const [familySearchResults, setFamilySearchResults] = useState<
    RawFamilySearchPersonResults | undefined
  >(undefined);

  const searchData: AdvancedSearchURLParams =
    getSearchQueryParams(searchParams);

  const { advancedSearch, source, fName, lName } = searchData;

  const personResultsCount: number = personResults?.length ?? 0;
  const obituaryResultsCount: number = obituaryResults?.length ?? 0;
  const contactResultsCount: number = contactResults?.total ?? 0;
  const residentResultsCount: number =
    residentResults?.properties?.[0]?.residents?.length ?? 0;
  const familySearchResultsCount: number = familySearchResults
    ? formattedFamilySearchData(familySearchResults, fName, lName).length
    : 0;

  const formatLength = (length: number | undefined): string => {
    const threshold = 99;
    if (length === undefined || length === null) {
      return '';
    }
    return length > threshold ? '99+' : length.toString();
  };

  useEffect(() => {
    document.title = 'Search - BeenVerified';
  }, []);

  const fetchPerson =
    !advancedSearch || source.includes(CollectionType.PUBLIC_RECORDS);
  const fetchObituary =
    !advancedSearch || source.includes(CollectionType.OBITUARIES);
  const fetchAncestor =
    !advancedSearch || source.includes(CollectionType.FAMILY_SEARCH);
  const fetchContact =
    !advancedSearch || source.includes(CollectionType.CONTACTS);
  const fetchResident =
    !advancedSearch || source.includes(CollectionType.RESIDENTS);

  const options: OptionType[] = [
    {
      label: 'Public Records',
      value: CollectionType.PUBLIC_RECORDS,
      length: formatLength(personResultsCount),
      visible: fetchPerson,
    },
    {
      label: 'FamilySearch',
      value: CollectionType.FAMILY_SEARCH,
      length: formatLength(familySearchResultsCount),
      visible: fetchAncestor,
    },
    {
      label: 'Obituary',
      value: CollectionType.OBITUARIES,
      length: formatLength(obituaryResultsCount),
      visible: fetchObituary,
    },
    {
      label: 'Contact',
      value: CollectionType.CONTACTS,
      length: formatLength(contactResultsCount),
      visible: fetchContact,
    },
    {
      label: 'Resident',
      value: CollectionType.RESIDENTS,
      length: formatLength(residentResultsCount),
      visible: fetchResident,
    },
  ];

  const handleTabSwitch = (option: CollectionType) => {
    setSelectedOption(option);
    setManualSwitch(true);
  };

  // useEffect to handle automatic switching based on `searchData` but only if not manually switched
  useEffect(() => {
    if (!manualSwitch) {
      const hasContactInfo =
        searchData.title !== '' ||
        searchData.company !== '' ||
        searchData.industry !== '' ||
        searchData.level?.some((level) => level !== '');
      const hasObituaryInfo =
        searchData.birthYear !== '' ||
        searchData.birthMonth !== '' ||
        searchData.deathYear !== '' ||
        searchData.deathMonth !== '';
      const hasResidentInfo = searchData.address !== '';

      if (
        hasContactInfo &&
        (source.length === 0 || source.includes(CollectionType.CONTACTS))
      ) {
        setSelectedOption(CollectionType.CONTACTS);
        return;
      } else if (
        hasObituaryInfo &&
        (source.length === 0 || source.includes(CollectionType.OBITUARIES))
      ) {
        setSelectedOption(CollectionType.OBITUARIES);
        return;
      } else if (
        hasResidentInfo &&
        (source.length === 0 || source.includes(CollectionType.RESIDENTS))
      ) {
        setSelectedOption(CollectionType.RESIDENTS);
        return;
      }
    }
  }, [searchData, manualSwitch, selectedOption]);

  useEffect(() => {
    if (advancedSearch) {
      setSelectedOption(source[0] as CollectionType);
    }
  }, [searchParams.get('source')]);

  const fetchers = () => {
    return (
      <>
        {fetchPerson && (
          <PersonFetcher
            setPersonResults={setPersonResults}
            searchData={searchData}
          />
        )}
        {fetchObituary && (
          <ObituaryFetcher
            setObituaryResults={setObituaryResults}
            searchData={searchData}
          />
        )}
        {fetchAncestor && (
          <FamilySearchFetcher
            setFamilySearchResults={setFamilySearchResults}
            searchData={searchData}
          />
        )}
        {fetchContact && (
          <ContactFetcher
            setContactResults={setContactResults}
            searchData={searchData}
          />
        )}
        {fetchResident && (
          <ResidentFetcher
            setResidentResults={setResidentResults}
            searchData={searchData}
          />
        )}
      </>
    );
  };

  const renderResults = () => {
    switch (selectedOption) {
      case CollectionType.FAMILY_SEARCH:
        return (
          <FamilySearchResultsContainer
            results={familySearchResults}
            searchData={searchData}
          />
        );
      case CollectionType.CONTACTS:
        return <ContactResultsContainer results={contactResults} />;
      case CollectionType.OBITUARIES:
        return (
          <ObituaryResultsContainer
            results={obituaryResults}
            searchData={searchData}
          />
        );
      case CollectionType.RESIDENTS:
        return <ResidentResultsContainer results={residentResults} />;
      case CollectionType.PUBLIC_RECORDS:
        return <PersonResultsContainer results={personResults} />;
      default:
        return null;
    }
  };

  const capitalizeFullName = capitalizeEachWord(searchData.fullName);
  const ShowHeaderTabs = () => {
    if (isDesktop) {
      return (
        <SearchHeaderDesktop
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={handleTabSwitch}
          searchData={searchData}
        />
      );
    }
    return (
      <SearchHeaderCarousel
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={handleTabSwitch}
      />
    );
  };

  return (
    <Stack
      sx={{ minHeight: 800, position: 'relative', backgroundColor: '#fff' }}
    >
      {!isDesktop && <ShowHeaderTabs />}
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={3}>
          <ConsolidatedStickyContainer>
            <PersonFilters advancedSearchUrlParams={searchData} />
          </ConsolidatedStickyContainer>
        </Grid>
        <BoxCardContainer>
          <Grid item xs={12} md={12} sx={{ mt: { xs: 6.25, md: 0 } }}>
            {isDesktop && <ShowHeaderTabs />}
            {!isDesktop && (
              <Stack flexDirection="row" mr={1.25} mb={2.5}>
                <Text
                  variant="h3"
                  sx={{
                    fontWeight: 'normal',
                    marginRight: '5px',
                    marginBottom: '0',
                  }}
                >
                  Results for:
                </Text>
                <Text variant="h3" m={0}>
                  {capitalizeFullName}
                </Text>
              </Stack>
            )}
            {fetchers()}
            {renderResults()}
          </Grid>
        </BoxCardContainer>
      </Grid>
    </Stack>
  );
}
