import { useEffect, useState } from 'react';
import { StepOne } from './SurveySteps/StepOne';
import { styled, Button } from '@ltvco/refresh-lib/theme';
import {
  PersonalityTraitsQuestionSet,
  usePersonalityTraitsQuestions,
} from 'routes/Dashboard/PersonalityTraits/PreTest/PersonalityTraitsSurveyHelpers';
import { QuestionsSurvey } from 'routes/Dashboard/PersonalityTraits/PreTest/PersonalityTraitsSurveyHelpers/QuestionSurveyService/QuestionsSurvey';
import { ModalContainer, PersonalityTraitsModal } from './components';
import { ErrorQuestions } from './SurveySteps/ErrorQuestions';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

interface MultiStepModalProps {
  open: boolean;
  handleClose: () => void;
}

const CloseButton = styled(Button)(() => ({
  position: 'absolute',
  right: '0px',
  color: '#fff',
  top: '5px',
}));

export const TraitsMultiStepModal = ({
  open,
  handleClose,
}: MultiStepModalProps) => {
  const { data: questions } = usePersonalityTraitsQuestions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [step, setStep] = useState(1);
  const [timeoutCompleted, setTimeoutCompleted] = useState(false);

  const startSurvey = () => {
    if (questions && (questions as PersonalityTraitsQuestionSet).question_set) {
      setStep(2);
    }
  };

  const handleTimeoutComplete = () => {
    setTimeoutCompleted(true);
  };

  useEffect(() => {
    if (timeoutCompleted) {
      handleCloseModal();
      searchParams.set('page', 'results');
      setSearchParams(searchParams);
    }
  }, [timeoutCompleted, searchParams, setSearchParams]);

  const ShowQuestions = () => {
    if (questions && (questions as PersonalityTraitsQuestionSet).question_set) {
      return (
        <QuestionsSurvey
          questionsSet={
            (questions as PersonalityTraitsQuestionSet).question_set
          }
          onTimeoutComplete={handleTimeoutComplete}
        />
      );
    } else {
      <ErrorQuestions />;
    }
  };

  const handleCloseModal = () => {
    setStep(1);
    setTimeoutCompleted(false);
    handleClose();
  };

  return (
    <PersonalityTraitsModal open={open} id="personality-modal">
      <ModalContainer>
        {step === 1 && <StepOne buttonOnClick={startSurvey} />}
        {step === 2 && <ShowQuestions />}
        <CloseButton onClick={handleCloseModal}>
          <Close />
        </CloseButton>
      </ModalContainer>
    </PersonalityTraitsModal>
  );
};
