import { useEffect, useState } from 'react';
import {
  FamilyTreeContent,
  useAncestryTree,
  type PostIndi,
  usePostAncestryTree,
  FamilyTreeContentProvider,
  type JsonGedcomData,
} from '@ltvco/refresh-lib/fmt';
import { constants } from 'appConstants';
import { useNavigate } from 'react-router-dom';
import { getClaimedReports } from 'utils/getClaimedReports';
import {
  LoadingDots,
  enqueueSnackbar,
  ReportFactory,
  ReportMonitor,
  serializedTypes,
  useReport,
  useReportMonitors,
} from '@ltvco/refresh-lib/v1';
import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';
import { Stack } from '@ltvco/refresh-lib/theme';
import {
  formatAddressFromReport,
  capitalizeString,
} from '@ltvco/refresh-lib/utils';

const REPORT_TYPE = 'detailed_person_report';
const ANCESTRY_TREE_INITIAL_MEMBER_COUNT = 6;
const MAX_REPORTS = 10;

const LoadingContent = () => (
  <Stack
    sx={{
      width: '100vw',
      height: '100vh',
      paddingTop: 10,
    }}
  >
    <LoadingDots count={5} />
  </Stack>
);

export const FamilyTreePage = () => {
  const {
    data: { brandName },
  } = useAppConstantsContext();

  useEffect(() => {
    document.title = `Family Tree - ${brandName}`;
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [openMenuParam, setOpenMenuParam] = useState<string | undefined>();
  const [claimedReports, setClaimedReports] = useState<ReportMonitor[]>([]);
  const [hasReachedMonitorsLimit, setHasReachedMonitorsLimit] = useState(false);
  const permalinkParam = urlParams.get('from') || '';
  const {
    currentMonitors,
    toggleReportIsMonitored,
    reportMonitorIsLoading,
    reportIsMonitored,
    updateMonitorMutation,
  } = useReportMonitors(permalinkParam);
  const navigate = useNavigate();

  const openParam = urlParams.get('open') ?? undefined;

  const claimedReportPermalink = claimedReports?.length
    ? claimedReports[0].permalink
    : '';

  useEffect(() => {
    if (!!claimedReportPermalink && claimedReportPermalink != permalinkParam) {
      navigate(`?from=${claimedReportPermalink}`);
    }
  }, [claimedReportPermalink, permalinkParam, navigate]);

  useEffect(() => {
    // If the user has been redirected from the relatives section on the person report
    // it has a query param 'open=quick-add-relatives' to open the quick add relatives menu
    // Once we opened the menu, we remove the query param from the URL Cesar C. 03/05/2024
    if (openParam) {
      setOpenMenuParam(openParam);
      urlParams.delete('open');
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, [openParam]);

  const claimCurrentReport = () => {
    setIsInitialLoading(true);
    const queryParams = {
      'report_monitor[permalink]': permalinkParam as string,
      'report_monitor[is_claimed]': 'true',
      'report_monitor[report_type]': 'detailed_person_report',
    };

    // If the report is already monitored, we update the monitor to be claimed
    if (reportIsMonitored) {
      updateMonitorMutation.mutate({
        permalinkToEdit: permalinkParam,
        bodyParams: {
          report_monitor: {
            is_claimed: true,
          },
        },
      });
    }

    // If the user has not reached the limit of reports, we add the report to the monitor with is_claimed = true
    else if (currentMonitors?.length < MAX_REPORTS) {
      toggleReportIsMonitored({
        queryParams,
        successCallback: () => {},
        errorCallback: () => navigate('/dashboard'),
      });
    }
  };

  useEffect(() => {
    if (reportMonitorIsLoading) return;

    const hasMaxReports = currentMonitors?.length >= MAX_REPORTS;

    if (hasMaxReports) {
      const lastMonitorAdded = currentMonitors?.[currentMonitors?.length - 1];

      if (!lastMonitorAdded?.permalink) return;

      // If the user has reached the limit of reports, we check if the current report is not in the list
      if (
        !currentMonitors.some((monitor) => monitor.permalink === permalinkParam)
      ) {
        setHasReachedMonitorsLimit(true);
      }
    }

    const claimedReports = getClaimedReports(currentMonitors, REPORT_TYPE);
    setClaimedReports(claimedReports);

    setIsInitialLoading(false);
  }, [currentMonitors, reportMonitorIsLoading]);

  if (reportMonitorIsLoading || isInitialLoading) return <LoadingContent />;

  return (
    <FamilyTreeWithRelatives
      permalinkParam={permalinkParam}
      claimCurrentReport={claimCurrentReport}
      hasReachedMonitorsLimit={hasReachedMonitorsLimit}
      claimedReportPermalink={claimedReportPermalink}
      openMenuParam={openMenuParam}
      cleanMenuParam={() => setOpenMenuParam(undefined)}
    />
  );
};

interface FamilyTreeWithRelativesProps {
  claimedReportPermalink: string;
  openMenuParam?: string;
  claimCurrentReport: () => void;
  permalinkParam: string;
  hasReachedMonitorsLimit: boolean;
  cleanMenuParam: () => void;
}

const FamilyTreeWithRelatives = ({
  claimedReportPermalink,
  claimCurrentReport,
  permalinkParam,
  hasReachedMonitorsLimit,
  openMenuParam,
  cleanMenuParam,
}: FamilyTreeWithRelativesProps) => {
  const isUserOnMainReport =
    permalinkParam === claimedReportPermalink && !!permalinkParam;

  const [isLoading, setIsLoading] = useState(
    isUserOnMainReport || !!claimedReportPermalink
  );
  const [bvid, setBvid] = useState<string>('');
  const [relatives, setRelatives] = useState<
    serializedTypes.Connection[] | undefined
  >(undefined);
  const [reportIndi, setReportIndi] = useState<PostIndi>();
  const [newAncestryTreeData, setNewAncestryTreeData] =
    useState<JsonGedcomData>();
  const [isCleanView, setIsCleanView] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data: ancestryTreeData,
    isError: getAncestryTreeError,
    error: getAncestryTreeErrorObj,
    isLoading: isAncestryTreeLoading,
  } = useAncestryTree({
    permalink: claimedReportPermalink,
  });
  const {
    links: { baseUrl },
  } = useAppConstantsContext();

  const { mutate: postAncestryTreeMutation, isError: postAncestryTreeIsError } =
    usePostAncestryTree();

  const { data: coreResultData } = useReport(
    claimedReportPermalink,
    !isUserOnMainReport
  );

  const handleNavigateToTreeBuilder = () => {
    window.location.href = `${baseUrl}/dashboard/family-ancestry?open=tree-builder&from=${permalinkParam}`;
  };

  const handleCleanViewToggle = (newValue: boolean) => {
    setIsCleanView(newValue);
  };

  useEffect(() => {
    if (coreResultData) {
      const report = ReportFactory.create(coreResultData, 'person');
      const owner = report.getOwner({
        potentialOwnerIndex: 0,
        showHighConfidenceDataOnly: true,
        higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
      });
      const { relatives, identity } = owner;
      const reportBvid = identity!.ids[0];

      const emptyAddress = { street: '', city: '', state: '', zip5: '' };

      const formattedAddress = owner.location?.parsed
        ? formatAddressFromReport(owner.location?.parsed)
        : emptyAddress;

      setReportIndi({
        first_name: capitalizeString(identity!.names[0]?.parsed?.first || ''),
        middle_name: capitalizeString(identity!.names[0]?.parsed?.middle || ''),
        last_name: capitalizeString(identity!.names[0]?.parsed?.last || ''),
        dob_month: identity!.dobs[0]?.date?.parsed?.month,
        dob_year: identity!.dobs[0]?.date?.parsed?.year,
        dod_month: identity!.dods[0]?.date?.parsed?.month,
        dod_year: identity!.dods[0]?.date?.parsed?.year,
        place: {
          street_address_1: formattedAddress.street ?? '',
          city: formattedAddress.city ?? '',
          state: formattedAddress.state ?? '',
          zip_code: formattedAddress.zip5 ?? '',
        },
      });

      setRelatives(relatives);
      setBvid(reportBvid);
    }
  }, [coreResultData]);

  useEffect(() => {
    if (getAncestryTreeError && !!reportIndi) {
      // if the request doesn't have a valid token avoid posting to ancestry tree.
      // @ts-expect-error - Checking if the error message includes 401 TODO: Fix this
      if (getAncestryTreeErrorObj?.message.includes('401')) return;

      postAncestryTreeMutation({
        indi: reportIndi,
        permalink: claimedReportPermalink,
        bvid,
      });
      setReportIndi(undefined);
    }
  }, [getAncestryTreeError, getAncestryTreeErrorObj]);

  useEffect(() => {
    if (!!ancestryTreeData) {
      // Get the current state value directly before using it
      const currentCleanViewState = isCleanView;

      // If the user is in clean view, we filter out the deleted individuals and placeholders from the tree
      if (currentCleanViewState) {
        const filteredIndis = ancestryTreeData.indis.filter(
          (i) => !i.placeholder || !!i.deleted_at
        );

        // Creates a set of the valid individual ids
        const validIndiIds = new Set(filteredIndis.map((indi) => indi.id));

        // Filters out Fams that makes reference to invalid individuals filtered before
        // and removes the reference to invalid individuals in the children array
        const newFams = ancestryTreeData.fams.map((fam) => {
          const validChildren =
            fam.children?.filter((childId) => validIndiIds.has(childId)) || [];

          return {
            id: fam.id,
            children: validChildren,
            parent1: validIndiIds.has(fam.parent1 as number)
              ? fam.parent1
              : undefined,
            parent2: validIndiIds.has(fam.parent2 as number)
              ? fam.parent2
              : undefined,
          };
        });

        // Creates a new object with the filtered individuals and the new Fams
        const newAncestryTreeData = {
          ...ancestryTreeData,
          indis: filteredIndis,
          fams: newFams,
        };

        setNewAncestryTreeData(newAncestryTreeData);
      } else {
        setNewAncestryTreeData(ancestryTreeData);
      }
    }
  }, [ancestryTreeData, isCleanView]);

  useEffect(() => {
    if (!!ancestryTreeData && isLoading) {
      if (
        ancestryTreeData.indis.length <= ANCESTRY_TREE_INITIAL_MEMBER_COUNT &&
        localStorage.getItem('skipAutoTreeBuilder') !== 'true'
      ) {
        handleNavigateToTreeBuilder();
      } else {
        setIsLoading(false);
      }
    }
  }, [ancestryTreeData, setIsLoading, localStorage]);

  if (postAncestryTreeIsError) {
    enqueueSnackbar('An error occurred while generating the family tree', {
      variant: 'error',
    });

    navigate('/dashboard');
  }

  if (isLoading) return <LoadingContent />;

  return (
    <FamilyTreeContentProvider members={newAncestryTreeData?.indis || []}>
      <FamilyTreeContent
        hasReport={isUserOnMainReport}
        relatives={relatives}
        ancestryTreeData={newAncestryTreeData}
        isAncestryTreeLoading={isAncestryTreeLoading}
        openMenuParam={openMenuParam}
        claimCurrentReport={claimCurrentReport}
        hasReachedMonitorsLimit={hasReachedMonitorsLimit}
        permalinkParam={permalinkParam}
        cleanMenuParam={cleanMenuParam}
        isCleanView={isCleanView}
        setCleanView={handleCleanViewToggle}
      />
    </FamilyTreeContentProvider>
  );
};
