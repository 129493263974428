import { Box, Stack, styled } from '@ltvco/refresh-lib/theme';

export const StickyContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 60,
  width: '100%',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    zIndex: 1,
    marginBottom: theme.spacing(3),
    height: 'unset',
    overflowY: 'unset',
    position: 'sticky',
  },
}));

export const ConsolidatedStickyContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 58,
  width: '100%',
  zIndex: 2,
  left: 0,

  [theme.breakpoints.up('md')]: {
    zIndex: 1,
    marginBottom: theme.spacing(3),
    height: 'unset',
    overflowY: 'unset',
    position: 'sticky',
  },
}));

export const PersonFilterNav = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  display: 'flex',
  margin: theme.spacing(0.5, 0.5, 2.5),
  padding: 0,
  position: 'relative',
  top: '50px',
  height: '90px',
  [theme.breakpoints.up('md')]: {
    position: 'unset',
    height: 'unset',
    top: 0,
  },
  '&.search-nav': {
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4),
    },
  },
}));

export const PersonFilterNavItem = styled('li')(({ theme }) => ({
  float: 'left',
  position: 'relative',
  display: 'block',
  paddingTop: theme.spacing(0.375),
  ':hover,&.active': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '.selected-tab': {
      display: 'block',
    },
  },
  '.selected-tab': {
    display: 'none',
  },
}));

export const NavButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.secondary,
  padding: theme.spacing(0.5, 1),
  borderRadius: '32px',
  border: `1px solid ${theme.palette.grey[300]}`,
  cursor: 'pointer',
  '-webkit-tap-highlight-color': 'transparent',
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  appearance: 'none',

  ':hover': {
    backgroundColor: theme.palette.success.light,
  },
  ':focus': {
    outline: 'none',
    backgroundColor: 'transparent',
  },
  ':active': {
    backgroundColor: 'transparent',
    color: 'inherit',
    opacity: 1,
  },
  '&.active': {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '.options-results': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&.disabled': {
    opacity: 0.35,
  },
}));

export const OptionResults = styled('div')(({ theme }) => ({
  height: '25px',
  width: '25px',
  backgroundColor: theme.palette.text.secondary,
  borderRadius: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
}));

export const SearchHeader = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: '52px',
  zIndex: 2,
  backgroundColor: theme.palette.primary.contrastText,
  left: 0,
  right: 0,
  marginLeft: '-1vw',
  paddingLeft: '100px',

  [theme.breakpoints.between('md', 'lg')]: {
    padding: '15px 24px',
  },

  [theme.breakpoints.up('md')]: {
    top: '52px',
    padding: '15px',
  },

  [theme.breakpoints.between(1070, 'xl')]: {
    top: '52px',
  },

  [theme.breakpoints.up('xl')]: {
    top: '52px',
  },
}));

export const BoxCardContainer = styled(Box)(({ theme }) => ({
  marginTop: '16px',
  zIndex: 1,
  marginLeft: '20px',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '72%',
    marginTop: '0',
  },
}));
