import {
  Stack,
  Text,
  CustomIcons,
  Box,
  Card,
  DarkTooltip,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import { CardContent, CardMedia } from '@mui/material';
import people_asset from '../../../../images/graphic_person.png';
import phone_asset from '../../../../images/graphic_phone.png';
import darkweb_asset from '../../../../images/graphic_darkweb.png';
import { useReportRedirect, useSession } from '@ltvco/refresh-lib/v1';
import { formatPhoneInput, useScreenSize } from '@ltvco/refresh-lib/utils';
import type { PersonSearchParamsv2 } from 'routes/SearchPerson/SearchFilter/interfaces';
import {
  ArrowForward,
  Help,
  PersonRounded,
  PhoneRounded,
} from '@mui/icons-material';

interface PublicDataCardProps {
  variant: 'Dark Web' | 'People' | 'Phone';
  header: string;
  peopleObject?: PersonSearchParamsv2;
}
const PublicDataCard = ({
  variant,
  header,
  peopleObject,
}: PublicDataCardProps) => {
  const navigate = useNavigate();
  const { redirectToSearchPersonUrlv2 } = useReportRedirect();
  let phone = '';

  if (variant === 'Phone') {
    phone = header.replace(/\D/g, '');
  }

  const config = {
    'Dark Web': {
      action: () => navigate('dark-web'),
      bgAsset: darkweb_asset,
      description: 'Is your info Safe?',
      icon: CustomIcons.DarkWebIcon,
    },
    People: {
      action: () => redirectToSearchPersonUrlv2(peopleObject || {}),
      bgAsset: people_asset,
      description: 'View your personal data',
      icon: PersonRounded,
    },
    Phone: {
      action: () => navigate(`/report/phone?phone=${phone}`),
      bgAsset: phone_asset,
      description: 'See your phone details',
      icon: PhoneRounded,
    },
  };

  const { bgAsset, icon: Icon, description, action } = config[variant];
  const theme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        width: 278,
        height: 174,
        padding: 0,
        flexShrink: 0,
        '&:hover': {
          cursor: 'pointer',
          bgcolor: theme.palette.background.default,
        },
      }}
      onClick={() => action()}
    >
      <CardMedia sx={{ backgroundColor: 'primary.main', width: '100%' }}>
        <Stack direction="row" alignItems="center" gap={1} height={36} pl={2}>
          <Icon sx={{ color: theme.palette.primary.light }} />
          <Text variant="h4" m={0} sx={{ color: 'primary.light' }}>
            {variant}
          </Text>
          <Box ml="auto">
            <img src={bgAsset} alt="Description" />
          </Box>
        </Stack>
      </CardMedia>
      <CardContent>
        <Text
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="h6"
        >
          {header}
        </Text>
        <Text variant="body2">{description}</Text>
        <Text mt={5} variant="body1" color="primary.main" fontWeight="bold">
          Search
        </Text>
      </CardContent>
    </Card>
  );
};

export function DashboardMyPublicData() {
  const {
    session: { account },
  } = useSession();

  const { first_name, last_name, email, phone_number } =
    account?.account.user_info || {};
  const { isMobile } = useScreenSize();
  return (
    <>
      <Stack display="flex" alignItems="center" flexDirection="row">
        <Text variant="h3" margin={0}>
          My Public Data
        </Text>
        {isMobile && (
          <DarkTooltip
            title="View your data that is publicly accessible"
            placement="bottom"
          >
            <Help fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
          </DarkTooltip>
        )}
      </Stack>
      <Stack
        direction="row"
        columnGap={{ xs: 1, xl: 10.5 }}
        sx={{
          width: '100%',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {!isMobile && (
          <Box
            width={147}
            height={174}
            sx={{ bgcolor: 'primary.main' }}
            display="flex"
            flexDirection="column"
            p={2.5}
            flexShrink={0}
            borderRadius={2}
            justifyContent="flex-end"
          >
            <Text variant="body2" color="text.contrastText">
              View your data that is publicly accessible
            </Text>
            <Box
              sx={{ bgcolor: 'primary.light', borderRadius: '50%' }}
              width={32}
              height={32}
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={3}
            >
              <ArrowForward fontSize="medium" color="primary" />
            </Box>
          </Box>
        )}
        <PublicDataCard
          variant="People"
          header={`${first_name} ${last_name}`}
          peopleObject={{
            fName: first_name || '',
            lName: last_name || '',
            city: '',
            state: '',
            age: '',
          }}
        />
        <PublicDataCard
          variant="Phone"
          header={formatPhoneInput(phone_number ?? '')}
        />
        <PublicDataCard variant="Dark Web" header={email ?? ''} />
      </Stack>
    </>
  );
}
