import { Navigate, useLocation } from 'react-router-dom';
import { isUserLoggedIn, useSession } from '@ltvco/refresh-lib/v1';

interface PublicRouteProps {
  children: JSX.Element | JSX.Element[];
}

export function PublicRoute({ children }: PublicRouteProps) {
  const {
    session: { isAuthenticated },
  } = useSession(); // should be replaced by the user_info object
  const location = useLocation();

  if (isAuthenticated && isUserLoggedIn()) {
    return (
      <Navigate
        to={location.search ? `/dashboard${location.search}` : '/dashboard'}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}
