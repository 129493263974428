export const darkWebScanFaq = [
  {
    id: '0',
    question: 'What is dark web scan?',
    answer: `A dark web scan is a search for your personal information on the Dark Web — the deepest recesses of the Internet where many criminals are reputed to operate. The Dark Web is not easily accessed and may require some additional software to search, and if information like your email is available on the Dark Web, your identity could be used to commit fraud. A BeenVerified Dark Web Scan searches web pages for any of your email addresses that may have been compromised.`,
  },
  {
    id: '1',
    question: 'What is a breach?',
    answer: `Breaches are incidents where personal information like emails, usernames, and passwords are inadvertently exposed on the dark web, usually due to a weak security system on the site that was breached. The Dark Web Scan aggregates breaches so that you may assess where your personal data may have been exposed.`,
  },
  {
    id: '2',
    question: 'What do I do if some of my accounts have been compromised?',
    answer: `If one or several of your accounts have been compromised, there are several actions you should consider taking immediately:
        - Change your passwords and make them stronger by using different characters, symbols, and numbers.
        - Check your credit report regularly.
        - Monitor your accounts from fraudulent activity, such as any statements or changes you don't recognize.
        - Use a service to scan the Dark Web regularly for your information.`,
  },
  {
    id: '3',
    question: 'Some Dark Web stats',
    answer:
      'The Dark Web sounds like a scary place, and when your information finds itself there, it may be vulnerable for use in connection with committing crimes. The Dark Web only comprises 3% of the internet, but is incredibly popular with criminals: according to the Identity Theft Resource Center, system-wide data breaches in the United States in 2016 hit an all-time high of 1,093. That’s a 40% increase over the previous year. When your personal data is sold on the Dark Web, it’s sold for cheap: it can cost just $1 to obtain your SSN, $1 to set up a fake Facebook account with 15 friends, $50 for your medical records, and $60 for your credit card details. Please bear in mind that, at present, a BeenVerified Dark Web Scan can only search for your email address.',
  },
  {
    id: '4',
    question: `My email was not found - does that mean my email hasn't been compromised?`,
    answer: `In short, no. We update our data as frequently as we can, but some information breaches can still go undetected. Even if your email address hasn't been found on the Dark Web after a successful scan, that doesn't necessarily mean that it hasn't been compromised or that you shouldn't take precautionary, preventive measures to regularly protect yourself.`,
  },
  {
    id: '5',
    question:
      'Why do I see my email as breached on a service I never signed up to?',
    answer:
      'There are several reasons why you may see your email listed as having been breached on a service you ostensibly never signed up for. One possible answer may be because your data was acquired by another service. Another may be that you signed up for a service that rebranded itself. Perhaps another person signed you up for that service using your email address, or you may have forgotten that you had signed up for that particular service.',
  },
];
