import {
  Stack,
  Text,
  styled,
  Card,
  Grid,
  StackProps,
  GridProps,
  CardProps,
} from '@ltvco/refresh-lib/theme';

export const SectionHeader = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(5),
}));

export const ScanImage1 = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(3),
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'initial',
    width: 150,
  },

  [theme.breakpoints.up('md')]: {
    width: 250,
  },

  [theme.breakpoints.up('lg')]: {
    width: 300,
  },

  [theme.breakpoints.up('xl')]: {
    width: 370,
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

export const ScanImage2 = styled('img')(({ theme }) => ({
  width: '50%',
  maxWidth: 180,

  [theme.breakpoints.up('sm')]: {
    maxWidth: 200,
  },

  [theme.breakpoints.up('md')]: {
    width: '80%',
    maxWidth: 'initial',
  },
}));

export const HowItWorksGridItem = styled((props: GridProps) => (
  <Grid item xs={12} md={6} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',

    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  [theme.breakpoints.up('md')]: {
    '&:nth-child(2)': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },

    '&:nth-of-type(even)': {
      borderLeft: 'none',
    },
  },
}));

export const HowItWorksStack = styled((props: StackProps) => (
  <Stack direction="row" spacing={4} {...props} />
))(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
}));

export const FullHeightCard = styled((props: CardProps) => (
  <Card variant="outlined" {...props} />
))(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.default,
  border: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2.5),
}));

export const StyledIconBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 65,
  height: 65,
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  border: 0,
}));
