import imgExpiredTime from '../../../images/img_expired_time.svg';
import imgStates from '../../../images/img_states.svg';
import imgGroupPeople from '../../../images/img_group_people.svg';

export const unclaimedMoneyFaq = [
  {
    id: '0',
    question: 'What is unclaimed money?',
    answer: `Unclaimed money, also called unclaimed property, is money that is held by the state after the rightful owner fails to collect it, typically within the period of a year. There are many reasons why there may be money that you have not yet claimed: switching bank accounts, moving, and overpaying a bill are just some of the common reasons why money that is rightfully yours may not have been able to be returned to you. The average amount of money unclaimed by an individual tends to be between $50 to $100.`,
  },
  {
    id: '1',
    question: 'Where does it come from?',
    answer: `Your state of residence may be holding your unclaimed money for any of the following reasons:`,
    list: [
      'Overpaying a bill, then relocating: The state will collect and safeguard your money if a company cannot locate you or doesn’t know your new address.',
      'Switching bank accounts during a move.',
      'Bank failures.',
      'Unpaid wages or unclaimed pensions.',
      'Tax refunds: If your refund was unclaimed or undelivered, the state may keep it until you claim it.',
      'Inheritance: The state may safeguard your inheritance if you do not claim it.',
    ],
  },
  {
    id: '2',
    question: 'How does this unclaimed money search work?',
    answer:
      'Simply search your first and last name plus any state you’ve ever lived in. If you find unclaimed money associated with your name, we will try and redirect you to the applicable, official state website to start the claims process, which is typically free.',
  },
  {
    id: '3',
    question: 'Do I have to pay to claim my money?',
    answer: 'Typically, you won’t need to pay a fee to claim your money.',
  },
  {
    id: '4',
    question: 'How do I file a claim?',
    answer:
      'We offer an easy way to search for unclaimed money in different states from one website. Once you are ready to file a claim, each state has their own claims process that may vary in complexity, so you may have to consult your state’s laws and regulations. Each state has its own rules on how to retrieve unclaimed money, such as whether or not you can do it online, or if you have to do it by mail. You will also likely have to prove that the money is yours by presenting a form of identification. Please review each state’s requirements carefully when filing your claim.',
  },
  {
    id: '5',
    question: 'How long does it take to get my money?',
    answer:
      'The time it will take to get your money depends on the state. Some states can process claims in as little as 2 weeks, whereas others may take as long as 6 months. For more detailed information, you may want to inquire about the timeline and whether or not you will be able to check up on the status of the claim online.',
  },
];

export const unclaimedMoneyHelpfulTips = [
  {
    id: '0',
    text: `Unclaimed money doesn't expire, so search every state you've lived or done business in.`,
    icon: imgExpiredTime,
  },
  {
    id: '1',
    text: 'State databases are updated frequently, so check back monthly.',
    icon: imgStates,
  },
  {
    id: '2',
    text: 'With unclaimed money you can search for yourself, relatives or your friends with ease.',
    icon: imgGroupPeople,
  },
];
