import React from 'react';
import {
  useSearchPerson,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getResidentSearchParams,
} from '@ltvco/refresh-lib/v2';

interface ResidentFetcherProps {
  searchData: AdvancedSearchURLParams;
  setResidentResults: (results: SerializedPropertyReport | undefined) => void;
}
export function ResidentFetcher({
  searchData,
  setResidentResults,
}: ResidentFetcherProps) {
  const { data, isFetched, isError } = useSearchPerson({
    searchPersonBy: 'address',
    options: {
      personSearchParams: getResidentSearchParams(searchData),
    },
  });

  const result = data as SerializedPropertyReport | undefined;

  if (isFetched && !isError) {
    setResidentResults(result);
  } else {
    setResidentResults(undefined);
  }
  return <React.Fragment />;
}
