import { OwnerSearch } from '@ltvco/refresh-lib/v1';
import { SidenavContainer } from 'components/sidenav/SidenavContainer';

export const OwnerSearchParent = () => {
  return (
    <SidenavContainer>
      <OwnerSearch />
    </SidenavContainer>
  );
};
