import type { RawReportType, ReportMonitor } from '@ltvco/refresh-lib/v1';

export const filterAndSortReports = (reports: ReportMonitor[]) => {
  const claimedReports = reports.filter((report) => report.is_claimed);

  const sortedClaimedReports: {
    [key in RawReportType as string]: ReportMonitor[];
  } = {};

  claimedReports.forEach((report) => {
    if (sortedClaimedReports[report.report_type]) {
      sortedClaimedReports[report.report_type] = [
        ...sortedClaimedReports[report.report_type],
        report,
      ];
    } else {
      sortedClaimedReports[report.report_type] = [report];
    }
  });

  return sortedClaimedReports;
};
