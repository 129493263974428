import { styled, Modal } from '@ltvco/refresh-lib/theme';
import { traitsTheme } from './constants';
import { constants } from 'appConstants';

export const PersonalityTraitsModal = styled(Modal)(({ theme }) => ({
  overflow: 'scroll',

  '.MuiPaper-root': {
    maxWidth: 'unset',
    maxHeight: 'unset',
    margin: '0',
    '.MuiDialogContent-root': {
      padding: '0',
    },
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'hidden',
  },
}));

export const ModalContainer = styled('div')(({ theme }) => ({
  padding: '40px 20px',
  backgroundColor: traitsTheme.modalContainerBackground,
  margin: 'auto',
  top: '14%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '&:before': {
    content: '""',
    backgroundImage: `url(${constants.images.personalityTraitsModalDots})`,
    position: 'absolute',
    width: '281px',
    height: '281px',
    bottom: '0',
    right: '0',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '40px',
    width: '600px',
  },
}));
