import { Stack, Text, Grid, Box } from '@ltvco/refresh-lib/theme';
import { titleize } from '@ltvco/refresh-lib/utils';
import { useSearchParams } from 'react-router-dom';
import { ResultCard, Record } from '@ltvco/refresh-lib/v1';
import {
  formattedFamilySearchData,
  RawFamilySearchPersonResults,
} from '@ltvco/refresh-lib/fmt';
import { useEffect, useState } from 'react';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { constants } from 'appConstants';
import {
  AdvancedSearchURLParams,
  ConsolidatedSearchResultCard,
} from '@ltvco/refresh-lib/v2';
import React from 'react';

interface FamilySearchResultsProps {
  familySearchInfo: AdvancedSearchURLParams;
  familySearchData: RawFamilySearchPersonResults;
}

export const FamilySearchResults = ({
  familySearchInfo,
  familySearchData,
}: FamilySearchResultsProps) => {
  const [ip, setIp] = useState<string>('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = 'Search - BeenVerified';
  }, []);

  useEffect(() => {
    if (!constants.featureFlags.familySearchEnabled) return;
    (async () => {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();

      setIp(data.ip);
    })();
  }, []);

  let personResults: Record[] = [];

  let formattedFamily: any = [];

  if (familySearchData)
    formattedFamily = formattedFamilySearchData(
      familySearchData,
      familySearchInfo.fName,
      familySearchInfo.lName
    );
  personResults = formattedFamily;

  // Utils
  const getFullName = () => {
    const { fName, mName, lName } = familySearchInfo;

    const nameData = [fName, mName, lName].filter(Boolean);
    const fullName = nameData.join(' ');

    return titleize(fullName.toLowerCase());
  };

  let obitRedirectMessage: string | null = null;

  if (searchParams.get('redirected_from_obit')) {
    obitRedirectMessage = `Looks like we don't have obituary search data for ${getFullName()}, but we have person search results for similar names`;
  }

  function title() {
    if (obitRedirectMessage) {
      return (
        <Text variant="h3" display={'inline'}>
          {obitRedirectMessage}
        </Text>
      );
    }
  }

  return (
    <Stack sx={{ minHeight: 800, position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ mt: { xs: 8.75, md: 0 } }}>
          <Grid item xs={12}>
            <Box
              mb={{ xs: 2, md: 2 }}
              ml={{ xs: 2, md: 0 }}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              {title()}
            </Box>
          </Grid>
          {personResults.map((person: Record, index: number) => {
            return (
              <React.Fragment key={index}>
                <ConsolidatedSearchResultCard
                  {...person}
                  ip={ip}
                  ancestorButton={person.familySearchId ? true : false}
                  textButtonProp={person.familySearchId ? 'View' : ''}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Stack>
  );
};
