import {
  Skeleton,
  Grid,
  Stack,
  Box,
  Divider,
  styled,
} from '@ltvco/refresh-lib/theme';

const CardContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  padding: `${theme.spacing(3)}`,
  marginBottom: `${theme.spacing(3)}`,
  borderRadius: `${theme.spacing(0.5)}`,
}));

/**
 *
 * This is a loading component while the search person is loading.
 * Currently, there is no props for this component. It is just a skeleton
 * to show that the search is loading.
 */
export const PersonCardLoading = () => {
  const itemList = [1, 2, 3];

  return (
    <Stack>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={9}>
          {itemList.map((_index) => (
            <CardContainer key={_index}>
              <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                <Box width={{ xs: '100%', md: '70%' }}>
                  <Grid container spacing={1} sx={{ mb: 1 }}>
                    <Grid item xs={12} sx={{ pt: 0, mt: 0 }}>
                      <Skeleton width="25%" height={30} variant="rectangular" />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton
                        sx={{
                          width: '20%',
                          height: 24,
                          mb: 2,
                        }}
                        variant="rectangular"
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Skeleton
                      sx={{
                        width: '10%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                        mr: 2,
                      }}
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{
                        width: '35%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                      }}
                      variant="rectangular"
                    />
                  </Box>
                  <Box>
                    <Skeleton
                      sx={{
                        width: '10%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                        mr: 2,
                      }}
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{
                        width: '35%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                      }}
                      variant="rectangular"
                    />
                  </Box>
                  <Box>
                    <Skeleton
                      sx={{
                        width: '10%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                        mr: 2,
                      }}
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{
                        width: '35%',
                        height: 19,
                        mb: 2,
                        display: 'inline-block',
                      }}
                      variant="rectangular"
                    />
                  </Box>
                </Box>
                <Box width={{ xs: '100%', md: '30%' }}>
                  <Skeleton
                    sx={{
                      width: '100%',
                      height: 40,
                    }}
                    variant="rectangular"
                  />
                </Box>
              </Stack>
            </CardContainer>
          ))}
        </Grid>
      </Grid>
    </Stack>
  );
};
