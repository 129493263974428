import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { renderStrengthsWeaknesses } from './utils';
import {
  StrengthsWeaknessesContainer,
  StrengthsWeaknessesUnorderedList,
  StrengthsWeaknessesWrapper,
} from './components';
import { PersonalityStrengthWeakness } from 'routes/Dashboard/PersonalityTraits/usePersonalityDescriptions/types';
import { Brightness7, BrightnessLow } from '@mui/icons-material';

type StrengthsWeaknessesProps = {
  strengths: PersonalityStrengthWeakness[];
  weaknesses: PersonalityStrengthWeakness[];
};

export const StrengthsWeaknesses = ({
  strengths,
  weaknesses,
}: StrengthsWeaknessesProps) => {
  return (
    <StrengthsWeaknessesWrapper>
      <StrengthsWeaknessesContainer>
        <Stack flexDirection="row">
          <Brightness7 fontSize="small" />
          <Text variant="h4" marginLeft="8px">
            Possible Strengths
          </Text>
        </Stack>
        <StrengthsWeaknessesUnorderedList>
          {renderStrengthsWeaknesses(strengths)}
        </StrengthsWeaknessesUnorderedList>
      </StrengthsWeaknessesContainer>
      <StrengthsWeaknessesContainer>
        <Stack flexDirection="row">
          <BrightnessLow fontSize="small" />
          <Text variant="h4" marginLeft="8px">
            Possible Weaknesses
          </Text>
        </Stack>
        <StrengthsWeaknessesUnorderedList>
          {renderStrengthsWeaknesses(weaknesses)}
        </StrengthsWeaknessesUnorderedList>
      </StrengthsWeaknessesContainer>
    </StrengthsWeaknessesWrapper>
  );
};
