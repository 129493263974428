import { ValidReportTypes } from '@ltvco/refresh-lib/utils';
import { Stack, Text, Button, Box } from '@ltvco/refresh-lib/theme';
import {
  useReportRedirect,
  mapReportTypesToReportParams,
} from '@ltvco/refresh-lib/v1';
import {
  RecentReportSerialized,
  ReportCard,
  useRecentReports,
} from '@ltvco/refresh-lib/v2';
import { useNavigate } from 'react-router-dom';
import { RecentReportsCardDescription } from 'routes/Dashboard/RecentReports/components';
import { useQueryParams } from 'use-query-params';
import {
  generateQueryConfig,
  stringifyParams,
} from '../../../../components/BBQ';
import { ChevronRight } from '@mui/icons-material';

const queryConfig = generateQueryConfig();

export function DashboardRecentReports() {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams(queryConfig);
  const filteredParams = stringifyParams(queryParams);
  const { data: queryData } = useRecentReports(filteredParams);
  const reports = (queryData?.reports as RecentReportSerialized[]) || [];

  if (!reports.length) return null;

  return (
    <>
      <Stack
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text variant="h3" margin={0}>
          My Recent Reports
        </Text>
        <Button
          variant="text"
          endIcon={<ChevronRight />}
          sx={{
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
          }}
          onClick={() => navigate('/dashboard/reports')}
          disableRipple
          disableTouchRipple
        >
          View all
        </Button>
      </Stack>
      <Stack
        direction="row"
        columnGap={1}
        sx={{
          width: '100%',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {reports.slice(0, 5).map((report) => {
          const {
            reportType,
            reportData,
            reportTypeFormatted,
            id: reportPermaLink,
            detailsCityState,
          } = report;
          const { redirectToReportUrl } = useReportRedirect();
          return (
            <Box
              key={reportPermaLink}
              sx={{
                minWidth: 'calc(100% / 4.5)',
                flexShrink: 0,
              }}
            >
              <ReportCard
                title={
                  reportTypeFormatted.toLowerCase() === 'vehicle' &&
                  detailsCityState
                    ? detailsCityState
                    : reportData
                }
                description={
                  <RecentReportsCardDescription
                    report={report}
                    deleteCurrentReport={() => null}
                    hideRats
                    isOnDashboard
                  />
                }
                reportType={
                  mapReportTypesToReportParams[
                    reportType as keyof typeof mapReportTypesToReportParams
                  ] as ValidReportTypes
                }
                clickAction={() => {
                  redirectToReportUrl(
                    mapReportTypesToReportParams[
                      reportType as keyof typeof mapReportTypesToReportParams
                    ] as ValidReportTypes,
                    reportPermaLink
                  );
                }}
                actionPosition="hidden"
                containerStyles={{ minHeight: 160 }}
              />
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
