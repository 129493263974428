import { PersonalityStrengthWeakness, TraitDescription } from './types';

export const usePersonalityDescriptions = () => {
  // traits and types are the same for big five since the type is just the most dominant trait
  const bigFiveTraits = [
    'agreeableness',
    'conscientiousness',
    'extraversion',
    'neuroticism',
    'openness',
  ];

  // traits and types are the same for disc since the type is just the most dominant trait
  const discTraits = ['compliance', 'dominance', 'influence', 'stability'];

  // extraversion2 is to distinguish it from big five for easier accessing
  const fourAspectsTraits = [
    'extraversion2',
    'realism',
    'objectivity',
    'planning',
  ];

  const fourAspectsTypes = [
    'ECBP',
    'ECBS',
    'ECOP',
    'ECOS',
    'ERBP',
    'ERBS',
    'EROP',
    'EROS',
    'ICBP',
    'ICBS',
    'ICOP',
    'ICOS',
    'IRBP',
    'IRBS',
    'IROP',
    'IROS',
  ];

  const getFourAspectsType = (fourAspectsAnalysis: {
    [trait: string]: string;
  }) => {
    const traitMap = fourAspectsTraits.map((trait: string) =>
      fourAspectsAnalysis[trait].charAt(0).toLocaleUpperCase()
    );
    return traitMap.join('');
  };

  const traitAndTypeDescriptions = {
    agreeableness: {
      title: 'Agreeable',
      description: [
        "Agreeableness measures an individual's concern for cooperation and social harmony. Agreeable individuals are considerate, friendly, generous, helpful, and willing to compromise, often believing in the inherent honesty and decency of others. They are optimistic about human nature and value getting along with others.",
        "Disagreeable individuals prioritize self-interest over social harmony, often being unconcerned with others' well-being. Their skepticism about others' motives can make them suspicious, unfriendly, and uncooperative. While agreeableness aids in popularity and likability, it may not be advantageous in situations requiring tough or objective decisions. Disagreeable individuals may excel in roles such as scientists, critics, or soldiers, where critical thinking and skepticism are beneficial.",
      ],
      summarizedDescription: [
        "Agreeableness measures an individual's concern for cooperation and social harmony.",
        'Agreeable individuals are considerate, friendly, generous, helpful, and willing to compromise, often believing in the inherent honesty and decency of others.',
      ],
    },
    conscientiousness: {
      title: 'Conscientious',
      description: [
        'Conscientiousness involves the control, regulation, and direction of impulses. It is linked to intelligence, as it enables individuals to consider future consequences before acting. This trait encompasses the contemplation of long-term goals, organized planning, and persistence despite short-lived impulses. High conscientiousness, also known as prudence, indicates wisdom and caution.',
        'People high in this trait are seen as intelligent and reliable, often avoiding trouble and achieving success through careful planning and perseverance. However, they may also be viewed as compulsive perfectionists and workaholics, sometimes perceived as stuffy and boring. In contrast, those low in conscientiousness may be seen as unreliable and lacking ambition but enjoy short-lived pleasures and are never considered stuffy.',
      ],
      summarizedDescription: [
        'Conscientiousness involves the control, regulation, and direction of impulses.',
        'This trait encompasses the contemplation of long-term goals, organized planning, and persistence despite short-lived impulses.',
      ],
    },
    extraversion: {
      title: 'Extroverted',
      description: [
        'Extraversion involves active engagement with the external world. Extroverts enjoy being with people, are energetic, and often experience positive emotions. They are enthusiastic, action-oriented, and eager for excitement, frequently asserting themselves and seeking attention in social settings.',
        'In contrast, introverts are quieter, more deliberate, and less socially active. They require less stimulation and prefer solitude, which is not due to shyness or depression. Their independence and reserve may be mistaken for unfriendliness or arrogance, but introverts can be pleasant when approached, despite not seeking social interaction.',
      ],
      summarizedDescription: [
        'Extraversion involves active engagement with the external world.',
        'Extroverts enjoy being with people, are energetic, and often experience positive emotions.',
      ],
    },
    neuroticism: {
      title: 'Neurotic',
      description: [
        "Neuroticism, initially described by Freud as neurosis, involves mental distress, emotional suffering, and difficulty coping with life's demands. Freud believed everyone exhibits some neurosis to varying degrees. Today, neuroticism refers to the tendency to experience negative emotions such as anxiety, anger, or depression.",
        "Those high in neuroticism are emotionally reactive, responding intensely to events that wouldn't affect most people. They often see ordinary situations as threatening and minor frustrations as extremely difficult. Their negative emotions persist longer, often leaving them in a bad mood. These issues in emotional regulation can impair their ability to think clearly, make decisions, and manage stress effectively.",
      ],
      summarizedDescription: [
        "Neuroticism, initially described by Freud as neurosis, involves mental distress, emotional suffering, and difficulty coping with life's demands.",
        'Freud believed everyone exhibits some neurosis to varying degrees.',
      ],
    },
    openness: {
      title: 'Open to Experience',
      description: [
        'People high in this trait are intellectually curious, appreciative of art, and sensitive to beauty. They are more aware of their feelings and tend to think and act in unique, nonconforming ways. Often referred to as Culture or Intellect, this trait includes a strong intellectual component but is only modestly related to education level and intelligence test scores.',
        'Open individuals excel in symbolic and abstract thinking, which can manifest in various forms such as mathematical, logical, artistic, metaphorical, musical, or visual and performing arts.',
      ],
      summarizedDescription: [
        'People high in this trait are intellectually curious, appreciative of art, and sensitive to beauty.',
        'They are more aware of their feelings and tend to think and act in unique, nonconforming ways.',
      ],
    },
    compliance: {
      title: 'Compliant',
      description: [
        'Compliance styles tend to be cautious and reflective + questioning and skeptical.',
        'The Compliance personality trait in the DISC assessment is marked by a focus on accuracy, orderliness, and dependability. Individuals with a high Compliance trait are meticulous, methodical, and highly organized. They prioritize precision and quality in their work, often paying close attention to detail and following through on tasks with thoroughness. They value structure, rules, and procedures, and are motivated by a desire to achieve high standards and ensure that everything is done correctly. This trait also includes a strong sense of responsibility and a commitment to meeting deadlines and fulfilling obligations. Compliant individuals are often seen as reliable and disciplined, making them effective in roles that require careful planning and execution. However, their emphasis on detail and adherence to rules can sometimes lead to perfectionism or difficulty adapting to unexpected changes. They thrive in environments that offer clear guidelines and well-defined expectations.',
      ],
      summarizedDescription: [
        'The Compliance trait in the DISC assessment emphasizes accuracy, orderliness, and dependability.',
        'High compliance individuals are meticulous, methodical, and organized, prioritizing precision and quality.',
      ],
    },
    dominance: {
      title: 'Dominant',
      description: [
        'Dominant styles tend to be fast-paced and outspoken + questioning and skeptical.',
        'The Dominant personality trait in the DISC assessment is characterized by a focus on results, assertiveness, and a drive for achieving goals. Individuals with a high Dominant trait are typically bold, confident, and competitive, preferring to take charge and tackle challenges head-on. They are decisive, often taking quick actions to address problems and seize opportunities. This trait is marked by a strong desire for control and influence, and a readiness to make decisions and take risks. Dominant individuals are usually driven by a need for achievement and success, and they may be less concerned with how their actions impact others, focusing instead on the end results. Their assertiveness can make them effective leaders and problem-solvers, though it might also come across as demanding or impatient to those who prefer a more collaborative approach.',
      ],
      summarizedDescription: [
        'The Dominant trait in the DISC assessment is marked by a focus on results, assertiveness, and goal achievement.',
        'Individuals with this trait are bold, confident, competitive, and decisive, preferring to take charge and tackle challenges head-on.',
      ],
    },
    influence: {
      title: 'Influential',
      description: [
        'Influence styles tend to be fast-paced and outspoken + accepting and warm.',
        'The Influence personality trait in the DISC assessment is characterized by sociability, enthusiasm, and a strong focus on building relationships. Individuals with a high Influence trait are typically outgoing, persuasive, and energetic. They excel in social settings and are skilled at engaging and motivating others through their optimism and charisma. They thrive on interaction and enjoy inspiring and influencing people to achieve common goals. These individuals are often seen as warm, friendly, and approachable, making them effective communicators and networkers. They value social harmony and are adept at creating a positive and collaborative atmosphere. However, their focus on social interaction and enthusiasm may sometimes lead them to overlook details or become overly idealistic. Their strength lies in their ability to connect with others and foster a supportive and lively environment.',
      ],
      summarizedDescription: [
        'The Influence trait in the DISC assessment is marked by sociability, enthusiasm, and relationship-building.',
        'High Influence individuals are outgoing, persuasive, and energetic, excelling in social settings and motivating others through optimism and charisma.',
      ],
    },
    stability: {
      title: 'Stable',
      description: [
        'Stable styles tend to be cautious and reflective + accepting and warm.',
        'The Stable personality trait in the DISC assessment is characterized by a calm, patient, and dependable demeanor. Individuals with a high Stable trait are known for their reliability and consistency. They value stability and harmony, often preferring to maintain a steady pace and avoid sudden changes or disruptions. They are excellent listeners, empathetic, and supportive, making them effective team players who foster a cooperative and nurturing environment. Stable individuals are often described as approachable, trustworthy, and resistant to stress, which allows them to manage conflict and challenges with a composed and measured approach. They typically excel in roles that require patience and attention to detail, and they work best in supportive, stable environments where their contributions can be made with consistency and care. However, their aversion to change and preference for routine can sometimes make them resistant to new ideas or methods.',
      ],
      summarizedDescription: [
        'The Stable trait in the DISC assessment is marked by calmness, patience, and dependability.',
        'Highly stable individuals value stability, prefer a steady pace, and avoid sudden changes.',
      ],
    },
    ECBP: {
      title: 'Diplomats - The Mentor (ECBP)',
      description: [
        'This personality type is known for their warm, empathetic, and attentive personalities. They see potential in everyone and are driven to help others realize their full potential, often acting as catalysts for both individual and group growth. Loyal and receptive to both praise and criticism, they are sociable and excel at facilitating group dynamics, providing inspiring leadership.',
      ],
      summarizedDescription: [],
    },
    ECBS: {
      title: 'Diplomats - The Connector (ECBS)',
      description: [
        'This personality type tends to be warmly enthusiastic and imaginative. They view life as brimming with possibilities. They quickly make connections between events and information, confidently acting on the patterns they discern. They seek ample affirmation from others and are generous in offering appreciation and support. Spontaneous and adaptable, they often depend on their improvisational skills and verbal fluency.',
      ],
      summarizedDescription: [],
    },
    ECOP: {
      title: 'Analysts - The Leader (ECOP)',
      description: [
        'This personality type is often very direct and decisive, they naturally take on leadership roles. They quickly identify illogical or inefficient procedures and develop and implement effective systems to address organizational issues. They take pleasure in long-term planning and setting goals. Typically well-informed and well-read, they enjoy expanding their knowledge and sharing it with others. They are assertive when presenting their ideas.',
      ],
      summarizedDescription: [],
    },
    ECOS: {
      title: 'Analysts - The Innovator (ECOS)',
      description: [
        'This personality type tends to be quick-witted, inventive, and lively. They are sharp and expressive in their approach. They excel at tackling new and challenging problems with resourcefulness. Skilled at generating innovative ideas and analyzing them strategically, they are also adept at reading others. They easily become bored with routine, often avoiding repetitive tasks, and are likely to pursue a series of new interests in rapid succession.',
      ],
      summarizedDescription: [],
    },
    ERBP: {
      title: 'Sentinels - The Loyalist (ERBP)',
      description: [
        'This personality type is typically warmhearted, conscientious, and cooperative as they strive to create harmony in their surroundings and work diligently to achieve it. They enjoy collaborating with others to complete tasks accurately and on schedule. Loyal and dependable, they follow through even on small matters. Attentive to the needs of others, they actively seek to provide support in everyday situations. They desire appreciation for both who they are and the contributions they make.',
      ],
      summarizedDescription: [],
    },
    ERBS: {
      title: 'Explorers - The Collaborator (ERBS)',
      description: [
        'This personality type tends to be outgoing, friendly, and accepting. They are exuberant enthusiasts of life, people, and material comforts. They thrive on collaborating with others to achieve goals and bring a practical, realistic approach to their work, often making it enjoyable. Flexible and spontaneous, they adapt easily to new people and environments. They learn best by actively trying new skills in the company of others.',
      ],
      summarizedDescription: [],
    },
    EROP: {
      title: 'Sentinels - The Decider (EROP)',
      description: [
        'This personality type is often very practical, realistic and matter-of-fact. They are usually very decisive and quick to act on their decisions. They excel at organizing projects and people to achieve results efficiently, ensuring routine details are managed effectively. Guided by a clear set of logical standards, they follow these systematically and expect others to do the same. They are assertive in implementing their plans and driving them to completion.',
      ],
      summarizedDescription: [],
    },
    EROS: {
      title: 'Explorers - The Executor (EROS)',
      description: [
        'This personality type tends to be flexible and tolerant, taking a practical approach that prioritizes immediate results. They quickly become bored with theories and abstract concepts, preferring to act energetically to address problems. Focused on the present moment, they are spontaneous and relish opportunities to be actively engaged with others. They appreciate material comforts and a sense of style and learn best through hands-on experience.',
      ],
      summarizedDescription: [],
    },
    ICBP: {
      title: 'Diplomats - The Idealist (ICBP)',
      description: [
        'This personality type tends to seek meaning and connection in ideas, relationships, and material possessions, aiming to understand what motivates people and showing keen insight into others. Conscientious and devoted to their core values, they cultivate a clear vision for serving the common good. They are both organized and decisive in bringing their vision to fruition.',
      ],
      summarizedDescription: [],
    },
    ICBS: {
      title: 'Diplomats - The Dreamer (ICBS)',
      description: [
        'This personality type is usually idealistic and deeply loyal, they are committed to their values and to people who are important to them. They strive to live a life aligned with their principles and are curious, with a keen ability to spot possibilities and act as catalysts for bringing ideas to life. They seek to understand others and help them reach their full potential. They are adaptable, flexible, and accepting, except when their values are challenged.',
      ],
      summarizedDescription: [],
    },
    ICOP: {
      title: 'Analysts - The Strategist (ICOP)',
      description: [
        'This personality type possesses creative minds and a strong drive to implement their ideas and achieve their goals. They quickly identify patterns in external events and develop long-term perspectives to explain them. When committed, they excel at organizing tasks and seeing them through to completion. Skeptical and independent, they maintain high standards of competence and performance for both themselves and others.',
      ],
      summarizedDescription: [],
    },
    ICOS: {
      title: 'Analysts - The Thinker (ICOS)',
      description: [
        'This personality type strives to create logical explanations for everything that piques their interest. Theoretical and abstract, they are more drawn to ideas than to social interactions. Quiet and composed, they are flexible and adaptable. They possess a remarkable ability to concentrate deeply on solving problems within their areas of interest. Skeptical and occasionally critical, they maintain a consistently analytical approach.',
      ],
      summarizedDescription: [],
    },
    IRBP: {
      title: 'Sentinels - The Harmonizer (IRBP)',
      description: [
        "This personality type has a tendency to be quiet, friendly and conscientious. They are responsible and dedicated to meeting their commitments. They approach tasks with thoroughness, accuracy, and attention to detail. Loyal and considerate, they remember specific details about people who matter to them and are attentive to others' feelings. They aim to create an orderly and harmonious environment both at work and at home.",
      ],
      summarizedDescription: [],
    },
    IRBS: {
      title: 'Explorers - The Feeler (IRBS)',
      description: [
        'This personality type tends to be quiet, friendly and kind hearted and can often be sensitive to their surroundings. Feeler types typically value their personal space and prefer working at their own pace. Loyal and dedicated, they are committed to their core values and to those who matter most to them. They avoid disagreements and conflicts and do not impose their opinions or values on others.',
      ],
      summarizedDescription: [],
    },
    IROP: {
      title: 'Sentinels - The Trusty (IROP)',
      description: [
        'This personality type has a tendency to achieve success through thoroughness and dependability. Practical and realistic, they approach life in a matter-of-fact and responsible manner. They make decisions logically and work steadily towards their goals, undeterred by distractions. They take great satisfaction in keeping everything orderly and organized, from their work to their home and personal life.',
      ],
      summarizedDescription: [],
    },
    IROS: {
      title: 'Explorers - The Fixer (IROS)',
      description: [
        'This personality type tends to be tolerant and flexible of new situations, remaining calm and observant until a problem arises, at which point they act quickly to find a solution. Fixer type personalities tend to value data and facts, relying on realism and objectivity to come to their decisions rather than theoretical ideas or opinions. This type also values spontaneity, giving them the ability to adapt quickly to new situations as they arise.',
      ],
      summarizedDescription: [],
    },
    extraversion2: {
      title: 'Extraversion',
      description: [],
      summarizedDescription: [
        'Extraverted (E) vs. Introverted (I)',
        'Extraversion is a personality trait that describes people who are outgoing, energetic and tend to gain energy from being around others.',
        'In contrast with extraversion, introversion is a personality trait that describes people who are shy, focused on their inner thoughts and tend to gain energy by being alone.',
        'Most people tend to experience both extroverted and introverted moments throughout their lives.',
      ],
    },
    objectivity: {
      title: 'Objectivity',
      description: [],
      summarizedDescription: [
        'Objective (O) vs. Biased (B)',
        'Objectivity is a personality trait that describes people who make decisions using logic and dispassionate reasoning.',
        'In contrast with objective decision-makers, biased decision-makers tend to make decisions based on preconceived ideas, morals, and emotions.',
        'Most people experience both extremes of this aspect throughout their lives, sometimes relying on pure logic and other times allowing their biases and emotions to influence their decisions.',
      ],
    },
    planning: {
      title: 'Planning',
      description: [],
      summarizedDescription: [
        'Planned (P) vs. Spontaneous (S)',
        'The aspect of Planning describes how people approach the world around them.',
        'Planners, individuals who score high in this aspect, tend to make detailed plans and follow through on their commitments, with a strong focus on execution and achieving their goals in an organized manner.',
        'In contrast, spontaneous individuals prefer to live life in a more adaptable and flexible way, often making decisions on the fly and embracing uncertainty.',
      ],
    },
    realism: {
      title: 'Realism',
      description: [],
      summarizedDescription: [
        'Realist (R) vs. Creative (C)',
        'Realism is a personality trait that describes how people react to new information and how they tend to view the world.',
        'Realists have a tendency to only consider the facts and disregard all other ideas that may seem impractical.',
        'In contrast with realism, creativity is a personality trait that describes people who put less emphasis on the facts and spend more time considering new possibilities.',
      ],
    },
  } as { [trait: string]: TraitDescription };

  const traitAndTypeStrengths = {
    agreeableness: [
      {
        label: 'Empathy',
        description:
          "Individuals high in agreeableness are often empathetic, understanding others' feelings and perspectives, which fosters strong interpersonal relationships.",
      },
      {
        label: 'Cooperation',
        description:
          'They are cooperative and willing to work collaboratively with others, which helps in team settings and promotes harmonious interactions.',
      },
      {
        label: 'Trustworthiness',
        description:
          'They tend to be reliable and trustworthy, making them dependable friends, colleagues, and partners.',
      },
      {
        label: 'Compassion',
        description:
          'Their compassionate nature leads them to offer support and assistance to those in need, contributing positively to their communities.',
      },
      {
        label: 'Conflict Resolution',
        description:
          'They are skilled at resolving conflicts and maintaining peace, often seeking compromise and avoiding confrontation.',
      },
      {
        label: 'Altruism',
        description:
          'They have a genuine desire to help others and contribute to the well-being of those around them, often engaging in acts of kindness.',
      },
    ],
    conscientiousness: [
      {
        label: 'Reliability',
        description:
          'Individuals high in conscientiousness are dependable and can be trusted to follow through on commitments and responsibilities.',
      },
      {
        label: 'Organization',
        description:
          'They excel at organizing tasks, projects, and environments, leading to effective time management and systematic work processes.',
      },
      {
        label: 'Attention to Detail',
        description:
          'They are meticulous and thorough, paying close attention to details to ensure accuracy and high-quality results.',
      },
      {
        label: 'Goal-Oriented',
        description:
          'They set clear goals and work diligently towards achieving them, often showing strong persistence and perseverance.',
      },
      {
        label: 'Self-Discipline',
        description:
          'They possess a high level of self-control and are able to resist distractions, maintaining focus on long-term objectives.',
      },
      {
        label: 'Planning and Preparation',
        description:
          'They are skilled at planning and preparing for future tasks and challenges, which helps them manage complex projects efficiently.',
      },
    ],
    extraversion: [
      {
        label: 'Sociable',
        description:
          'Individuals high in extraversion are often outgoing and enjoy social interactions, making it easier for them to connect with others and build relationships',
      },
      {
        label: 'Energetic',
        description:
          'They tend to have high levels of energy and enthusiasm, which can be motivating and uplifting for those around them.',
      },
      {
        label: 'Assertive',
        description:
          'They are confident in expressing their opinions and taking charge of situations, often demonstrating strong leadership qualities.',
      },
      {
        label: 'Optimistic',
        description:
          'They generally maintain a positive outlook on life, which can be contagious and foster a more enjoyable environment.',
      },
      {
        label: 'Active',
        description:
          'They are often engaged in a variety of activities and seek out new experiences, contributing to a dynamic and exciting lifestyle.',
      },
      {
        label: 'Good Communication Skills',
        description:
          'Their comfort in social settings allows them to communicate effectively and persuasively, making them adept at networking and collaboration.',
      },
    ],
    neuroticism: [
      {
        label: 'Empathy',
        description:
          ' Those high in agreeableness are often deeply empathetic, understanding and sharing the feelings of others, which enhances interpersonal connections.',
      },
      {
        label: 'Cooperation',
        description:
          'They excel in collaborative environments, working well with others and contributing to team success with a cooperative attitude.',
      },
      {
        label: 'Trustworthiness',
        description:
          'Their reliability and honesty build trust, making them dependable friends, colleagues, and partners.',
      },
      {
        label: 'Compassion',
        description:
          'They are naturally compassionate, frequently showing concern and kindness towards others, and helping those in need.',
      },
      {
        label: 'Conflict Resolution',
        description:
          'They are skilled at managing conflicts and finding amicable solutions, striving to maintain harmony in relationships and group settings.',
      },
      {
        label: 'Altruism',
        description:
          'They are motivated by a desire to help others, often engaging in altruistic behaviors and contributing positively to their communities.',
      },
    ],
    openness: [
      {
        label: 'Creativity',
        description:
          'Individuals high in openness are often very creative and imaginative, able to generate novel ideas and solutions to problems.',
      },
      {
        label: 'Curiousity',
        description:
          'They possess a strong desire to learn and explore new concepts, ideas, and experiences, making them intellectually adventurous.',
      },
      {
        label: 'Adaptability',
        description:
          'They are open to change and new experiences, which allows them to adapt easily to different situations and environments.',
      },
      {
        label: 'Appreciation of Art and Beauty',
        description:
          'They have a deep appreciation for art, literature, and aesthetic experiences, often engaging with and valuing these aspects of life.',
      },
      {
        label: 'Intellectual Engagement',
        description:
          'They enjoy engaging in abstract thinking and theoretical discussions, which can lead to a deeper understanding of complex issues.',
      },
      {
        label: 'Open-Mindedness',
        description:
          'They tend to be more accepting of diverse perspectives and unconventional ideas, contributing to a more inclusive approach to problem-solving and interpersonal interactions.',
      },
    ],
    compliance: [
      {
        label: 'Detail-Oriented',
        description:
          'Individuals high in Compliance are meticulous and thorough, ensuring that tasks are completed accurately and to a high standard.',
      },
      {
        label: 'Organized',
        description:
          'They excel at organizing tasks and projects, creating structured plans and systems to achieve their goals efficiently.',
      },
      {
        label: 'Dependable',
        description:
          'They are reliable and consistent, often seen as trustworthy and committed to meeting deadlines and fulfilling responsibilities.',
      },
      {
        label: 'High Standards',
        description:
          'They set and maintain high standards for themselves and others, striving for excellence and quality in their work.',
      },
      {
        label: 'Analytical',
        description:
          'They are adept at analyzing complex information and making informed decisions based on careful evaluation.',
      },
      {
        label: 'Focused',
        description:
          'They have a strong ability to concentrate on tasks, minimizing distractions and working persistently towards their objectives.',
      },
    ],
    dominance: [
      {
        label: 'Decisive',
        description:
          'Individuals high in the Dominant aspect are quick to make decisions and take action, which can drive progress and achieve results efficiently.',
      },
      {
        label: 'Leadership',
        description:
          'They naturally take charge of situations, providing clear direction and motivation to others, and often excel in leadership roles.',
      },
      {
        label: 'Goal-Oriented',
        description:
          'They are focused on achieving goals and overcoming challenges, showing persistence and determination in their efforts.',
      },
      {
        label: 'Assertive',
        description:
          'They are confident in expressing their opinions and taking initiative, which can lead to effective problem-solving and innovation.',
      },
      {
        label: 'Problem-Solving',
        description:
          'They are skilled at identifying issues and developing practical solutions, often demonstrating strategic thinking.',
      },
      {
        label: 'Action-Oriented',
        description:
          'They prefer to act and make things happen rather than spend time on detailed planning or theoretical discussions.',
      },
    ],
    influence: [
      {
        label: 'Charismatic',
        description:
          'Individuals high in the Influence aspect are often outgoing and engaging, which helps them build strong relationships and motivate others.',
      },
      {
        label: 'Persuasive',
        description:
          'They are skilled at influencing others and gaining support for their ideas, often excelling in roles that require negotiation or selling.',
      },
      {
        label: 'Enthusiastic',
        description:
          'Their positive energy and enthusiasm can inspire and energize those around them, contributing to a lively and dynamic environment.',
      },
      {
        label: 'Excellent Communicators',
        description:
          'They have strong verbal skills and can articulate their ideas effectively, making them adept at public speaking and networking.',
      },
      {
        label: 'Adaptable',
        description:
          'They are flexible and able to adjust to changing circumstances and people, making it easier for them to thrive in various social and professional settings.',
      },
      {
        label: 'Socially Skilled',
        description:
          'They are comfortable in social situations and excel at building and maintaining relationships, which can be beneficial in collaborative environments.',
      },
    ],
    stability: [
      {
        label: 'Reliable',
        description:
          'Individuals high in the Stability aspect are dependable and consistent, making them trustworthy and valuable team members.',
      },
      {
        label: 'Patient',
        description:
          'They exhibit patience in dealing with tasks and people, contributing to a calm and stable work environment.',
      },
      {
        label: 'Supportive',
        description:
          'They are often empathetic and supportive, offering assistance and encouragement to colleagues, which fosters a collaborative atmosphere.',
      },
      {
        label: 'Good Listeners',
        description:
          'They are attentive and considerate listeners, providing thoughtful feedback and understanding to others.',
      },
      {
        label: 'Team-Oriented',
        description:
          'They excel in team settings, valuing harmony and cooperation, and work well to maintain a cohesive group dynamic.',
      },
      {
        label: 'Adaptable',
        description:
          'They handle change with a steady approach and are often open to supporting others through transitions, even if they prefer stability themselves.',
      },
    ],
    ECBP: [
      {
        label: 'Empathetic and Supportive',
        description:
          'They are highly attuned to the emotions and needs of others, offering strong support and encouragement to those around them.',
      },
      {
        label: 'Charismatic and Inspiring',
        description:
          'Known for their ability to motivate and inspire, they are often seen as natural leaders who can energize and uplift groups.',
      },
      {
        label: 'Organized and Efficient',
        description:
          'They are skilled at organizing tasks and people, ensuring that goals are met efficiently and effectively.',
      },
      {
        label: 'Skilled Communicators',
        description:
          'They excel in communication, able to express ideas clearly and persuasively, fostering positive relationships and collaboration.',
      },
      {
        label: 'Dedicated and Loyal',
        description:
          'They are deeply committed to their values and to the people they care about, showing strong loyalty and reliability.',
      },
      {
        label: 'Visionary',
        description:
          'They have a clear vision for the future and work passionately towards achieving their goals, often helping others to see and reach their own potential.',
      },
    ],
    ECBS: [
      {
        label: 'Creative and Imaginative',
        description:
          'They are highly creative, often coming up with innovative ideas and seeing possibilities that others might overlook.',
      },
      {
        label: 'Enthusiastic and Energetic',
        description:
          'Known for their warmth and enthusiasm, they bring energy and excitement to their pursuits and interactions.',
      },
      {
        label: 'Empathetic and Supportive',
        description:
          'They have a strong ability to connect with others emotionally, offering genuine support and encouragement.',
      },
      {
        label: 'Spontaneous and Flexible',
        description:
          'They adapt easily to new situations and are open to exploring various paths, thriving in dynamic environments.',
      },
      {
        label: 'Excellent Communicators',
        description:
          'Their verbal fluency and ability to articulate their thoughts make them effective in expressing their ideas and engaging with others.',
      },
      {
        label: 'Inspirational',
        description:
          'They often inspire and motivate those around them with their positive outlook and passion for new ideas.',
      },
    ],
    ECOP: [
      {
        label: 'Natural Leaders',
        description:
          'They excel in leadership roles, confidently taking charge and guiding teams toward achieving goals. Their ability to make decisions and direct others is a key strength.',
      },
      {
        label: 'Strategic Thinkers',
        description:
          'They are adept at long-term planning and strategic thinking, capable of developing comprehensive plans to address complex issues and drive progress.',
      },
      {
        label: 'Decisive and Action-Oriented',
        description:
          'Known for their decisiveness, they act quickly and effectively, ensuring that plans are implemented efficiently and objectives are met.',
      },
      {
        label: 'Organized and Efficient',
        description:
          'They excel at organizing resources and tasks, focusing on efficiency and productivity to achieve results.',
      },
      {
        label: 'Persuasive Communicators',
        description:
          'They are skilled at presenting their ideas convincingly and inspiring others to embrace their vision and goals.',
      },
      {
        label: 'Confident and Driven',
        description:
          'They are confident in their abilities and driven to achieve success, setting high standards for themselves and others.',
      },
    ],
    ECOS: [
      {
        label: 'Innovative and Creative',
        description:
          'They are highly inventive and excel at generating novel ideas and solutions.',
      },
      {
        label: 'Quick Thinkers',
        description:
          'They think on their feet and can rapidly analyze situations to come up with effective strategies or solutions.',
      },
      {
        label: 'Charismatic and Persuasive',
        description:
          'They possess strong communication skills and are skilled at convincing others of their ideas. Their charisma often helps them influence and inspire those around them.',
      },
      {
        label: 'Adaptable and Flexible',
        description:
          'They thrive in dynamic environments and are able to adjust quickly to new situations or changes in plans.',
      },
      {
        label: 'Curious and Open-Minded',
        description:
          'Driven by curiosity, they are eager to explore new concepts and possibilities. Their open-mindedness allows them to consider various viewpoints and ideas.',
      },
      {
        label: 'Energetic and Enthusiastic',
        description:
          'They bring a high level of energy and enthusiasm to their projects and interactions, which can be motivating for those around them.',
      },
    ],
    ERBP: [
      {
        label: 'Warm and Friendly',
        description:
          'Individuals with this personality type are naturally warm and friendly, making them approachable and easy to get along with.',
      },
      {
        label: 'Empathetic and Caring',
        description:
          'They are deeply empathetic, often tuning into the needs and emotions of others, and are motivated to provide support and care.',
      },
      {
        label: 'Organized and Dependable',
        description:
          'They are highly organized and reliable, ensuring tasks are completed on time and to a high standard. Their dependability makes them trustworthy team members.',
      },
      {
        label: 'Loyal and Committed',
        description:
          'They value loyalty and are dedicated to their relationships, whether personal or professional. Their commitment to others is a defining trait.',
      },
      {
        label: 'Strong Interpersonal Skills',
        description:
          'They excel in social settings, building strong relationships and fostering a sense of community. Their cooperative nature helps them work well in teams.',
      },
      {
        label: 'Responsible and Conscientious',
        description:
          'They take their responsibilities seriously and strive to meet their obligations diligently, often going above and beyond what is required.',
      },
    ],
    ERBS: [
      {
        label: 'Charismatic and Energetic',
        description:
          'Individuals with this personality type are often lively and engaging, drawing people in with their vibrant energy and enthusiasm.',
      },
      {
        label: 'Social and Friendly',
        description:
          'They excel in social settings, easily making connections and building relationships with others. Their warmth and approachability make them popular in group settings.',
      },
      {
        label: 'Practical and Realistic',
        description:
          'They have a strong sense of practicality, focusing on what works in the here and now rather than getting bogged down by abstract theories.',
      },
      {
        label: 'Spontaneous and Adaptable',
        description:
          'They handle change well and are flexible in responding to new situations, often embracing spontaneity and enjoying the moment.',
      },
      {
        label: 'Creative and Fun-Loving',
        description:
          'Their creativity and zest for life often lead to innovative ideas and a playful approach to problem-solving, making activities and projects more enjoyable.',
      },
      {
        label: 'Empathetic and Supportive',
        description:
          'They are attentive to the emotions of others and are often seen as supportive and understanding friends and colleagues.',
      },
    ],
    EROP: [
      {
        label: 'Decisive and Action-Oriented',
        description:
          'Individuals with this personality type are known for their decisiveness and ability to take swift action. They are effective at making decisions and implementing them efficiently.',
      },
      {
        label: 'Organized and Efficient',
        description:
          'They excel at organizing projects and people, focusing on achieving results in the most efficient way possible. Their systematic approach ensures tasks are completed on time and to a high standard.',
      },
      {
        label: 'Practical and Realistic',
        description:
          'They have a practical mindset and focus on realistic, achievable goals. Their matter-of-fact approach helps them navigate challenges effectively.',
      },
      {
        label: 'Strong Leadership Skills',
        description:
          'They are natural leaders, capable of guiding and motivating others to achieve common objectives. Their clear direction and confidence inspire trust and respect.',
      },
      {
        label: 'Dependable and Responsible',
        description:
          'They take their responsibilities seriously and are reliable in fulfilling their commitments. Others can count on them to follow through on tasks and projects.',
      },
      {
        label: 'Logical and Analytical',
        description:
          'They possess a clear set of logical standards and systematically follow them. Their analytical skills help them make sound, rational decisions.',
      },
    ],
    EROS: [
      {
        label: 'Action-Oriented',
        description:
          'Executor types thrive in fast-paced environments and excel at making quick decisions. They are adept at taking immediate action to address challenges and seize opportunities.',
      },
      {
        label: 'Practical Problem-Solvers',
        description:
          'They have a strong ability to analyze situations in real-time and come up with effective, pragmatic solutions. Their focus is on results and efficiency.',
      },
      {
        label: 'Charismatic and Sociable',
        description:
          'They are outgoing and engaging, often drawing people to them with their energetic and confident demeanor. They are skilled at networking and building relationships.',
      },
      {
        label: 'Adaptable and Flexible',
        description:
          'They handle changes and unexpected situations well, quickly adjusting their approach to meet new demands or opportunities.',
      },
      {
        label: 'Courageous and Risk-Taking',
        description:
          'They are often willing to take risks and face challenges head-on. Their boldness and willingness to embrace uncertainty can lead to significant achievements.',
      },
      {
        label: 'Hands-On Learners',
        description:
          'They learn best through direct experience and practical involvement, often preferring action over theoretical learning.',
      },
    ],
    ICBP: [
      {
        label: 'Empathetic and Insightful',
        description:
          "They possess a deep understanding of others' emotions and motivations, which allows them to offer meaningful support and guidance.",
      },
      {
        label: 'Visionary',
        description:
          'They have a strong sense of purpose and are often guided by a clear vision of how they want to contribute to the world, focusing on long-term goals and ideals.',
      },
      {
        label: 'Highly Committed',
        description:
          'Once they commit to a cause or a relationship, they do so with dedication and loyalty, working tirelessly to uphold their values.',
      },
      {
        label: 'Organized and Strategic',
        description:
          'They are capable of creating detailed plans and strategies to achieve their goals, effectively organizing their efforts to realize their vision.',
      },
      {
        label: 'Creative Problem-Solvers',
        description:
          'Their ability to think outside the box enables them to come up with innovative solutions to complex problems.',
      },
      {
        label: 'Strong Values',
        description:
          'They have a well-defined set of values and principles, guiding their decisions and actions with integrity and authenticity.',
      },
    ],
    ICBS: [
      {
        label: 'Idealistic and Principled',
        description:
          'They are deeply committed to their values and ideals, striving to live authentically and make a positive impact in the world.',
      },
      {
        label: 'Empathetic and Understanding',
        description:
          'They possess a strong sense of empathy and are sensitive to the feelings and needs of others, often offering genuine support and encouragement.',
      },
      {
        label: 'Creative and Imaginative',
        description:
          'They have a rich inner world and excel in creative endeavors, often coming up with innovative ideas and unique solutions.',
      },
      {
        label: 'Open-Minded and Accepting',
        description:
          'They are open to different perspectives and approaches, valuing diversity and embracing individuality in themselves and others.',
      },
      {
        label: 'Dedicated and Loyal',
        description:
          'They show deep loyalty to their friends, family, and causes they care about, often going to great lengths to support and stand by them.',
      },
      {
        label: 'Reflective and Thoughtful',
        description:
          'They are introspective and spend time contemplating their own thoughts and feelings, leading to personal growth and self-awareness.',
      },
    ],
    ICOP: [
      {
        label: 'Strategic and Visionary',
        description:
          'Individuals with this personality type excel at thinking long-term and developing comprehensive strategies. They are adept at creating detailed plans and seeing the bigger picture.',
      },
      {
        label: 'Analytical and Objective',
        description:
          'They are highly analytical, relying on logic and objective reasoning to solve problems. Their ability to analyze complex systems and scenarios is a key strength.',
      },
      {
        label: 'Independent and Self-Motivated',
        description:
          'They are highly independent and self-driven, capable of working alone and pursuing their goals with minimal supervision or external motivation.',
      },
      {
        label: 'Decisive and Efficient',
        description:
          'Known for their decisiveness, they make well-considered decisions quickly and efficiently. Their focus on efficiency helps them achieve their goals effectively.',
      },
      {
        label: 'Innovative and Creative',
        description:
          'They often bring innovative ideas and solutions to the table, using their creativity to approach challenges from unique angles.',
      },
      {
        label: 'High Standards and Integrity',
        description:
          'They hold themselves and others to high standards of competence and integrity, striving for excellence and upholding their principles.',
      },
    ],
    ICOS: [
      {
        label: 'Innovative Thinkers',
        description:
          'They excel at generating creative ideas and innovative solutions. Their ability to think abstractly allows them to approach problems from unique angles.',
      },
      {
        label: 'Analytical and Objective',
        description:
          'They are highly analytical, relying on logic and critical thinking to understand complex systems and solve problems effectively.',
      },
      {
        label: 'Curious and Open-Minded',
        description:
          'Driven by a strong intellectual curiosity, they are eager to explore new concepts and theories. Their open-mindedness enables them to consider various perspectives.',
      },
      {
        label: 'Independent and Self-Reliant',
        description:
          'They prefer to work independently and are highly self-reliant. They can dive deeply into their interests without needing external motivation.',
      },
      {
        label: 'Adaptable and Flexible',
        description:
          'They are adaptable and open to changing their approach as new information or insights arise. This flexibility helps them navigate complex and evolving situations.',
      },
      {
        label: 'Detail-Oriented',
        description:
          'They have a keen eye for detail and are capable of deep focus when working on problems within their area of interest.',
      },
    ],
    IRBP: [
      {
        label: 'Reliable and Dependable',
        description:
          'Individuals with this personality type are known for their reliability and strong sense of duty. They can be trusted to follow through on commitments and meet their responsibilities',
      },
      {
        label: 'Detail-Oriented and Thorough:',
        description:
          'They have a keen eye for detail and are meticulous in their work, ensuring tasks are completed accurately and efficiently.',
      },
      {
        label: 'Supportive and Caring',
        description:
          'They are empathetic and considerate, often putting the needs of others before their own. Their supportive nature makes them excellent caregivers and friends.',
      },
      {
        label: 'Organized and Practical',
        description:
          'They excel at organizing their work and personal lives, creating systems and structures that help them manage tasks effectively. They approach problems with a practical mindset, finding workable and efficient solutions.',
      },
      {
        label: 'Loyal and Committed',
        description:
          'They value loyalty and are dedicated to the people and causes they care about. Their commitment makes them trustworthy and dependable partners and team members.',
      },
      {
        label: 'Observant and Remember Details',
        description:
          "They notice and remember specifics about people who are important to them, which helps them build strong, personal connections and understand others' needs and preferences.",
      },
    ],
    IRBS: [
      {
        label: 'Creative and Artistic',
        description:
          'Feeler types often have a strong sense of aesthetics and creativity, excelling in artistic and expressive fields such as art, music, and design.',
      },
      {
        label: 'Sensitive and Empathetic',
        description:
          'They are deeply in tune with their own emotions and the feelings of others, making them compassionate and understanding in relationships.',
      },
      {
        label: 'Adaptable and Flexible',
        description:
          'They are open to new experiences and changes, often going with the flow and adapting to different situations as they arise.',
      },
      {
        label: 'Authentic and Genuine',
        description:
          'They value authenticity and strive to live in alignment with their true selves and values, often being sincere and genuine in their interactions.',
      },
      {
        label: 'Patient and Non-Judgmental',
        description:
          'They are generally patient and accepting of others’ differences, avoiding judgment and fostering a supportive environment.',
      },
      {
        label: 'Independent',
        description:
          'They value their personal freedom and often prefer to work independently or in flexible environments where they can set their own pace.',
      },
    ],
    IROP: [
      {
        label: 'Dependable and Responsible',
        description:
          'Individuals with this personality type are known for their reliability and strong sense of duty. They can be counted on to follow through on commitments and meet their responsibilities.',
      },
      {
        label: 'Detail-Oriented and Thorough',
        description:
          'They have a keen eye for detail and are meticulous in their work. Their thorough approach ensures that tasks are completed accurately and efficiently.',
      },
      {
        label: 'Organized and Structured',
        description:
          'They excel at organizing their work and personal lives, creating systems and structures that help them manage tasks effectively.',
      },
      {
        label: 'Practical and Realistic',
        description:
          'Their focus on practicality and realism allows them to approach problems with a grounded perspective, finding workable and efficient solutions.',
      },
      {
        label: 'Loyal and Committed',
        description:
          'They value loyalty and are dedicated to the people and causes they care about. Their commitment makes them trustworthy and dependable partners and team members.',
      },
      {
        label: 'Logical and Analytical',
        description:
          'They use logic and analysis to make decisions, ensuring that their actions are based on sound reasoning and practical considerations.',
      },
    ],
    IROS: [
      {
        label: 'Analytical Problem Solving',
        description:
          'They tend to excel at breaking down complex problems into manageable parts in order to find practical solutions. Their logical and systematic approach allows them to solve issues efficiently.',
      },
      {
        label: 'Hands On Skills',
        description:
          'They often have a natural talent for working with their hands and understanding how things work. This makes them skilled in technical and mechanical tasks.',
      },
      {
        label: 'Adaptability',
        description:
          'Fixer types are typically flexible and can quickly adjust to new situations or changes in their environment. They thrive in dynamic and fast-paced settings.',
      },
      {
        label: 'Calm Under Pressure',
        description:
          'They tend to stay calm and composed during stressful situations, allowing them to make clear-headed decisions and take effective action.',
      },
      {
        label: 'Independence',
        description:
          'They usually value their autonomy and are comfortable working alone.',
      },
      {
        label: 'Pragmatic',
        description:
          'They focus on what is practical and effective, often preferring straightforward, results-oriented approaches over abstract theories.',
      },
    ],
  } as { [trait: string]: PersonalityStrengthWeakness[] };

  const traitAndTypeWeaknesses = {
    agreeableness: [
      {
        label: 'Overly Agreeable',
        description:
          'Their strong desire to please others can sometimes lead to difficulty setting boundaries or saying no, resulting in potential personal or professional exploitation.',
      },
      {
        label: 'Avoidance of Conflict',
        description:
          'Their tendency to avoid conflict can lead to unresolved issues or suppressed feelings, which may impact long-term relationships or team dynamics.',
      },
      {
        label: 'Overemphasis on Harmony',
        description:
          'They might prioritize maintaining harmony at the expense of addressing important issues or making necessary but uncomfortable decisions.',
      },
      {
        label: 'Easily Manipulated',
        description:
          'Their trusting nature can sometimes make them vulnerable to manipulation or exploitation by less scrupulous individuals.',
      },
      {
        label: 'Self-Sacrifice',
        description:
          'They may sacrifice their own needs and desires for the sake of others, which can lead to personal dissatisfaction or neglect of their own well-being.',
      },
      {
        label: 'Lack of Assertiveness',
        description:
          'Their preference for accommodating others can result in a lack of assertiveness, potentially leading to challenges in advocating for themselves or their ideas.s',
      },
    ],
    conscientiousness: [
      {
        label: 'Perfectionism',
        description:
          'Their high standards can lead to perfectionism, where they may become overly critical of themselves and others, potentially causing stress and dissatisfaction.',
      },
      {
        label: 'Rigidity',
        description:
          'Their preference for structure and organization can sometimes result in rigidity, making it challenging for them to adapt to unexpected changes or new approaches.',
      },
      {
        label: 'Overemphasis on Work',
        description:
          'They might prioritize work and responsibilities to the detriment of personal relationships or leisure activities, leading to work-life imbalance.',
      },
      {
        label: 'Difficulty Delegating',
        description:
          'Their desire for control and high standards can make it difficult for them to delegate tasks, resulting in taking on too much themselves.',
      },
      {
        label: 'Overthinking',
        description:
          'Their thorough approach can lead to overthinking and excessive planning, which may slow down decision-making and action.',
      },
      {
        label: 'Struggle with Spontaneity',
        description:
          'Their preference for planning and structure may hinder their ability to be spontaneous or flexible in the face of unforeseen circumstances.',
      },
    ],
    extraversion: [
      {
        label: 'Impulsivity',
        description:
          'Their tendency to act on their energy and enthusiasm can sometimes lead to impulsive decisions without sufficient consideration.',
      },
      {
        label: 'Overstimulation',
        description:
          'They may become overwhelmed or exhausted by constant social interactions or activities, requiring downtime to recharge.',
      },
      {
        label: 'Difficulty with Reflection',
        description:
          'Their focus on external stimulation can sometimes lead to less time spent on introspection or deep thinking.',
      },
      {
        label: 'Need for Attention',
        description:
          'They may seek validation and attention from others, which can occasionally lead to difficulties if they do not receive the recognition they desire.',
      },
      {
        label: 'Difficulty with Solitude',
        description:
          'Their preference for social interactions can make it challenging for them to handle solitude or engage in solitary tasks.',
      },
      {
        label: 'Overcommitment',
        description:
          'Their enthusiasm for new opportunities may lead them to overcommit to various projects or activities, potentially resulting in burnout or difficulty managing responsibilities.',
      },
    ],
    neuroticism: [
      {
        label: 'Setting Boundaries',
        description:
          'Their strong desire to be agreeable can lead to difficulty setting boundaries or asserting themselves, potentially resulting in being taken advantage of.',
      },
      {
        label: 'Conflict Avoidance',
        description:
          'They may avoid addressing conflicts directly, which can lead to unresolved issues or suppressed grievances affecting relationships.',
      },
      {
        label: 'Overemphasis on Harmony',
        description:
          'Their focus on maintaining harmony might cause them to overlook or ignore important issues that need to be addressed.',
      },
      {
        label: 'Easily Manipulated',
        description:
          'Their high level of trust and desire to please can make them susceptible to manipulation or exploitation by others.',
      },
      {
        label: 'Self-Sacrifice',
        description:
          'They may prioritize others’ needs over their own, leading to personal dissatisfaction or neglect of their own well-being and desires.',
      },
      {
        label: 'Lack of Assertiveness',
        description:
          'Their inclination to accommodate others can result in a lack of assertiveness, making it challenging for them to advocate for their own needs and preferences.',
      },
    ],
    openness: [
      {
        label: 'Overwhelmed by Complexity',
        description:
          'Their interest in new and complex ideas can sometimes lead to feeling overwhelmed or struggling with the practical application of these ideas.',
      },
      {
        label: 'Difficulty with Routine',
        description:
          'They may find routine tasks and repetitive activities boring or unfulfilling, which can affect their persistence in certain tasks.',
      },
      {
        label: 'Indecisiveness',
        description:
          'Their openness to multiple possibilities and perspectives can lead to difficulty in making firm decisions or committing to a single course of action.',
      },
      {
        label: 'Overthinking',
        description:
          'Their tendency to explore various ideas and possibilities can sometimes lead to overthinking or excessive analysis, complicating decision-making.',
      },
      {
        label: 'Impulsivity',
        description:
          'High openness can sometimes lead to impulsive behavior or quick shifts in interests, potentially resulting in inconsistent focus or follow-through.',
      },
      {
        label: 'Conflict with Conventional Views',
        description:
          'Their unconventional thinking may lead to disagreements or difficulties with more traditional or conservative viewpoints, potentially causing friction in certain social or professional contexts.',
      },
    ],
    compliance: [
      {
        label: 'Overly Perfectionistic',
        description:
          'Their high standards can lead to perfectionism, causing delays or dissatisfaction when results do not meet their exacting criteria.',
      },
      {
        label: 'Rigid',
        description:
          'They may become overly rigid or inflexible, struggling to adapt to unexpected changes or deviations from their plans.',
      },
      {
        label: 'Difficulty Delegating',
        description:
          'Their focus on precision and control can make it challenging for them to delegate tasks, leading to increased workload and stress.',
      },
      {
        label: 'Risk-Averse',
        description:
          'Their preference for structure and order might lead to a reluctance to take risks or explore unconventional approaches.',
      },
      {
        label: 'Overemphasis on Details',
        description:
          'They may become bogged down by minutiae, potentially losing sight of the bigger picture or overall goals.',
      },
      {
        label: 'Slow to Adapt',
        description:
          'Their preference for thoroughness and planning can result in slower responses to changing circumstances or new opportunities.',
      },
    ],
    dominance: [
      {
        label: 'Impulsiveness',
        description:
          'Their quick decision-making can sometimes lead to impulsive actions without fully considering all options or potential consequences.',
      },
      {
        label: 'Overbearing',
        description:
          'Their assertiveness and drive can sometimes come across as domineering or overly controlling, which may create friction with others.',
      },
      {
        label: 'Impatience',
        description:
          'They may become frustrated with slower processes or individuals who do not share their sense of urgency, potentially leading to conflicts.',
      },
      {
        label: 'Risk-Taking',
        description:
          'Their focus on achieving results can sometimes lead to taking excessive risks, which might result in avoidable setbacks.',
      },
      {
        label: 'Difficulty Delegating',
        description:
          'Their desire for control and high standards may make it challenging for them to delegate tasks effectively, leading to increased workload and stress.',
      },
      {
        label: 'Focus on Outcomes',
        description:
          'They might prioritize results over relationships or processes, potentially overlooking the importance of team dynamics and morale.',
      },
    ],
    influence: [
      {
        label: 'Overly Optimistic',
        description:
          'Their tendency to focus on the positive can sometimes lead to unrealistic expectations or underestimating potential challenges.',
      },
      {
        label: 'Impulsive',
        description:
          'They may act on their enthusiasm without fully considering the consequences, leading to hasty decisions.',
      },
      {
        label: 'Disorganized',
        description:
          'Their focus on interaction and ideas can sometimes result in neglecting details or failing to follow through on tasks, leading to disorganization.',
      },
      {
        label: 'Need for Approval',
        description:
          'They may seek excessive validation and approval from others, which can impact their decision-making and self-esteem if not met.',
      },
      {
        label: 'Difficulty with Criticism',
        description:
          'They may struggle with receiving criticism or handling negative feedback, which can affect their ability to improve or adapt.',
      },
      {
        label: 'Overemphasis on Social Interaction',
        description:
          'Their preference for social engagement might lead them to prioritize relationships over tasks, potentially affecting productivity or focus.',
      },
    ],
    stability: [
      {
        label: 'Resistance to Change',
        description:
          'Their preference for stability can make them resistant to change or reluctant to adopt new methods or ideas.',
      },
      {
        label: 'Avoidance of Conflict',
        description:
          'They may avoid addressing conflicts directly, which can lead to unresolved issues or tension within the team.',
      },
      {
        label: 'Overly Cautious',
        description:
          'Their careful and methodical approach can sometimes result in indecision or slow responses to new opportunities.',
      },
      {
        label: 'Difficulty with Assertiveness',
        description:
          "They may struggle to assert themselves or advocate for their own needs, often prioritizing others' needs over their own.",
      },
      {
        label: 'Reluctance to Take Risks',
        description:
          'Their desire for stability might lead to a reluctance to take risks or explore innovative solutions, potentially stifling creativity.',
      },
      {
        label: 'Overwhelmed by Stress',
        description:
          'While they are generally steady, high levels of stress or change can overwhelm them, leading to decreased performance or anxiety.',
      },
    ],
    ECBP: [
      {
        label: 'Overly Idealistic',
        description:
          'Their strong ideals can sometimes lead to disappointment when reality does not meet their expectations, causing frustration or disillusionment.',
      },
      {
        label: 'Sensitive to Criticism',
        description:
          'They may take criticism personally, which can impact their self-esteem and lead to emotional distress.',
      },
      {
        label: 'Struggle with Boundaries',
        description:
          'Their desire to help and support others can lead them to overextend themselves, sometimes at the expense of their own well-being.',
      },
      {
        label: 'Difficulty with Conflict',
        description:
          'They may find it challenging to handle conflict or disagreements, preferring to avoid confrontations rather than addressing issues directly.',
      },
      {
        label: 'Perfectionistic Tendencies',
        description:
          'Their high standards for themselves and others can sometimes lead to frustration and stress if things do not go as planned.',
      },
      {
        label: 'Tendency to Overcommit',
        description:
          'Their eagerness to help and involve themselves can lead them to take on too many responsibilities, risking burnout or inefficiency.',
      },
    ],
    ECBS: [
      {
        label: 'Easily Distracted',
        description:
          'Their wide range of interests can lead to difficulties with focus and follow-through, as they may jump from one project to another.',
      },
      {
        label: 'Overly Idealistic',
        description:
          'They can sometimes set unrealistic expectations or ideals, leading to disappointment when reality falls short',
      },
      {
        label: 'Sensitive to Criticism',
        description:
          'They may take criticism personally and struggle with negative feedback, affecting their self-esteem and motivation.',
      },
      {
        label: 'Struggle with Routine',
        description:
          'Their preference for spontaneity can make routine tasks and structured environments challenging for them.',
      },
      {
        label: 'Difficulty with Practical Matters',
        description:
          'They may neglect practical details or organizational aspects in favor of focusing on big-picture ideas.',
      },
      {
        label: 'Overcommitment',
        description:
          'Their eagerness to engage in new opportunities can lead them to overcommit, potentially causing stress and burnout.',
      },
    ],
    ECOP: [
      {
        label: 'Overly Critical',
        description:
          'Their high standards and focus on efficiency can make them overly critical of themselves and others, potentially leading to conflicts and tension.',
      },
      {
        label: 'Impatient',
        description:
          'They may become impatient with slower processes or those who do not meet their expectations, which can affect team dynamics and productivity.',
      },
      {
        label: 'Dominant',
        description:
          'Their strong leadership tendencies can come across as domineering or controlling, potentially alienating team members or colleagues.',
      },
      {
        label: 'Neglect of Emotional Needs',
        description:
          'They may prioritize tasks and goals over emotional considerations, which can lead to challenges in understanding and addressing the emotional needs of others',
      },
      {
        label: 'Resistance to Input',
        description:
          'Their confidence and decisiveness can sometimes lead to a reluctance to consider alternative viewpoints or feedback, potentially limiting collaboration.',
      },
      {
        label: 'Overemphasis on Goals',
        description:
          'Their intense focus on achieving goals may lead them to overlook immediate concerns or the practical aspects of day-to-day tasks.',
      },
    ],
    ECOS: [
      {
        label: 'Impatience with Details',
        description:
          'They may overlook details or become frustrated with tasks that require meticulous attention, preferring to focus on the big picture.',
      },
      {
        label: 'Difficulty with Routine',
        description:
          'Their dislike for routine can lead them to struggle with repetitive or mundane tasks, which they may find uninteresting or monotonous.',
      },
      {
        label: 'Overconfidence',
        description:
          'Their confidence in their ideas and abilities can sometimes come across as arrogance, which might alienate others or lead to conflicts.',
      },
      {
        label: 'Challenges with Follow-Through',
        description:
          'They may start multiple projects but struggle to complete them, as their interests and focus can shift quickly.',
      },
      {
        label: 'Impulsiveness',
        description:
          'Their tendency to act on impulse can sometimes lead to hasty decisions or actions without fully considering the consequences.',
      },
      {
        label: 'Difficulty with Structure',
        description:
          'They might struggle with adhering to established structures or systems, preferring more flexible and unstructured approaches.',
      },
    ],
    ERBP: [
      {
        label: 'Overly Sensitive',
        description:
          'Their strong desire to be appreciated and loved can make them overly sensitive to criticism and conflict, affecting their self-esteem and causing stress.',
      },
      {
        label: 'Difficulty with Change',
        description:
          'They may struggle with adapting to change, preferring stability and routine. Sudden changes can be challenging for them to handle.',
      },
      {
        label: 'People-Pleasing Tendencies',
        description:
          'Their eagerness to please others can sometimes lead them to neglect their own needs or agree to things they do not actually want to do.',
      },
      {
        label: 'Judgmental Attitudes',
        description:
          'They might be quick to judge others based on their own values and standards, which can lead to misunderstandings and conflicts.',
      },
      {
        label: 'Avoidance of Conflict',
        description:
          'Their preference for harmony can result in avoiding necessary confrontations, potentially allowing unresolved issues to persist.',
      },
      {
        label: 'Dependence on External Validation',
        description:
          'They may rely heavily on external validation and approval, which can make them vulnerable to the opinions of others and affect their decision-making.',
      },
    ],
    ERBS: [
      {
        label: 'Impulsiveness',
        description:
          'Their preference for immediate gratification and spontaneous decisions can lead to hasty actions without fully considering long-term consequences.',
      },
      {
        label: 'Difficulty with Routine',
        description:
          'They may struggle with repetitive tasks or structured routines, finding them monotonous and challenging to maintain over time.',
      },
      {
        label: 'Overly Focused on the Present',
        description:
          'Their strong focus on the present moment can sometimes result in neglecting future planning or long-term goals.',
      },
      {
        label: 'Sensitivity to Criticism',
        description:
          'They may take criticism personally and struggle to handle negative feedback, which can affect their confidence and morale.',
      },
      {
        label: 'Challenges with Follow-Through',
        description:
          'Their enthusiasm can sometimes wane, leading to difficulties in completing tasks or projects they start.',
      },
      {
        label: 'Avoidance of Conflict',
        description:
          'They often prefer to maintain harmony and avoid confrontations, which can lead to unresolved issues or difficulties in addressing problems directly.',
      },
    ],
    EROP: [
      {
        label: 'Inflexibility',
        description:
          'Their preference for order and routine can make them resistant to change. They may struggle to adapt to new ideas or methods.',
      },
      {
        label: 'Overbearing',
        description:
          'Their strong-willed and forceful nature can sometimes come across as overbearing or controlling, which may lead to conflicts with others.',
      },
      {
        label: 'Impatience',
        description:
          'They may become impatient with those who do not share their sense of urgency or efficiency, leading to frustration and tension in collaborative settings.',
      },
      {
        label: 'Difficulty with Emotions',
        description:
          'They may prioritize logic and efficiency over emotions, sometimes overlooking the emotional needs and perspectives of others.',
      },
      {
        label: 'Rigidity',
        description:
          'Their adherence to rules and standards can make them rigid, finding it challenging to accommodate alternative viewpoints or unconventional approaches.',
      },
      {
        label: 'Tendency to Micromanage',
        description:
          'Their desire for efficiency and control can lead to micromanagement, which may stifle creativity and autonomy in others.',
      },
    ],
    EROS: [
      {
        label: 'Impulsiveness',
        description:
          'Their preference for quick action can sometimes lead to hasty decisions without fully considering long-term consequences.',
      },
      {
        label: 'Disregard for Details',
        description:
          'Executor types may focus on the big picture while neglecting important details, potentially leading to oversight or incomplete solutions.',
      },
      {
        label: 'Difficulty with Routine',
        description:
          'They can become bored with repetitive tasks and may struggle with tasks that require sustained focus or long-term planning.',
      },
      {
        label: 'Conflict with Authority',
        description:
          'Their assertive and independent nature can sometimes lead to clashes with authority figures or established systems, especially if they perceive these as restrictive.',
      },
      {
        label: 'Challenges with Emotional Sensitivity',
        description:
          "They may prioritize logic and action over emotional considerations, which can lead to misunderstandings or insensitivity towards others' feelings.",
      },
      {
        label: 'Struggles with Long-Term Planning',
        description:
          'Their focus on immediate results can make long-term planning and strategic thinking less appealing or challenging for them.',
      },
    ],
    ICBP: [
      {
        label: 'Overly Idealistic',
        description:
          'Their high ideals can sometimes lead them to be disappointed with the realities of life, as they may set unrealistic expectations for themselves and others.',
      },
      {
        label: 'Sensitive to Criticism',
        description:
          'They can be particularly sensitive to criticism or conflict, which may affect their self-esteem and lead to stress or withdrawal.',
      },
      {
        label: 'Reserved and Private',
        description:
          'Their introspective nature can make them seem distant or reserved, making it challenging for others to understand or connect with them.',
      },
      {
        label: 'Perfectionistic Tendencies',
        description:
          'They may set very high standards for themselves and others, leading to frustration or dissatisfaction when things do not meet their expectations',
      },
      {
        label: 'Difficulty with Practicalities',
        description:
          'Their focus on abstract ideas and long-term goals can sometimes result in neglecting practical details or immediate needs.',
      },
      {
        label: 'Struggle with Boundaries',
        description:
          'Their deep empathy and commitment to others can lead them to overextend themselves, sometimes at the expense of their own well-being.',
      },
    ],
    ICBS: [
      {
        label: 'Overly Idealistic',
        description:
          'Their strong ideals can lead to disappointment when reality does not align with their expectations, causing frustration or disillusionment.',
      },
      {
        label: 'Sensitive to Criticism',
        description:
          'They may take criticism or conflict personally, which can affect their self-esteem and lead to emotional distress.',
      },
      {
        label: 'Difficulty with Practical Matters:',
        description:
          'Their focus on abstract ideas and ideals can sometimes result in neglecting practical or immediate concerns.',
      },
      {
        label: 'Reserved and Private',
        description:
          'They might be reserved about sharing their inner thoughts and feelings, making it challenging for others to connect with them on a deeper level.',
      },
      {
        label: 'Indecisive',
        description:
          'They may struggle with making decisions, particularly when they feel that any choice could conflict with their values or lead to potential negative outcomes.',
      },
      {
        label: 'Overwhelmed by Emotions',
        description:
          'Their strong emotional responses can sometimes be overwhelming, affecting their ability to manage stress or handle conflicts effectively.',
      },
    ],
    ICOP: [
      {
        label: 'Overly Critical',
        description:
          'Their high standards can lead to excessive criticism of themselves and others, which may create tension or conflict in relationships.',
      },
      {
        label: 'Difficulty with Emotional Expression',
        description:
          'They may struggle to express their emotions or understand the emotional needs of others, which can lead to misunderstandings and strained relationships.',
      },
      {
        label: 'Impatience with Incompetence',
        description:
          'They can become frustrated with inefficiency or incompetence, potentially leading to impatience or dismissiveness towards others who do not meet their standards.',
      },
      {
        label: 'Reluctance to Collaborate',
        description:
          'Their strong independence and self-reliance may make them reluctant to seek or value collaboration, preferring to work alone.',
      },
      {
        label: 'Overly Focused on Long-Term Goals',
        description:
          'Their focus on long-term objectives might cause them to neglect immediate concerns or the practicalities of day-to-day tasks.',
      },
      {
        label: 'Difficulty Adapting to Change',
        description:
          'They may struggle with adapting to sudden changes or disruptions to their plans, finding it challenging to adjust their strategies on the fly.',
      },
    ],
    ICOS: [
      {
        label: 'Difficulty with Practicalities',
        description:
          'They may struggle with practical or mundane tasks, finding them less engaging compared to their abstract and theoretical interests.',
      },
      {
        label: 'Social Interaction Challenges',
        description:
          'They might prefer solitude or intellectual pursuits over social interactions, which can sometimes lead to difficulties in building and maintaining relationships.',
      },
      {
        label: 'Overly Critical',
        description:
          'Their strong analytical nature can make them overly critical, both of themselves and others. This can create tension in interactions or collaboration.',
      },
      {
        label: 'Indecisiveness',
        description:
          'Their tendency to analyze every detail can lead to indecisiveness, making it challenging for them to make final decisions or commit to a course of action.',
      },
      {
        label: 'Emotional Distance',
        description:
          'They may struggle with understanding or expressing emotions, which can affect their personal relationships and their ability to connect with others on an emotional level.',
      },
      {
        label: 'Overlook Immediate Concerns',
        description:
          'Their focus on abstract ideas can sometimes lead them to overlook immediate, practical concerns or daily responsibilities.',
      },
    ],
    IRBP: [
      {
        label: 'Resistance to Change',
        description:
          'Their preference for stability and routine can make them resistant to change, causing difficulties in adapting to new situations or methods.',
      },
      {
        label: 'Overly Self-Critical',
        description:
          'They may set high standards for themselves and can be overly critical when they feel they have not met these standards, leading to unnecessary stress.',
      },
      {
        label: 'Difficulty Saying No',
        description:
          'Their desire to help and support others can make it hard for them to say no, potentially leading to overcommitment and burnout.',
      },
      {
        label: 'Avoidance of Conflict',
        description:
          'They often prefer to maintain harmony and avoid confrontations, which can lead to unresolved issues or difficulties in addressing problems directly.',
      },
      {
        label: 'Sensitivity to Criticism',
        description:
          'They may take criticism personally and struggle to handle negative feedback, which can affect their confidence and morale.',
      },
      {
        label: 'Inflexibility',
        description:
          'Their strong preference for order and routine can sometimes make them inflexible, finding it challenging to adapt to new ideas or ways of doing things.',
      },
    ],
    IRBS: [
      {
        label: 'Avoidance of Conflict',
        description:
          'Feeler types tend to avoid conflicts and disagreements, which can sometimes lead to unresolved issues and passive-aggressive behavior.',
      },
      {
        label: 'Difficulty with Long-Term Planning',
        description:
          'They may struggle with long-term planning and organization, often focusing more on the present moment rather than future goals.',
      },
      {
        label: 'Overly Sensitive',
        description:
          'Their heightened sensitivity can make them prone to taking criticism personally and experiencing emotional distress over minor issues.',
      },
      {
        label: 'Reluctance to Share Feelings',
        description:
          'Despite their sensitivity, they might find it challenging to express their emotions openly, leading to misunderstandings in relationships.',
      },
      {
        label: 'Struggles with Routine',
        description:
          'They may find routine tasks monotonous and prefer a more spontaneous and varied approach to their activities.',
      },
      {
        label: 'Indecisiveness',
        description:
          'They can be indecisive, particularly when faced with significant choices, due to their desire to maintain harmony and avoid conflict.',
      },
    ],
    IROP: [
      {
        label: 'Resistance to Change',
        description:
          'Their preference for stability and routine can make them resistant to change, causing difficulties in adapting to new situations or methods.',
      },
      {
        label: 'Inflexibility',
        description:
          'They may struggle with flexibility, preferring to stick to established procedures and plans even when adjustments are necessary.',
      },
      {
        label: 'Overly Critical',
        description:
          'Their high standards can sometimes lead them to be overly critical of themselves and others, focusing too much on flaws and shortcomings.',
      },
      {
        label: 'Difficulty Expressing Emotions',
        description:
          'They may find it challenging to express their emotions or understand the emotional needs of others, leading to potential misunderstandings in relationships.',
      },
      {
        label: 'Stubbornness',
        description:
          'Their strong-willed nature can sometimes make them stubborn, particularly when their principles or methods are questioned.',
      },
      {
        label: 'Workaholic Tendencies',
        description:
          'Their dedication to their responsibilities can lead to workaholic tendencies, sometimes at the expense of their personal well-being and relationships.',
      },
    ],
    IROS: [
      {
        label: 'Impulsiveness',
        description:
          'Their preference for action and spontaneity can sometimes lead to hasty decisions or a lack of thorough planning.',
      },
      {
        label: 'Difficulty with Emotions',
        description:
          'This type may struggle to express their emotions or understand the emotional needs of others, leading to potential misunderstandings in relationships.',
      },
      {
        label: 'Avoidance of Routine',
        description:
          'They can often dislike repetitive tasks and may struggle with tasks that require consistent attention to detail over long periods.',
      },
      {
        label: 'Resistance to Authority',
        description:
          'Their independent nature can sometimes make them resistant to following rules or authority figures, especially if they perceive these as restrictive.',
      },
      {
        label: 'Social Withdrawal',
        description:
          'They may prefer solitude or small groups over large social gatherings, which can sometimes lead to a perception of being distant or aloof.',
      },
      {
        label: 'Difficulty with Long-Term Planning',
        description:
          'Their focus on the present and immediate solutions can sometimes result in challenges with long-term goals and future planning.',
      },
    ],
  } as { [trait: string]: PersonalityStrengthWeakness[] };

  const getTitle = (personalityTrait: string): string =>
    traitAndTypeDescriptions?.[personalityTrait]?.title || '';
  const getDescription = (personalityTrait: string): string[] =>
    traitAndTypeDescriptions?.[personalityTrait]?.description || '';
  const getSummarizedDescription = (personalityTrait: string): string[] =>
    traitAndTypeDescriptions?.[personalityTrait]?.summarizedDescription || '';
  const getStrengths = (
    personalityTrait: string
  ): PersonalityStrengthWeakness[] =>
    traitAndTypeStrengths?.[personalityTrait] || [];
  const getWeaknesses = (
    personalityTrait: string
  ): PersonalityStrengthWeakness[] =>
    traitAndTypeWeaknesses?.[personalityTrait] || [];

  return {
    bigFiveTraits,
    discTraits,
    fourAspectsTraits,
    fourAspectsTypes,
    getFourAspectsType,
    getTitle,
    getDescription,
    getSummarizedDescription,
    getStrengths,
    getWeaknesses,
  };
};
