import { CookiesStorage } from '@ltvco/refresh-lib';
import {
  Button,
  Text,
  Grid,
  Link,
  styled,
  CustomIcons,
} from '@ltvco/refresh-lib/theme';
import {
  DateUtil,
  FirstRecordSearch,
  getFirstRecordSearch,
  useScreenSize,
} from '@ltvco/refresh-lib/utils';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { constants } from 'appConstants';
import { SidenavContainer } from '../components/sidenav/SidenavContainer';
import { useBumpeeChat } from '../components/Language/hooks/useBumpeeChat';
import { useBumpeeUsers } from '../components/Language/hooks/useBumpeeUsers';
import { languageID, topicID } from '../components/Language/constants';
import { TransitionChatModal } from 'components/Language/ChatContainer/TransitionChatModal/TransitionChatModal';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import {
  closeSnackbar,
  MobileAppsCtaCard,
  NeedAssistanceSidebar,
  PremiumDataModal,
  RightRailCard,
  useAccount,
  usePremiumDataModal,
  useServiceToken,
  useSnackbar,
} from '@ltvco/refresh-lib/v1';
import {
  BackdropLoading,
  EmailSettingsCard,
  MonitoredReportsModal,
  SideBarMigrationCta,
} from '@ltvco/refresh-lib/v2';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { ReactivatePausedAccountCta } from '@ltvco/refresh-lib/payments';

const CreditCardIcon = styled(CustomIcons.CreditCardIcon)(({ theme }) => ({
  fontSize: '3.75rem',
  padding: theme.spacing(0.9375),
}));

const DollarsAndCoinsIcon = styled(CustomIcons.DollarsAndCoinsIcon)(
  ({ theme }) => ({
    fontSize: '3.75rem',
    padding: theme.spacing(0.9375),
  })
);

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  width: '100%',
  '&&': {
    '& > *': {
      width: '100%',
      maxWidth: '100%',
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: 'none',
      '& > button': {
        width: '95%',
      },
      '& > div button': {
        paddingRight: 0,
      },
    },
  },
}));

export function DashboardParent({
  contactUsHandler,
  handleLogout,
  openConfirmCancelModal,
}: {
  contactUsHandler: () => void;
  handleLogout: () => void;
  openConfirmCancelModal: (downsellTest?: boolean) => void;
}) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { routingUtils } = useContext(AppConfig);
  const cookieStorage = new CookiesStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: account } = useAccount(true);
  const { data: bumpeeTokenData } = useServiceToken('bumpee');
  const { createChatMutation } = useBumpeeChat(0, true, true);
  const { createUserMutation } = useBumpeeUsers();
  const [isTransitionChatModalOpen, setIsTransitionChatModalOpen] =
    useState(false);
  const [firstRecordSearchData, setFirstRecordSearchData] = useState(
    getFirstRecordSearch()
  );
  const { planName } = useLimitedPlanInfo();
  const { isMobile } = useScreenSize();

  const {
    isInSingleReportVariation,
    isInternationalVinTest,
    isInInternationalLanguageTest,
  } = checkPlanVariations(planName);

  const dateUtil = new DateUtil();
  const currentDate = new Date();

  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const [searchType, setSearchType] = useState('');

  const featureAction = (str: string) => {
    setSearchType(str);
    triggerModal(true, isInSingleReportVariation);
  };

  // State
  const [monitoredReportsOpen, setMonitoredReportsOpen] = useState(false);

  if (
    account?.account?.user_settings?.show_info_collection_screen &&
    !localStorage.getItem('recordSearch')
  ) {
    navigate('/self-searching');
  }

  const [showFirstReportLoading, setShowFirstReportLoading] = useState(
    !!firstRecordSearchData
  );

  useEffect(() => {
    if (!account) return;
    if (firstRecordSearchData?.searchType === 'language_chat') {
      if (!bumpeeTokenData?.token) return;
      cookieStorage.removeValue('first_record_search');
      if (
        Boolean(firstRecordSearchData?.native_language) &&
        Boolean(firstRecordSearchData?.learning_language) &&
        Boolean(firstRecordSearchData?.learning_language_level) &&
        Boolean(firstRecordSearchData?.learning_language_topic)
      ) {
        handleOpenTransitionChatModal(firstRecordSearchData);
      }
      if (Boolean(firstRecordSearchData?.native_language)) {
        createUserMutation.mutateAsync({
          native_language_id: languageID(
            firstRecordSearchData.native_language.toLocaleLowerCase()
          ).toString(),
        });
      }
      navigate('/dashboard/language');
      return;
    }

    if (firstRecordSearchData?.searchType === 'personality_test') {
      cookieStorage.removeValue('first_record_search');

      const localStorageData = localStorage.getItem('recordSearch') || '';
      let testAnswers;
      try {
        testAnswers =
          JSON.parse(String(localStorageData))?.personalityTestResults || {};
      } catch (error) {
        testAnswers = {};
      }
      const responseMap = {
        '0': 'Strongly Disagree',
        '1': 'Disagree',
        '2': 'Neutral',
        '3': 'Agree',
        '4': 'Strongly Agree',
      } as { [response_value: string]: string };

      const answersToStore = Object.keys(testAnswers).map(
        (custom_attribute, index) => ({
          custom_attribute,
          response: responseMap[testAnswers[custom_attribute]],
          screen_id: `TRAIT-BV-01-${index + 1}`,
        })
      );

      localStorage.setItem('answersStorage', JSON.stringify(answersToStore));
      localStorage.setItem(
        'activeQuestionIndex',
        String(answersToStore.length)
      );
      navigate('/dashboard/personality-traits');
      return;
    }

    cookieStorage.removeValue('first_record_search');

    const obitPlans = [
      '7_day_trial_1_00_up_front_26_89_recurring_monthly_freshness_limit_100_no_pdf_obits_test_scale_14',
      '7_day_trial_5_00_up_front_26_89_recurring_monthly_freshness_limit_100_free_pdf_obits_test_scale_14',
    ];
    const planName =
      account.account.subscription_info.subscription_plan_unique_name;

    const isInObitSearchForFirstSearchVariation = obitPlans.includes(planName);

    if (
      firstRecordSearchData?.searchType === 'obituary' &&
      !isInObitSearchForFirstSearchVariation
    ) {
      firstRecordSearchData.searchType = 'person';
    }

    let navigateTo;

    const reportLimit =
      account?.account?.subscription_info?.monthly_report_limit;
    const oneReportLimit = reportLimit && reportLimit === 1;
    if (firstRecordSearchData && !oneReportLimit) {
      const typesForReportRoute = [
        'phone',
        'email',
        'username',
        'property',
        'vehicle',
        'phone-fraud',
        'email-fraud',
        'ip-fraud',
        'url-fraud',
      ];
      if (typesForReportRoute.includes(firstRecordSearchData.searchType)) {
        navigateTo = handleRedirectToReportRoute(firstRecordSearchData);
      }

      const typesForSearchRoute = [
        'person',
        'for_sale',
        'pre_foreclosures',
        'obituary',
      ];

      if (typesForSearchRoute.includes(firstRecordSearchData.searchType)) {
        navigateTo = handleRedirectToSearchRoute(firstRecordSearchData);
      }

      if (firstRecordSearchData.searchType === 'unclaimed_money') {
        const hasAllRequiredData =
          firstRecordSearchData?.first_name &&
          firstRecordSearchData?.last_name &&
          firstRecordSearchData?.state;
        setShowFirstReportLoading(false);
        if (hasAllRequiredData) {
          navigateTo = handleRedirectToReportRoute(firstRecordSearchData);
        } else {
          navigateTo = handleRedirectToSearchRoute(firstRecordSearchData);
        }
      }

      if (navigateTo) {
        navigate(navigateTo);
        // Setting this cookie for redirecting properly on the Onboarding Questions
        cookieStorage.setValue('first_report_navigate', navigateTo);
      }
    }
  }, [account, bumpeeTokenData]);

  useEffect(() => {
    if (localStorage.getItem('pausedAccountSnackbarId')) {
      try {
        closeSnackbar(
          JSON.parse(localStorage.getItem('pausedAccountSnackbarId') || '')
        );
      } catch (e) {
        console.log('error', e);
      }
    }
    handleSiteNotifications();
    handleReactivationForPausedAccountsBanner();
  }, [account]);

  // Functions
  function closeMonitoredReportsModal() {
    setMonitoredReportsOpen(false);
  }

  function handleRedirectToSearchRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let navigateRoute;
    if (
      firstRecordSearchData.last_name &&
      firstRecordSearchData.searchType === 'person'
    ) {
      navigateRoute = routingUtils.searchPersonUrl({
        fName: firstRecordSearchData.first_name,
        mName: firstRecordSearchData.middle_name,
        lName: firstRecordSearchData.last_name,
        state: firstRecordSearchData.state,
        city: firstRecordSearchData.city,
        age: firstRecordSearchData.age,
      });
    } else if (
      firstRecordSearchData.searchType === 'for_sale' ||
      firstRecordSearchData.searchType === 'pre_foreclosures'
    ) {
      navigateRoute = routingUtils.searchPropertyListingsUrl({
        city: firstRecordSearchData.city,
        state: firstRecordSearchData.state,
        zip5: firstRecordSearchData.zip_code,
        search_type:
          firstRecordSearchData?.searchType === 'pre_foreclosures'
            ? 'pre_foreclosures'
            : undefined,
      });
    } else if (firstRecordSearchData.searchType === 'obituary') {
      navigateRoute = `/search/obituary?name=${
        firstRecordSearchData.first_name
      } ${firstRecordSearchData.last_name}&dob=${
        firstRecordSearchData.dob ?? ''
      }&dod=${firstRecordSearchData.dod ?? ''}`;
    } else if (firstRecordSearchData.searchType === 'unclaimed_money') {
      navigateRoute = `/dashboard/unclaimed-money?fname=${firstRecordSearchData?.first_name}&lname=${firstRecordSearchData?.last_name}`;
    }

    return navigateRoute;
  }

  function handleRedirectToReportRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let queryParams = '';

    if (firstRecordSearchData.searchType === 'phone') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'username') {
      queryParams = `username=${firstRecordSearchData.username}`;
    }
    if (firstRecordSearchData.searchType === 'property') {
      queryParams = `address=${firstRecordSearchData.address}&city=${firstRecordSearchData.city}&state=${firstRecordSearchData.state}&zipcode=${firstRecordSearchData.zip_code}`;
    }
    if (firstRecordSearchData.searchType === 'vehicle') {
      if (stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.vin)) {
        queryParams = `vin=${firstRecordSearchData.vin}`;
      } else if (
        stringExistsAndItsValueIsNotUndefined(
          firstRecordSearchData.license_plate
        ) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.state)
      ) {
        queryParams = `license_plate=${firstRecordSearchData.license_plate}&state=${firstRecordSearchData.state}`;
      } else if (
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.make) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.model) &&
        stringExistsAndItsValueIsNotUndefined(firstRecordSearchData.year)
      ) {
        queryParams = `make=${firstRecordSearchData.make}&model=${firstRecordSearchData.model}&year=${firstRecordSearchData.year}`;
      }
    }
    if (firstRecordSearchData.searchType === 'phone-fraud') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email-fraud') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'ip-fraud') {
      queryParams = `ip_address=${firstRecordSearchData.ip_address}`;
    }
    if (firstRecordSearchData.searchType === 'url-fraud') {
      queryParams = `url=${firstRecordSearchData.url}`;
    }
    if (firstRecordSearchData.searchType === 'unclaimed_money') {
      queryParams = `first_name=${firstRecordSearchData.first_name}&last_name=${firstRecordSearchData.last_name}&state=${firstRecordSearchData.state}`;
      return `/report/unclaimed-money?${queryParams}`;
    }
    return `/report/${firstRecordSearchData.searchType}?${queryParams}`;
  }

  const handleOpenTransitionChatModal = (
    firstRecordSearchData: FirstRecordSearch
  ) => {
    setFirstRecordSearchData(firstRecordSearchData);
    setIsTransitionChatModalOpen(true);
  };

  function handleRedirectToLanguageChat() {
    const options = {
      native_language_id: languageID(
        firstRecordSearchData?.native_language?.toLowerCase() ?? ''
      ),
      learning_language_id: languageID(
        firstRecordSearchData?.learning_language?.toLowerCase() ?? ''
      ),
      level: firstRecordSearchData?.learning_language_level ?? '',
      topic_id: topicID(
        firstRecordSearchData?.learning_language_topic?.toLowerCase() ?? ''
      ),
    };
    setIsTransitionChatModalOpen(false);
    createChatMutation.mutateAsync(options);
    setShowFirstReportLoading(false);
    return;
  }

  function handleReactivationForPausedAccountsBanner() {
    if (!account) return;

    const isInPausedStatus = account?.meta?.subscription_state === 'paused';
    if (!isInPausedStatus) return;

    const today = currentDate.toISOString();
    const expirationDate = account?.account.subscription_info?.normalize_date;

    if (!expirationDate) return;

    const isAfterCurrentDate = dateUtil.isAfter(today, expirationDate);

    function handleReactivationClick() {
      closeSnackbar(pausedAccountSnackbarId);
      navigate('/reactivation');
    }

    const expiredMessage = (
      <Text>
        <b>
          Your account expired on{' '}
          {dateUtil.parseDateFromString(
            account?.account.subscription_info?.normalize_date,
            'M/d/y'
          )}{' '}
          . Reactivate to regain access.
        </b>
      </Text>
    );

    const cancelledMessage = (
      <Text>
        <b>
          Your account with member ID {account?.account?.user_info?.user_code}{' '}
          has been cancelled.
        </b>{' '}
        <br /> You have until{' '}
        {dateUtil.parseDateFromString(
          account?.account.subscription_info?.normalize_date,
          'M/d/y'
        )}{' '}
        to continue running reports. Please reactivate now to maintain access.
        Visit{' '}
        <Link onClick={handleReactivationClick} style={{ cursor: 'pointer' }}>
          here
        </Link>{' '}
        to reactivate.
      </Text>
    );

    const message = isAfterCurrentDate ? expiredMessage : cancelledMessage;

    const pausedAccountSnackbarId = enqueueSnackbar(message, {
      variant: 'info',
      persist: true,
      action: getCloseButton(() => {
        closeSnackbar(pausedAccountSnackbarId);
      }),
    });

    localStorage.setItem(
      'pausedAccountSnackbarId',
      JSON.stringify(pausedAccountSnackbarId)
    );
  }

  function getCloseButton(onClick: () => void) {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        sx={(theme) => ({
          fontsize: theme.spacing(0.75),
          maxHeight: '40px',
          width: '125px',
          color: `${theme.palette.success.contrastText} !important`,
          backgroundColor: `${theme.palette.success.main} !important`,
        })}
      >
        Close
      </Button>
    );
  }
  function stringExistsAndItsValueIsNotUndefined(input: string) {
    return Boolean(input) && input !== 'undefined';
  }

  function handleSiteNotifications() {
    if (!account) return;
    const siteNotification = account?.meta?.notification;
    if (!siteNotification) return;
    const siteNotificationSnackbarId = enqueueSnackbar(
      <Text>
        <b>{siteNotification.title}</b> <br /> {siteNotification.message}
      </Text>,
      {
        variant: 'info',
        persist: true,
        action: (
          <Button
            onClick={() => {
              closeSnackbar(siteNotificationSnackbarId);
            }}
            variant="contained"
            sx={(theme) => ({
              fontsize: theme.spacing(0.75),
              maxHeight: '40px',
              width: '125px',
              color: `${theme.palette.success.contrastText} !important`,
              backgroundColor: `${theme.palette.success.main} !important`,
            })}
          >
            Close
          </Button>
        ),
      }
    );
  }

  const isIdMonitorIndex = location.pathname === '/dashboard/id-monitor';
  const isMonitoringPage = location.pathname.includes('/dashboard/monitoring');
  const isBillingPage = location.pathname.includes('/dashboard/billing');
  const isReportsPage = location.pathname.includes('/dashboard/reports');

  const showRightRail = isMonitoringPage || isBillingPage || isReportsPage;

  const appsFlyerLink = 'https://beenverified.onelink.me/5mdJ/j0m5b8cj';

  const RightRailComponent = (
    <Grid
      item
      container
      xs={12}
      md={3}
      lg={3}
      spacing={1}
      direction="column"
      marginTop={2}
      maxWidth={{ xs: '100%', md: '275px' }}
    >
      {isBillingPage && (
        <Grid
          item
          width={'100%'}
          order={{ xs: 6, md: 1 }}
          className="needs-assistance"
        >
          <NeedAssistanceSidebar
            logout={handleLogout}
            supportImgUrl={constants.images.img24hr}
            contactUsHandler={contactUsHandler}
            openConfirmCancelModal={openConfirmCancelModal}
          />
        </Grid>
      )}
      {isMonitoringPage && (
        <Grid item width={'100%'} order={2}>
          <EmailSettingsCard
            currentInstantAlertsSetting={
              account?.account?.alert_me.settings.instant_enabled || false
            }
            currentDigestAlertsSetting={
              account?.account?.alert_me.settings.digest_enabled || false
            }
            handleMonitoredReports={() => setMonitoredReportsOpen(true)}
          />
          <MonitoredReportsModal
            isOpen={monitoredReportsOpen}
            onClose={() => closeMonitoredReportsModal()}
          />
        </Grid>
      )}
      {!isMonitoringPage &&
        !isBillingPage &&
        !isInternationalVinTest &&
        !isInInternationalLanguageTest && (
          <>
            {isMobile && (
              <Grid item width={'100%'} order={2} key="mobile-migration-card">
                <StyledContainer>
                  <SideBarMigrationCta />
                </StyledContainer>
              </Grid>
            )}
            <Grid item width={'100%'} order={3}>
              <RightRailCard
                icon={<DollarsAndCoinsIcon />}
                title="Check for Unclaimed Money"
                subtitle="Included with your membership is a state government search of billions in unclaimed money"
                body="Find out if unclaimed money is owed to you or anyone you know!"
                buttonText="Unclaimed Money Search"
                tagText="Bonus Feature"
                showTag={true}
                tagOnLeft={false}
                onButtonClick={() =>
                  isInSingleReportVariation
                    ? featureAction('unclaimed money')
                    : navigate('unclaimed-money')
                }
              />
            </Grid>
            <Grid item width={'100%'} order={4}>
              <RightRailCard
                icon={<CreditCardIcon />}
                title="Dark Web Scan"
                subtitle="Millions of online accounts are compromised each year"
                body="Search using your email address to see if you may have been compromised on the dark web"
                buttonText="Scan Now"
                tagText="New"
                showTag={true}
                tagOnLeft={false}
                onButtonClick={() =>
                  isInSingleReportVariation
                    ? featureAction('dark web')
                    : navigate('dark-web')
                }
              />
            </Grid>
          </>
        )}
      {isReportsPage &&
        !isInternationalVinTest &&
        !isInInternationalLanguageTest && (
          <Grid item width={'100%'} order={5}>
            <MobileAppsCtaCard appsFlyerLink={appsFlyerLink} />
          </Grid>
        )}
      {account?.meta?.subscription_state === 'paused' && (
        <Grid item order={5}>
          <ReactivatePausedAccountCta />
        </Grid>
      )}
      <PremiumDataModal
        isOpen={isModalOpen}
        onClose={closeModal}
        searchType={searchType}
      />
    </Grid>
  );

  const RightRail = showRightRail ? RightRailComponent : <></>;

  return (
    <SidenavContainer defaultBackground={isIdMonitorIndex}>
      <Grid container justifyContent={'space-between'} height="100%">
        <Grid
          item
          xs={12}
          md={showRightRail ? 8 : 12}
          lg={showRightRail ? 8 : 12}
          maxWidth={{
            xs: '100%',
            md: showRightRail ? '911px !important' : '100%',
          }}
        >
          {isTransitionChatModalOpen && (
            <TransitionChatModal
              learningLanguage={languageID(
                firstRecordSearchData?.learning_language?.toLowerCase() || ''
              )}
              nativeLanguage={
                firstRecordSearchData?.native_language || 'english'
              }
              setNewTransitionModalOpen={handleRedirectToLanguageChat}
            />
          )}
          {showFirstReportLoading ? (
            <BackdropLoading
              message={<Text variant="h5">Generating first report</Text>}
              sx={{ backgroundColor: 'white' }}
            />
          ) : (
            <Outlet />
          )}
        </Grid>
        {RightRail}
      </Grid>
    </SidenavContainer>
  );
}
