export const traitsTheme = {
  traitsColors: {
    analyst: {
      primary: '#f0faf0',
      secondary: '#05A52B',
    },
    diplomats: {
      primary: '#ffeef1',
      secondary: '#FF113F',
    },
    explorers: {
      primary: '#F4F0E6',
      secondary: '#825E01',
    },
    sentinels: {
      primary: '#eff6fb',
      secondary: '#0085C6',
    },
  },
  mainBackground: '#fff',
  categoryBoxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.12)',
  resultContainer: '#ccf1d6',
  modalContainerBackground: '#4a3b8f',
  firstStepBackground: '#F2F0FF',
};
