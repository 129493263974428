import React from 'react';
import {
  SerializedObituaryDetails,
  useSearchObituary,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getObituarySearchParams,
} from '@ltvco/refresh-lib/v2';

interface ObituaryFetcherProps {
  searchData: AdvancedSearchURLParams;
  setObituaryResults: (results: SerializedObituaryDetails | undefined) => void;
}

export function ObituaryFetcher({
  searchData,
  setObituaryResults,
}: ObituaryFetcherProps) {
  const { data, isError, isFetched } = useSearchObituary({
    obituarySearchParams: getObituarySearchParams(searchData),
  });

  const result = data as SerializedObituaryDetails | undefined;

  if (isFetched && !isError) {
    setObituaryResults(result);
  } else {
    setObituaryResults(undefined);
  }
  return <React.Fragment />;
}
