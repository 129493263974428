import {
  AccountInformation,
  GenericCtaCard,
  LimitedPlanProgress,
  LimitedPlanProgressVIN,
  MyAccountHeader,
  PremiumDataModal,
  usePremiumDataModal,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { Grid, Box } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import { useEffect, useState } from 'react';
import {
  AccountInformationOptions,
  GenericCtaCardProps,
  editOptionClicked,
} from './BillingConstants';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { DateUtil } from '@ltvco/refresh-lib/utils';
import { MyAccountTabs, AccountEditModals } from '@ltvco/refresh-lib/v2';
import { useFeatureIsOn, useFeatureValue } from '@ltvco/refresh-lib/vendors';
import {
  PaymentsSelectionModal,
  PayPalRedirectionModal,
  RadioPaymentsModal,
  ViewOrderHistory,
} from '@ltvco/refresh-lib/payments';

export function Billing({
  onCancelConfirmCancelModal,
}: {
  onCancelConfirmCancelModal?: () => void;
}) {
  const navigate = useNavigate();
  const dateUtil = new DateUtil();
  const {
    session: { account },
  } = useSession();

  useEffect(() => {
    document.title = 'My Account - BeenVerified';
  }, []);

  const {
    isLimitedUser,
    isUnlimitedUser,
    reportLimit,
    reportsRun,
    reportsRunPercentage,
    isLoading,
    planName,
    nextRenewalDate,
  } = useLimitedPlanInfo();

  const isInTabsVariation = useFeatureIsOn('OAR-1287');

  const [openPaymentsSelectionModal, setOpenPaymentsSelectionModal] =
    useState<boolean>(false);

  const [openPaymentsUpdateModal, setOpenPaymentsUpdateModal] =
    useState<boolean>(false);

  //Paypal Redirection modal
  const [openPaypalRedirection, setOpenPaypalRedirection] =
    useState<boolean>(false);
  const [paypalState, setPaypalState] = useState<'success' | 'canceled'>(
    'success'
  );
  const togglePaypalRedirectionModal = () =>
    setOpenPaypalRedirection(!openPaypalRedirection);

  const [editing, setEditing] = useState<string | null>('');

  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const [searchType, setSearchType] = useState('');

  const {
    isInSingleReportVariation,
    isInInternationalLanguageTest,
    isInternationalVinTest,
  } = checkPlanVariations(planName);

  const onVariationCtaClick = (text?: string) => {
    setSearchType(text || '');
    triggerModal(true, isInSingleReportVariation);
  };

  const { ctaCardProps, length } = GenericCtaCardProps(
    navigate,
    isInSingleReportVariation,
    onVariationCtaClick
  );

  const togglePaymentsSelectionModal = () =>
    setOpenPaymentsSelectionModal(!openPaymentsSelectionModal);

  const togglePaymentsUpdateModal = () => {
    setOpenPaymentsUpdateModal(!openPaymentsUpdateModal);
    if (!openPaymentsSelectionModal) {
      togglePaymentsSelectionModal();
    }
  };
  const CancelMyAccount = {
    name: 'Cancel My Account',
    display: 'Cancel My Account',
    value: 'Cancel My Account',
    constantValue: 'CANCEL_MY_ACCOUNT',
  };
  const IsinCancelVariation = useFeatureValue('OAR-1326', '');
  const isInCancelMenuVariation_3 =
    IsinCancelVariation === 'OAR-1326_variation_3';
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('bvppcanc')) {
      setPaypalState('canceled');
      setOpenPaypalRedirection(true);
      return;
    }
    if (urlParams.get('bvpp') && urlParams.get('token')) {
      setPaypalState('success');
      setOpenPaypalRedirection(true);
    }
    if (isInCancelMenuVariation_3) {
      const exist = AccountInformationOptions.find(
        (option) => option.value === 'Cancel My Account'
      );
      if (!exist) AccountInformationOptions.push(CancelMyAccount);
    }
  }, []);

  const finishEditing = () => {
    setEditing('');
  };

  function handleAddPaymentMethod() {
    setOpenPaymentsSelectionModal(false);
    setOpenPaymentsUpdateModal(true);
  }

  const has_sso_connections = account?.account?.user_info?.has_sso_connections;

  let updatedItems = has_sso_connections
    ? AccountInformationOptions.filter(
        (item) => item.constantValue !== 'CHANGE_PASSWORD'
      )
    : AccountInformationOptions;

  if (has_sso_connections) {
    updatedItems.push({
      name: 'Send Password Reset Email',
      display: 'Send Password Reset Email',
      value: 'Send Password Reset Email',
      constantValue: 'PASSWORD_RESET_EMAIL',
    });
  }

  return (
    <>
      <MyAccountHeader isUnlimited={isUnlimitedUser} />
      {isInTabsVariation ? (
        <MyAccountTabs
          accountInformationOptions={AccountInformationOptions}
          togglePaymentsSelectionModal={togglePaymentsSelectionModal}
          editOptionClicked={editOptionClicked}
          reportLimit={reportLimit}
          reportsRun={reportsRun}
          reportsRunPercentage={reportsRunPercentage}
          length={length}
          ctaCardProps={ctaCardProps}
        />
      ) : (
        <>
          {isLimitedUser &&
            !isLoading &&
            !isInInternationalLanguageTest &&
            (isInternationalVinTest ? (
              <LimitedPlanProgressVIN
                progressValue={reportsRunPercentage}
                reportsLimit={reportLimit}
                reportsRun={reportsRun}
                nextRenewalDate={
                  dateUtil?.parseDateFromString(
                    nextRenewalDate,
                    'MMMM dd, yyyy'
                  ) || ''
                }
              />
            ) : (
              <LimitedPlanProgress
                plansWithout5ReportBlocker={
                  constants?.data?.plansWithout5ReportBlocker
                }
                validReportCountThreshold={
                  constants?.data?.validReportCountThreshold
                }
                progressValue={reportsRunPercentage}
                reportsLimit={reportLimit}
                reportsRun={reportsRun}
                link="/upgrade/plan"
                blockUpgradeWindowThreshold={
                  constants?.data?.blockUpgradeWindowThreshold
                }
              />
            ))}
          {isInInternationalLanguageTest || isInternationalVinTest ? null : (
            <Grid container spacing={2} justifyContent="center">
              {[...Array(length)].map((_x, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <GenericCtaCard {...ctaCardProps[index]}></GenericCtaCard>
                </Grid>
              ))}
            </Grid>
          )}

          <PremiumDataModal
            isOpen={isModalOpen}
            onClose={closeModal}
            searchType={searchType}
          />

          <AccountEditModals
            editing={editing}
            finishEditing={finishEditing}
            onCancelConfirmCancelModal={onCancelConfirmCancelModal}
          />
          <Box
            mt={2.5}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <AccountInformation
              editOptions={updatedItems}
              editOptionClick={(value: string | null) => {
                editOptionClicked(
                  value,
                  setOpenPaymentsSelectionModal,
                  setEditing,
                  updatedItems
                );
              }}
            />
          </Box>
          <ViewOrderHistory />
        </>
      )}

      <PaymentsSelectionModal
        open={openPaymentsSelectionModal}
        onClose={togglePaymentsSelectionModal}
        onAddPaymentMethod={handleAddPaymentMethod}
      />
      <RadioPaymentsModal
        isOpen={openPaymentsUpdateModal}
        onCloseHandle={togglePaymentsUpdateModal}
      />

      <PayPalRedirectionModal
        paypalState={paypalState}
        isOpen={openPaypalRedirection}
        onCloseHandle={togglePaypalRedirectionModal}
      />
    </>
  );
}
