import { Box, LoadingReportCard } from '@ltvco/refresh-lib/theme';

export function LoadingReports() {
  return (
    <Box mt={2.5}>
      <LoadingReportCard />
      <LoadingReportCard />
      <LoadingReportCard />
    </Box>
  );
}
