import { StyledButton, Container } from './constants';
import { SimpleQuestionSurveyProps } from '../QuestionSurveyService/constants';
import React from 'react';

export const PersonalityTraitsButton = ({
  answerSet,
  screenId,
  customAttribute,
  storeAnswer,
}: SimpleQuestionSurveyProps) => {
  return (
    <Container>
      {answerSet.map((answer: string) => (
        <StyledButton
          variant="contained"
          onClick={() =>
            storeAnswer({
              screen_id: screenId,
              custom_attribute: customAttribute,
              response: answer,
            })
          }
          sx={{ backgroundColor: '#000' }}
        >
          {answer}
        </StyledButton>
      ))}
    </Container>
  );
};
