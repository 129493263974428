import {
  Button,
  Divider,
  Stack,
  Text,
  Tooltip,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  personalityTestTraitsHashMap,
  personalityTraitsHashMap,
  personalityTraitsTooltipsCopyHashMap,
} from '../utils';
import { PersonalityTestScore } from '../../usePersoniltyScore/usePersonailyScore';
import { useState } from 'react';
import { usePersonalityDescriptions } from '../../usePersonalityDescriptions/usePersonalityDescriptions';
import { renderImageBasedOnTrait } from './utils';
import { PersonalityTraitResult } from './PersonalityTraitResult/PersonalityTraitResult';
import {
  PersonalityTraitsTestsResultsHeader,
  PersonalityTraitsTestsResultsHeaderBody,
  PersonalityTraitsTestsResultsMobileHeader,
  PersonalityTraitsTestsResultsWrapper,
} from './components';
import { StrengthsWeaknesses } from './PersonalityTraitResult/StrengthsWeaknesses/StrengthsWeaknesses';
import { ExpandLess, ExpandMore, Info } from '@mui/icons-material';

type PersonalityTraitsTestResults = {
  personalityTraitsResultKey: string;
  personalityTraitsResults: PersonalityTestScore;
  fourAspectsAnalysis?: {
    extraversion2: string;
    realism: string;
    objectivity: string;
    planning: string;
  };
};

export const PersonalityTraitsTestResults = ({
  personalityTraitsResults,
  personalityTraitsResultKey,
  fourAspectsAnalysis,
}: PersonalityTraitsTestResults) => {
  const [expandedState, setExpandedState] = useState(false);
  const theme = useTheme();
  const { isDesktop } = useScreenSize();
  const {
    getFourAspectsType,
    getTitle,
    getDescription,
    getSummarizedDescription,
    getStrengths,
    getWeaknesses,
  } = usePersonalityDescriptions();

  const fourAspectsType = fourAspectsAnalysis
    ? getFourAspectsType(fourAspectsAnalysis)
    : '';

  const calculateHighestPersonalityTraitScore = () => {
    let highestScore = 0;
    let highestScoreKey = '';
    Object.keys(personalityTraitsHashMap[personalityTraitsResultKey]).forEach(
      (key) => {
        if (personalityTraitsResults[key] > highestScore) {
          highestScore = personalityTraitsResults[key];
          highestScoreKey = key;
        }
      }
    );
    if (!highestScoreKey) {
      highestScoreKey = Object.keys(
        personalityTraitsHashMap[personalityTraitsResultKey]
      )[0];
    }
    return highestScoreKey;
  };

  const highestScoreTrait = calculateHighestPersonalityTraitScore();

  const handleExpand = () => {
    setExpandedState(!expandedState);
  };

  const renderTestResultsSummary = () => {
    let summaryParagraph = '';

    if (personalityTraitsResultKey === 'fourAspects') {
      getDescription(fourAspectsType).map(
        (description) => (summaryParagraph += ` ${description}`)
      );
      return <Text marginBottom="8px">{summaryParagraph}</Text>;
    } else {
      getSummarizedDescription(highestScoreTrait).map(
        (description) => (summaryParagraph += ` ${description}`)
      );
      return <Text marginBottom="8px">{summaryParagraph}</Text>;
    }
  };

  const renderPersonalityTraits = () => {
    return Object.keys(
      personalityTraitsHashMap[personalityTraitsResultKey]
    ).map((personalityTraitTestName) => {
      const percentage = Math.round(
        personalityTraitsResults[personalityTraitTestName] * 100
      );
      if (personalityTraitsResultKey === 'fourAspects') {
        return (
          <PersonalityTraitResult
            key={personalityTraitTestName}
            personalityTraitResultType={getTitle(personalityTraitTestName)}
            personalityTraitResultTypeLabel={
              getSummarizedDescription(personalityTraitTestName)[0]
            }
            personalityTraitResultTypeDescription={getSummarizedDescription(
              personalityTraitTestName
            ).splice(1)}
            personalityTraitResultPercentrage={percentage}
            personalityTraitResultStrengths={getStrengths(fourAspectsType)}
            personalityTraitResultWeaknesses={getWeaknesses(fourAspectsType)}
          />
        );
      }
      return (
        <PersonalityTraitResult
          key={personalityTraitTestName}
          personalityTraitResultType={getTitle(personalityTraitTestName)}
          personalityTraitResultTypeLabel={getTitle(personalityTraitTestName)}
          personalityTraitResultTypeDescription={getDescription(
            personalityTraitTestName
          )}
          personalityTraitResultPercentrage={percentage}
          personalityTraitResultStrengths={getStrengths(
            personalityTraitTestName
          )}
          personalityTraitResultWeaknesses={getWeaknesses(
            personalityTraitTestName
          )}
        />
      );
    });
  };

  const renderMobileHeader = () => {
    return (
      <Stack marginTop="16px">
        <PersonalityTraitsTestsResultsMobileHeader>
          {fourAspectsAnalysis ? (
            <img
              src={renderImageBasedOnTrait(fourAspectsType)}
              style={{ width: '61px' }}
            />
          ) : (
            <img
              src={renderImageBasedOnTrait(highestScoreTrait)}
              style={{ width: '61px' }}
            />
          )}
          <Stack marginLeft="16px">
            <Text display="flex" alignItems="center">
              {personalityTestTraitsHashMap[personalityTraitsResultKey]}
              <Tooltip
                title={
                  personalityTraitsTooltipsCopyHashMap[
                    personalityTraitsResultKey
                  ]
                }
                placement="bottom"
                enterTouchDelay={0}
              >
                <Info
                  sx={{ fontSize: '1rem', margin: '5px', color: theme.palette.primary.main }}
                />
              </Tooltip>
            </Text>
            <Text variant="h4">
              Your Leading Type:{' '}
              {getTitle(fourAspectsType ? fourAspectsType : highestScoreTrait)}
            </Text>
          </Stack>
        </PersonalityTraitsTestsResultsMobileHeader>
        {renderTestResultsSummary()}
        <Button
          variant="contained"
          color="success"
          onClick={handleExpand}
          sx={{ marginTop: '16px', width: 'min-content' }}
        >
          {expandedState ? (
            <>
              Hide <ExpandLess />
            </>
          ) : (
            <>
              View <ExpandMore />
            </>
          )}
        </Button>
      </Stack>
    );
  };

  return (
    <PersonalityTraitsTestsResultsWrapper>
      {!isDesktop ? (
        renderMobileHeader()
      ) : (
        <PersonalityTraitsTestsResultsHeader>
          {fourAspectsAnalysis ? (
            <img
              src={renderImageBasedOnTrait(fourAspectsType)}
              style={{ width: '61px' }}
            />
          ) : (
            <img
              src={renderImageBasedOnTrait(highestScoreTrait)}
              style={{ width: '61px' }}
            />
          )}
          <PersonalityTraitsTestsResultsHeaderBody>
            <Text display="flex" alignItems="center">
              {personalityTestTraitsHashMap[personalityTraitsResultKey]}
              <Tooltip
                title={
                  personalityTraitsTooltipsCopyHashMap[
                    personalityTraitsResultKey
                  ]
                }
                placement="right-start"
                enterTouchDelay={0}
              >
                <Info
                  sx={{ fontSize: '1rem', margin: '5px', color: theme.palette.primary.main }}
                />
              </Tooltip>
            </Text>
            <Text variant="h4">
              Your Leading Type:{' '}
              {getTitle(fourAspectsType ? fourAspectsType : highestScoreTrait)}
            </Text>
            {renderTestResultsSummary()}
          </PersonalityTraitsTestsResultsHeaderBody>
          <Button variant="contained" color="success" onClick={handleExpand}>
            {expandedState ? (
              <>
                Hide <ExpandLess />
              </>
            ) : (
              <>
                View <ExpandMore />
              </>
            )}
          </Button>
        </PersonalityTraitsTestsResultsHeader>
      )}
      <Stack
        sx={(theme) => ({
          padding: '16px',
          width: '100%',
          marginLeft: '8px',
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          },
        })}
      >
        {expandedState ? (
          <>
            {renderPersonalityTraits()}{' '}
            <StrengthsWeaknesses
              strengths={
                personalityTraitsResultKey === 'fourAspects'
                  ? getStrengths(fourAspectsType)
                  : getStrengths(highestScoreTrait)
              }
              weaknesses={
                personalityTraitsResultKey === 'fourAspects'
                  ? getWeaknesses(fourAspectsType)
                  : getWeaknesses(highestScoreTrait)
              }
            />
          </>
        ) : null}
      </Stack>
      <Divider
        sx={(theme) => ({
          padding: 0,
          [theme.breakpoints.down('sm')]: {
            padding: '16px',
          },
        })}
      />
    </PersonalityTraitsTestsResultsWrapper>
  );
};
