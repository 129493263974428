import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const PersonalityTraitsTestsResultsWrapper = styled(Stack)(
  ({ theme }) => ({
    flexDirection: 'column',
    width: '100%',
  })
);

export const PersonalityTraitsTestsResultsHeader = styled(Stack)(
  ({ theme }) => ({
    flexDirection: 'row',
    margin: '16px',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  })
);

export const PersonalityTraitsTestsResultsHeaderBody = styled(Stack)(
  ({ theme }) => ({
    width: '80%',
    margin: '0 16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '16px 0',
    },
  })
);

export const PersonalityTraitsTestsResultsMobileHeader = styled(Stack)(
  ({ theme }) => ({
    flexDirection: 'row',
    width: '100%',
    marginBottom: '16px',
  })
);
