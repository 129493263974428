import {
  ContactResultSkeleton,
  NoContactResults,
  SerializedContactTeaserData,
} from '@ltvco/refresh-lib/v1';
import { ContactCards } from '../Cards/ContactCards';

interface ContactResultsContainerProps {
  results: SerializedContactTeaserData | undefined;
}

export function ContactResultsContainer({
  results,
}: ContactResultsContainerProps) {
  return !results ? (
    <ContactResultSkeleton />
  ) : results.total <= 0 ? (
    <NoContactResults />
  ) : (
    <ContactCards searchContactResult={results} />
  );
}
