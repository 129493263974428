import React from 'react';
import {
  ObituaryResultsCard,
  SerializedObituaryDetails,
} from '@ltvco/refresh-lib/v1';
import { ObituaryResultsCardv2 } from '@ltvco/refresh-lib/v2';
import { useSortedObitResults } from '@ltvco/refresh-lib/hooks';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
interface ObituaryResultsCardProps {
  searchObituaryResult: SerializedObituaryDetails;
  searchObituaryQuery: any;
}

export const ObituaryCards = ({
  searchObituaryResult,
  searchObituaryQuery,
}: ObituaryResultsCardProps) => {
  const sortedObits = useSortedObitResults(
    searchObituaryResult,
    searchObituaryQuery
  );

  return (
    <>
      {sortedObits.map((obituary: any, index: number) => (
        <React.Fragment key={index}>
          <ObituaryResultsCardv2 obituaryData={obituary} />
        </React.Fragment>
      ))}
    </>
  );
};
