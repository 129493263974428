import { constants } from 'appConstants';
import { PersonalityTraitsTestHashMap } from '../utils';

export const renderImageBasedOnTrait = (trait: string) => {
  const personalityTraitsImages = constants.images.personalityTraits;
  const personalityTraitsImagePerType: PersonalityTraitsTestHashMap = {
    ECBP: personalityTraitsImages.diplomats,
    ECBS: personalityTraitsImages.diplomats,
    ECOP: personalityTraitsImages.analyst,
    ECOS: personalityTraitsImages.analyst,
    ERBP: personalityTraitsImages.sentinels,
    ERBS: personalityTraitsImages.explorers,
    EROP: personalityTraitsImages.sentinels,
    EROS: personalityTraitsImages.explorers,
    ICBP: personalityTraitsImages.diplomats,
    ICBS: personalityTraitsImages.diplomats,
    ICOP: personalityTraitsImages.analyst,
    ICOS: personalityTraitsImages.analyst,
    IRBP: personalityTraitsImages.sentinels,
    IRBS: personalityTraitsImages.explorers,
    IROP: personalityTraitsImages.sentinels,
    IROS: personalityTraitsImages.explorers,
    agreeableness: personalityTraitsImages.agreeableness,
    conscientiousness: personalityTraitsImages.conscientiousness,
    extraversion: personalityTraitsImages.extraversion,
    neuroticism: personalityTraitsImages.neuroticism,
    openness: personalityTraitsImages.openness,
    compliance: personalityTraitsImages.compliance,
    dominance: personalityTraitsImages.dominance,
    influence: personalityTraitsImages.influence,
    stability: personalityTraitsImages.stability,
  };

  return personalityTraitsImagePerType[trait];
};
