import { Button, Text } from '@ltvco/refresh-lib/theme';
import { ModalContent, StepContainer } from './components';
import { constants } from 'appConstants';

interface StepOneProps {
  buttonOnClick: () => void;
}

export const StepOne = ({ buttonOnClick }: StepOneProps) => {
  const colorBar = constants.images.colorBar;
  const personalityTraitsLogo = constants.images.personalityTraitsLogo;
  const personalityTraitsModal = constants.images.personalityTraitsModal;

  return (
    <ModalContent>
      <img
        src={colorBar}
        style={{
          width: '100%',
          right: '1px',
          position: 'absolute',
          top: '0%',
        }}
        alt="Color Bar"
      />
      <img
        src={personalityTraitsModal}
        alt="Personality Traits Categories"
        style={{
          position: 'relative',
          width: '100%',
          top: '3%',
        }}
      />

      <StepContainer>
        <Text variant="h1" textAlign={'center'} mt={2.5}>
          Welcome to the AI Personality Test!
        </Text>
        <Text variant="body1" textAlign={'center'} mb={2}>
          Be yourself and answer honestly to find out your personality type.
          There are no right or wrong answers, only what feels most accurate to
          you.
        </Text>
        <Button
          onClick={buttonOnClick}
          variant="contained"
          sx={{ backgroundColor: '#000' }}
        >
          Find your Personality Type
          <img src={personalityTraitsLogo} alt="Personality Traits Logo" />
        </Button>
      </StepContainer>
    </ModalContent>
  );
};
