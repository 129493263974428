import { Stack, Box } from '@ltvco/refresh-lib/theme';
import { PreTestPersonalityTraits } from './PreTest/PreTest';
import { PersonalityHeader } from './PersonalityHeader/PersonalityHeader';
import { useEffect, useState } from 'react';
import { PersonalityTraitsResultsContainer } from './Results/PersonalityTraitsTestsResultsContainer';
import { TraitsMultiStepModal } from './PreTest/PersonalityTraitsSurvey/TraitsMultiStepModal';
import { usePersonalityScore } from './usePersoniltyScore/usePersonailyScore';
import {
  usePersonalityTraitsAnswers,
  usePostTraitsAnswers,
} from './PreTest/PersonalityTraitsSurveyHelpers';
import { LoadingDots, useSession } from '@ltvco/refresh-lib/v1';

export const PersonalityTraitsPage = () => {
  const [isTraitsModalOpen, setIsTraitsModalOpen] = useState(false);
  const [personalityScores, setPersonalityScores] = useState({});
  const [fourAspectsAnalysis, setFourAspectsAnalysis] = useState({});
  const { data, refetch, isLoading } = usePersonalityTraitsAnswers();
  const { postAnswers, tokenIsLoading } = usePostTraitsAnswers();
  const {
    session: { account },
  } = useSession();

  const handleTraitsModalOpen = () => {
    setIsTraitsModalOpen(true);
  };

  const handleTraitsModalClose = () => {
    refetch();
    setIsTraitsModalOpen(false);
  };

  const retakeTest = () => {
    handleTraitsModalOpen();
  };

  useEffect(() => {
    if (data) {
      const answers = usePersonalityScore(data);
      setPersonalityScores(answers.scores);
      setFourAspectsAnalysis(answers.analysis.fourAspectsAnalysis);
    }
  }, [data]);

  const submitCompletedTestOnLoad = async () => {
    const recordSearch = localStorage.getItem('recordSearch');
    if (!recordSearch) {
      return;
    }
    const storedAnswers = localStorage.getItem('answersStorage');
    const answersToPost = JSON.parse(storedAnswers || '');
    if (answersToPost.length === 50) {
      await postAnswers({
        user_code: account?.account.user_info.user_code,
        plan_id: 'TRAIT_BV_01',
        identifier: 'personality-test-v1',
        answers: answersToPost,
      });
      localStorage.removeItem('recordSearch');
      localStorage.removeItem('answersStorage');
      localStorage.removeItem('activeQuestionIndex');
      refetch();
    }
  };

  useEffect(() => {
    if (!tokenIsLoading) {
      submitCompletedTestOnLoad();
    }
  }, [tokenIsLoading]);

  if (isLoading) {
    return (
      <Stack
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            paddingRight: '16px',
          },
        })}
      >
        <PersonalityHeader />
        <Box sx={{ padding: '32px 0' }}>
          <LoadingDots count={3} />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          paddingRight: '16px',
        },
      })}
    >
      <PersonalityHeader />
      {Object.keys(personalityScores).length > 0 ? (
        <PersonalityTraitsResultsContainer
          personalityScores={personalityScores}
          fourAspectsAnalysis={fourAspectsAnalysis}
          retakeTest={retakeTest}
        />
      ) : (
        <PreTestPersonalityTraits onSearch={handleTraitsModalOpen} />
      )}
      <TraitsMultiStepModal
        open={isTraitsModalOpen}
        handleClose={handleTraitsModalClose}
      />
    </Stack>
  );
};
