import {
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Text,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { creditBureauConsts } from './utils';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

export const CreditBureauContacts = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const buildGridItems = () => {
    return creditBureauConsts.map((bureau, index) => {
      return (
        <Grid
          item
          xs={12}
          sm={3.7}
          key={'contact-bureau-' + index}
          padding={2.5}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <img src={bureau.logo} width="124px" height="33px" />

          <Divider sx={{ my: 1.25, height: '1px' }} />

          {bureau.sections.map((section, sectionIndex) => {
            const marginBottom =
              sectionIndex < bureau.sections.length - 1 ? 2.5 : 0;

            return (
              <Stack
                mb={marginBottom}
                key={`contact-bureau-${index}-${sectionIndex}`}
              >
                <Text variant="h6" mb={1}>
                  {section.title}
                </Text>

                <Text variant="body1" whiteSpace="pre-wrap" mb={1}>
                  {section.body.address}
                </Text>
                {section.body?.numbers?.map((number, numberIndex) => {
                  return (
                    <Text
                      variant="body1"
                      mb={1}
                      key={`contact-bureau-${index}-${sectionIndex}-${numberIndex}`}
                    >
                      {number}
                    </Text>
                  );
                })}
                <Link href={`https://${section.body.website}`} target="_blank">
                  {section.body.displayFriendlyWebsite || section.body.website}
                </Link>
              </Stack>
            );
          })}
        </Grid>
      );
    });
  };

  return (
    <>
      <Button
        variant="text"
        data-testid="back-button"
        onClick={() => navigate('/dashboard/id-monitor')}
        startIcon={<ArrowBack color="inherit" />}
        sx={{
          textDecoration: 'underline',
          color: 'text.primary',
          marginBottom: 2.5,
        }}
      >
        Back to ID Monitor
      </Button>

      <Text variant="h1">Credit Bureau Contact Information</Text>

      <Divider sx={{ my: 2.5, height: '1px' }} />

      <Grid container justifyContent={'space-between'} rowGap={2}>
        {buildGridItems()}
      </Grid>
    </>
  );
};
