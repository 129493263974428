import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const PTProgressBarContainer = styled(Stack)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

export const PTProgressBarText = styled('span')(({ theme }) => ({
  marginRight: '10px',
  fontWeight: 'bold',
}));

export const PTProgressBarBarContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '10px',
  backgroundColor: '#e0e0df',
  borderRadius: '5px',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'row',
}));

export const PTProgressBarBar = styled(Stack)(({ theme, property }) => ({
  width: `${property}%`,
  height: '100%',
  backgroundColor: '#000000',
  borderRadius: '5px 0 0 5px',
}));

export const PTProgressBarHandle = styled(Stack)(({ theme, property }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: '#000000',
  position: 'absolute',
  top: '50%',
  left: `${property}%`,
  transform: 'translate(-50%, -50%)',
}));
