import TransunionLogo from 'images/id-monitor-transunion-logo.svg';
import ExperianLogo from 'images/id-monitor-experian-logo.svg';
import EquifaxLogo from 'images/id-monitor-equifax-logo.svg';

type CreditBureauConsts = {
  logo: string;
  sections: {
    title: string;
    body: {
      address: string;
      numbers?: string[];
      website: string;
      displayFriendlyWebsite?: string;
    };
  }[];
}[];

export const creditBureauConsts: CreditBureauConsts = [
  {
    logo: TransunionLogo,
    sections: [
      {
        title: 'Dispute Information',
        body: {
          address: 'P.O. Box 2000\nChester, PA 19016',
          numbers: ['(800) 916-8800'],
          website: 'dispute.transunion.com',
        },
      },
      {
        title: 'Freeze',
        body: {
          address: 'P.O. Box 160\nWoodlyn, PA 19094',
          numbers: ['(800) 909-8872'],
          website: 'transunion.com/credit-freeze',
        },
      },
      {
        title: 'Fraud',
        body: {
          address: 'P.O. Box 2000\nChester, PA 19016',
          numbers: ['(800) 680-7289'],
          website: 'fraud.transunion.com',
        },
      },
    ],
  },
  {
    logo: EquifaxLogo,
    sections: [
      {
        title: 'Order Credit Report',
        body: {
          address: 'P.O. Box 740241\nAtlanta, GA 30374',
          numbers: ['(866) 349-5191', '(866) 478-0030 (TDD)'],
          website: 'equifax.com',
        },
      },
      {
        title: 'Dispute Information',
        body: {
          address: 'P.O. Box 740256\nAtlanta, GA 30374',
          numbers: ['(800) 349-5191'],
          website: 'equifax.com',
        },
      },
      {
        title: 'Freeze',
        body: {
          address: 'P.O. Box 105788\nAtlanta, GA 30348',
          numbers: ['(800) 349-9960'],
          website: 'equifax.com/personal/credit-report-services/credit-freeze/',
          displayFriendlyWebsite: 'equifax.com/credit-freeze',
        },
      },
      {
        title: 'Fraud',
        body: {
          address: 'P.O. Box 105069\nAtlanta, GA 30348',
          numbers: ['(800) 525-6285'],
          website: 'equifax.com',
        },
      },
    ],
  },
  {
    logo: ExperianLogo,
    sections: [
      {
        title: 'Order Credit Report',
        body: {
          address: 'P.O. Box 2002\nAllan, TX 75013',
          website: 'experian.com',
        },
      },
      {
        title: 'Dispute Information',
        body: {
          address: 'P.O. Box 4500\nAllen, TX 75013',
          website: 'experian.com',
        },
      },
      {
        title: 'Fraud/Freeze',
        body: {
          address: 'P.O. Box 9554\nAllen, TX 75013',
          numbers: ['(888) 397-3742'],
          website: 'experian.com/freeze',
        },
      },
    ],
  },
];
