import { PersonSearchNoResults, Record } from '@ltvco/refresh-lib/v1';
import { PersonCards } from '../Cards/PersonCards';
import { PersonCardLoading } from '../Loading/PersonCardLoading';

interface PersonResultsContainerProps {
  results: Record[] | undefined;
}

export function PersonResultsContainer({
  results,
}: PersonResultsContainerProps) {
  return !results ? (
    <PersonCardLoading />
  ) : results.length <= 0 ? (
    <PersonSearchNoResults />
  ) : (
    <PersonCards searchPersonResult={results} />
  );
}
