import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const PersonalityTraitDescriptionBox = styled(Stack)(({ theme }) => ({
  width: '94.5%',
  marginTop: '24px',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '16px',
  borderRadius: '4px',
}));

export const PersonalityTraitResultContainer = styled(Stack)(({ theme }) => ({
  width: '95%',
  flexDirection: 'row',
  marginTop: '16px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
