import { Stack, Text, Box, Button } from '@ltvco/refresh-lib/theme';
import FamilyTreeCtaIcon from './img/FamilyTreeCtaICon.svg';
import FamilyTreeCtaTree from './img/FamilyTreeCtaTree.svg';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

export function FamilyTreeCta() {
  const { trackEventGA4 } = useContext(AppConfig);
  const navigate = useNavigate();

  function handleGetStarted() {
    trackEventGA4({
      event_name: 'feature_engagement',
      type: 'family_tree',
      interacted_from: 'home',
    });

    navigate('/family-tree');
  }

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent={{ xs: 'center', md: 'space-between' }}
      sx={{
        px: 5,
        py: 2.5,
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        height={'100%'}
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Stack
          flexDirection="row"
          mb={2}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'start' }}
        >
          <img src={FamilyTreeCtaIcon} width={'60px'} height={'60px'} />
          <Text variant="h1" m="0 0 0 8px">
            Family Tree
          </Text>
        </Stack>
        <Box display={{ xs: 'block', md: 'none' }} mb={2}>
          <img src={FamilyTreeCtaTree} />
        </Box>
        <Text variant="h3" mb={0.5}>
          Start building your family tree!
        </Text>
        <Text variant="body1" mb={2}>
          Search for relative and ancestors and organize your information now.
        </Text>
        <Button
          variant="contained"
          onClick={handleGetStarted}
          sx={{ width: { xs: '100%', md: 'unset' } }}
        >
          Get Started
        </Button>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <img src={FamilyTreeCtaTree} />
      </Box>
    </Stack>
  );
}
