import { Button, Stack, styled } from '@ltvco/refresh-lib/theme';

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '30px',
  padding: '1px',
  height: '46px',
  borderWidth: '1px',
  [theme.breakpoints.up('sm')]: {
    minWidth: '30px',
    paddingLeft: '5px',
    paddingRight: '5px',
    height: '46px',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  maxWidth: '540px',
  justifyContent: 'center',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
  flexWrap: 'wrap',
  width: '100%',
  gap: theme.spacing(1.5),
  [theme.breakpoints.up('sm')]: {
    maxWidth: '900px',
    gap: theme.spacing(1.5),
  },
}));

export const TextContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
}));
