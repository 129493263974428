import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Psychology } from '@mui/icons-material';
import { constants } from 'appConstants';

export const PersonalityHeader = () => {
  const { isMobile } = useScreenSize();
  const { personalityColorBanner, personalityColorBar } = constants.images;

  return (
    <Stack spacing={2} my={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Psychology fontSize="large" />
        <Text variant="h1">Personality Trait</Text>
      </Stack>
      <img
        src={isMobile ? personalityColorBar : personalityColorBanner}
        alt="personality-color-banner"
      />
    </Stack>
  );
};
