import React, { useEffect, useState } from 'react';
import {
  AdvancedSearchURLParams,
  getAncestorSearchParams,
} from '@ltvco/refresh-lib/v2';
import { RawFamilySearchPersonResults, useFamilySearch } from '@ltvco/refresh-lib/fmt';

interface FamilySearchFetcherProps {
  searchData: AdvancedSearchURLParams;
  setFamilySearchResults: (
    results: RawFamilySearchPersonResults | undefined
  ) => void;
}

export function FamilySearchFetcher({
  searchData,
  setFamilySearchResults,
}: FamilySearchFetcherProps) {
  const [ip, setIp] = useState<string>('');

  useEffect(() => {
    (async () => {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();

      setIp(data.ip);
    })();
  }, []);

  const { data, isLoading, isError } = useFamilySearch(
    ip,
    getAncestorSearchParams(searchData),
    !!ip
  );

  const result = data as RawFamilySearchPersonResults | undefined;

  if (!isLoading && !isError) {
    setFamilySearchResults(result);
  } else {
    setFamilySearchResults(undefined);
  }

  return <React.Fragment />;
}
