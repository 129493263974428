import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import {
  mapResidentsToResidentInfo,
  PersonSearchNoResults,
  ResidentCard,
  SerializedPropertyReport,
} from '@ltvco/refresh-lib/v1';
import { ResidentCardv2 } from '@ltvco/refresh-lib/v2';

interface ResidentCardProps {
  searchResidentResult: SerializedPropertyReport;
}

export const ResidentCards = ({ searchResidentResult }: ResidentCardProps) => {
  if (!searchResidentResult) {
    return <PersonSearchNoResults />;
  }

  const residents = searchResidentResult?.properties?.[0]?.residents || [];

  return <ResidentCardv2 residents={residents} />;
};
