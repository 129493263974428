import {
  PTProgressBarContainer,
  PTProgressBarText,
  PTProgressBarBarContainer,
  PTProgressBarBar,
  PTProgressBarHandle,
} from './components';

type PTProgressBarProps = {
  percentage: number;
};

export const PTProgressBar = ({ percentage }: PTProgressBarProps) => {
  return (
    <PTProgressBarContainer>
      <PTProgressBarText>{percentage}%</PTProgressBarText>
      <PTProgressBarBarContainer>
        <PTProgressBarBar property={String(percentage)} />
        <PTProgressBarHandle property={String(percentage)} />
      </PTProgressBarBarContainer>
    </PTProgressBarContainer>
  );
};
