import {
  PersonSearchNoResults,
  SerializedObituaryDetails,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getObituarySearchParams,
} from '@ltvco/refresh-lib/v2';
import { ObituaryCards } from '../Cards/ObituaryCards';
import { PersonCardLoading } from '../Loading/PersonCardLoading';

interface ObituaryResultsContainerProps {
  searchData: AdvancedSearchURLParams;
  results: SerializedObituaryDetails | undefined;
}

export function ObituaryResultsContainer({
  searchData,
  results,
}: ObituaryResultsContainerProps) {
  return !results ? (
    <PersonCardLoading />
  ) : results.length === 0 ? (
    <PersonSearchNoResults />
  ) : (
    <ObituaryCards
      searchObituaryResult={results}
      searchObituaryQuery={getObituarySearchParams(searchData)}
    />
  );
}
