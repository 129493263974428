import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Text, Grid, Box } from '@ltvco/refresh-lib/theme';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { SearchHub } from '../SearchHub/SearchHub';
import { FeatureCard } from '@ltvco/refresh-lib/v2';

import {
  LanguageTutor,
  FamilyThree,
  UnclaimedMoney,
  DarkWebScan,
  Monitoring,
  MemberPerks,
  FraudScan,
  Identity,
  TheftInsurance,
} from 'icons';
import {
  useAccount,
  PremiumDataModal,
  usePremiumDataModal,
} from '@ltvco/refresh-lib/v1';
import { PremiumPaymentSuccessModal } from '@ltvco/refresh-lib/payments';
import { DashboardRecentReports } from './components/DashboardRecentReports';
import { FamilyTreeCta } from './components/FamilyTreeCta';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { DashboardMyPublicData } from './components/DashboardMyPublicData';

export function DashboardPage() {
  useEffect(() => {
    document.title = 'Dashboard - BeenVerified';
  }, []);
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const { data: account } = useAccount(true);
  const { isModalOpen, closeModal, triggerModal } = usePremiumDataModal();
  const [searchType, setSearchType] = useState('');
  const [isInSingleReportVariation, setIsInSingleReportVariation] =
    useState(false);
  const [paymentShowModalNumber, setPaymentShowModalNumber] =
    useState<number>(0);
  const [isSuccessModaVisible, setIsSuccessModaVisible] =
    useState<boolean>(false);

  const openSuccessModal = () => {
    setIsSuccessModaVisible(true);
  };

  const closeModals = () => {
    setIsSuccessModaVisible(false);
    setPaymentShowModalNumber(0);
  };

  const onSuccessPaymentHandler = () => {
    closeModals();
    navigate('/dashboard');
  };

  useEffect(() => {
    if (!account) return;
    const planName =
      account.account.subscription_info.subscription_plan_unique_name;
    const { isInSingleReportVariation } = checkPlanVariations(planName);
    setIsInSingleReportVariation(isInSingleReportVariation);
  }, [account]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFallFromCTAEmail = urlParams.get('premiumData');

    if (isInSingleReportVariation && isFallFromCTAEmail === 'true') {
      triggerModal(true, isInSingleReportVariation);
    }
  }, [isInSingleReportVariation]);

  const memberId = account?.account.user_info?.user_code || '';

  const featureAction = (str: string) => {
    setSearchType(str);
    triggerModal(true, isInSingleReportVariation);
  };

  const planName =
    account?.account?.subscription_info?.subscription_plan_unique_name ?? '';
  const { isInInternationalLanguageTest, isInternationalVinTest } =
    checkPlanVariations(planName);

  const features = [
    {
      title: 'Language Tutor',
      description: 'Speak a new language with confidence',
      icon: <LanguageTutor />,
      action: () => navigate('/dashboard/Language'),
    },
    {
      title: 'Family Tree',
      description:
        'Start building your family tree! Organize your information now',
      icon: <FamilyThree />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('family-tree')
          : navigate('/family-tree'),
    },
    {
      title: 'Unclaimed Money',
      description: 'Find out what money you may be owed',
      icon: <UnclaimedMoney />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('unclaimed money')
          : navigate('/dashboard/unclaimed-money'),
    },
    {
      title: 'Dark Web Scan',
      description: 'Check if your email was potentially leaked',
      icon: <DarkWebScan />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('dark web')
          : navigate('/dashboard/dark-web'),
    },
    {
      title: 'Identity & Credit Alerts',
      description: 'Monitor potential identity and credit record activity',
      icon: <Identity />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('identity & credit alerts')
          : navigate('/dashboard/id-monitor?escalated=true'),
    },
    {
      title: 'Monitor your reports',
      description: 'Receive updates if we detect changes',
      icon: <Monitoring />,
      action: () => navigate('/dashboard/monitoring'),
    },
    {
      title: 'Member Perks',
      description:
        'Savings may be available through gift cards, discounts, and more',
      icon: <MemberPerks />,
      action: () => navigate('/dashboard/rewards'),
    },
    {
      title: '$1M ID Theft Insurance',
      description:
        'Protect your identity with identity theft insurance and restoration services',
      icon: <TheftInsurance />,
      action: () =>
        isInSingleReportVariation
          ? featureAction('identity theft insurance')
          : navigate('/dashboard/id-monitor?escalated=true'),
    },
    {
      title: 'FraudScan',
      description:
        'Check phone numbers and emails for threats and fraudulent info',
      icon: <FraudScan />,
      action: () => navigate('/dashboard/fraud'),
    },
  ];

  const isOnMyPublicData = useFeatureIsOn('RFRSH-2573');

  return (
    <Box display="flex" flexDirection="column" rowGap={2.5}>
      <SearchHub />
      {isOnMyPublicData ? (
        <DashboardMyPublicData />
      ) : (
        <DashboardRecentReports />
      )}
      <FamilyTreeCta />
      {isInternationalVinTest || isInInternationalLanguageTest ? null : (
        <Grid container direction="column">
          <Grid item>
            <Text variant="h3" mb={3} mt={2}>
              Explore New Features
            </Text>
          </Grid>
          <Grid container spacing={isMobile ? 1.5 : 2}>
            {features.map((feature) => (
              <Grid item xs={12} sm={6} md={4} key={feature.title}>
                <FeatureCard
                  title={feature.title}
                  description={feature.description}
                  icon={feature.icon}
                  action={feature.action}
                  width={'100%'}
                  height={120}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {isInSingleReportVariation && (
        <PremiumDataModal
          isOpen={isModalOpen}
          onClose={closeModal}
          searchType={searchType}
          paymentShowModalNumber={paymentShowModalNumber}
          setPaymentShowModalNumber={setPaymentShowModalNumber}
          openSuccessModal={openSuccessModal}
        />
      )}
      <PremiumPaymentSuccessModal
        open={isSuccessModaVisible}
        onClose={closeModals}
        onAccept={onSuccessPaymentHandler}
      />
    </Box>
  );
}
