import {
  Box,
  ProgressBar,
  Stack,
  styled,
  Text,
} from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  ActiveComponent,
  ActiveComponentProps,
  QuestionsSurveyProps,
} from './constants';
import { usePersonalityTraitsWizard } from '../wizard';
import { useEffect } from 'react';
import { ContentContainer } from './components';
import PersonalityTraitsLoading from './PersonalityTraitsAnimations';

const ProgressBarAdjusted = styled(ProgressBar)({
  borderRadius: '8px 8px 0 0',
  backgroundColor: '#d9e8c9',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#81B44C',
  },
});

export function QuestionsSurvey({
  questionsSet,
  onTimeoutComplete,
}: QuestionsSurveyProps) {
  const { activeQuestion, progressBarPercentage, storeQuestionAnswer } =
    usePersonalityTraitsWizard(questionsSet);
  const inputType = activeQuestion?.response.input_type;
  const isMiddleQuestion = activeQuestion?.screen_id === 'TRAIT-BV-01-26';
  const isLastQuestion = activeQuestion?.screen_id === 'TRAIT-BV-01-52';
  const ComponentToRender =
    ActiveComponent[inputType as keyof ActiveComponentProps];
  const { isMobile } = useScreenSize();

  const splitQuestionText = (text: string, breakIndex: number) => {
    const beforeBreak = text.slice(0, breakIndex);
    const afterBreak = text.slice(breakIndex);
    return (
      <span>
        {beforeBreak}
        <br />
        {afterBreak}
      </span>
    );
  };

  useEffect(() => {
    if (isLastQuestion) {
      const timeout = setTimeout(() => {
        onTimeoutComplete();
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [activeQuestion, onTimeoutComplete, isLastQuestion]);

  if (isLastQuestion) {
    localStorage.removeItem('randomQuestions');
    localStorage.removeItem('activeQuestionIndex');
    localStorage.removeItem('answersStorage');
    localStorage.removeItem('recordSearch');
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        zIndex: '1',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <ProgressBarAdjusted
        value={progressBarPercentage}
        variant="determinate"
        sx={{ height: [16, 14] }}
      />
      <Text
        variant="h6"
        color="#000"
        sx={{
          position: 'absolute',
          fontSize: [14, 16],
        }}
      >
        {`${Math.round(progressBarPercentage)}%`}
      </Text>
      <ContentContainer>
        <Stack
          id={`container-${activeQuestion.screen_id}`}
          sx={{ transition: '1s ease-in-out' }}
        >
          <Stack
            sx={{ margin: '85px 0' }}
            alignItems={isMiddleQuestion ? 'start' : 'center'}
          >
            <Text
              variant="h3"
              color="#000"
              textAlign="start"
              sx={{
                fontSize: [24, 30],
                marginBottom: [3, 5],
                padding: isMobile ? '0 20px' : '0 40px',
              }}
              id={`questionText-${activeQuestion.screen_id}`}
            >
              {isMiddleQuestion
                ? splitQuestionText(activeQuestion.question_text, 14)
                : activeQuestion.question_text}
            </Text>
            {isLastQuestion && <PersonalityTraitsLoading />}
            <Stack
              display={isLastQuestion ? 'none' : 'block'}
              sx={{ width: '100%' }}
            >
              <ComponentToRender
                storeAnswer={storeQuestionAnswer}
                screenId={activeQuestion.screen_id}
                answerSet={
                  activeQuestion.response.answer_set
                    ? activeQuestion.response.answer_set.map((item) =>
                        String(item)
                      )
                    : []
                }
                customAttribute={
                  activeQuestion.response.custom_attribute !== null &&
                  activeQuestion.response.custom_attribute !== undefined
                    ? activeQuestion.response.custom_attribute
                    : ''
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </ContentContainer>
    </Box>
  );
}
