/*
  Once we test and mature this code a little more and add the functionality to pull plans from the API,
  we can evaluate what of this code should be made shared in the refresh-lib
*/
import { type SubscriptionPlan } from '@ltvco/refresh-lib/v1';
import { costPerReport, savings } from '@ltvco/refresh-lib/payments';

export interface CurrentPlan {
  title: string;
  amount: number;
  /** Renewal period in months */
  renewalPeriod: number;
  /** Report limit */
  limit: number;
  costPerReport?: number;
}

export const planTitles: {
  [key: number]: { title: string; description: string; premier?: boolean };
} = {
  25: {
    title: 'Saver',
    description: 'Save more per month by running fewer reports',
  },
  30: { title: 'Starter', description: 'Starting small and simple' },
  100: { title: 'Starter', description: 'Starting small and simple' },
  150: { title: 'Standard', description: 'Boost your search capacity' },
  200: { title: 'Standard', description: 'Double your search capacity' },
  300: { title: 'Premium', description: 'Your premium reports package' },
  400: { title: 'Premium', description: 'Your premium reports package' },
  500: {
    title: 'Professional',
    description: 'Take your business to the next level',
  },
  750: {
    title: 'Professional',
    description: 'Take your business to the next level',
  },
  1000: {
    title: 'Professional',
    description: 'Take your business to the next level',
  },
  1500: { title: 'Premier', description: 'Perfect for fast-tracking growth' },
  2000: { title: 'Premier', description: 'Perfect for fast-tracking growth' },
  2500: {
    title: 'Premier',
    description: 'Perfect for fast-tracking growth',
    premier: true,
  },
  3000: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  5000: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  7500: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  8500: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  10000: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  15000: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
  20000: {
    title: 'Premier +',
    description: 'Perfect for fast-tracking growth',
  },
};

export const scrollTestplanTitles: {
  [key: number]: { title: string; description: string; premier?: boolean };
} = {
  200: { title: 'Standard', description: 'Double your search capacity' },
  400: { title: 'Premium', description: 'Your Premium reports package' },
  800: { title: 'Advanced', description: 'Elevate search potential' },
  1500: {
    title: 'Professional',
    description: 'Take your business to the next level',
  },
  3000: {
    title: 'Premier',
    description: 'Perfect for fast-tracking growth',
    premier: true,
  },
};

const fallbackTitles: { [key: number]: { title: string } } = {
  25: { title: 'Saver' },
  100: { title: 'Lite User' },
  200: { title: 'Lite User' },
  400: { title: 'Plus User' },
  2000: { title: 'Elite User' },
};

export const customPlanTitle: {
  title: string;
  description: string;
} = {
  title: 'Premier +',
  description: 'Perfect for fast-tracking growth',
};

const mostPopularPlanLimit = 25;

export function getPlanTitleAndDescription(
  planLimit: number,
  isScrollPlansVariation: boolean = false,
  isCustomPlan: boolean = false
) {
  if (isCustomPlan) {
    return customPlanTitle;
  }

  if (isScrollPlansVariation && scrollTestplanTitles[planLimit]) {
    return scrollTestplanTitles[planLimit];
  }

  return (
    planTitles[planLimit] ||
    fallbackTitles[planLimit] || { title: 'Power User' }
  );
}

/** Returns the cost per report of the plan if it's not unlimited */
export function planCostPerReport(plan: {
  amount: number;
  renewalPeriod: number;
  limit: number;
}) {
  if (plan.limit === -1) return;

  return costPerReport(plan.amount, plan.renewalPeriod, plan.limit);
}

/** Returns the savings of the plan compared to the current plan */
export function planSavings(plan: SubscriptionPlan, currentPlan: CurrentPlan) {
  if (currentPlan.limit === -1 || plan.monthly_report_limit === -1) return;

  return savings(
    currentPlan.amount,
    currentPlan.renewalPeriod,
    currentPlan.limit,
    plan.amount / 100,
    plan.renewal_period,
    plan.monthly_report_limit
  );
}

export function getCustomPlan(
  brandPlan: SubscriptionPlan,
  currentPlan: CurrentPlan
) {
  return [mapPlan(brandPlan, currentPlan, false, false, true)];
}
/** Returns all the plans */
export function getPlans(
  brandPlans: SubscriptionPlan[],
  currentPlan: CurrentPlan,
  isDownsellVariation: boolean,
  isScrollPlansVariation: boolean
) {
  let filteredPlans: SubscriptionPlan[];

  if (isDownsellVariation) {
    filteredPlans = brandPlans.filter(
      (plan) => plan.monthly_report_limit !== currentPlan.limit
    );
  } else {
    filteredPlans = brandPlans.filter(
      (plan) => plan.monthly_report_limit > currentPlan.limit
    );
  }

  return filteredPlans
    .map((plan) =>
      mapPlan(plan, currentPlan, isDownsellVariation, isScrollPlansVariation)
    )
    .reverse();
}

export function mapPlan(
  plan: SubscriptionPlan,
  currentPlan: CurrentPlan,
  isDownsellVariation: boolean,
  isScrollPlansVariation: boolean,
  isCustomPlan?: boolean
) {
  return {
    key: plan.unique_key,
    ...getPlanTitleAndDescription(
      plan.monthly_report_limit,
      isScrollPlansVariation,
      isCustomPlan
    ),
    amount: plan.amount / 100,
    limit: plan.monthly_report_limit,
    renewalPeriod: plan.renewal_period,
    renewalPeriodType: plan.renewal_period_type,
    costPerReport: planCostPerReport({
      amount: plan.amount / 100,
      renewalPeriod: plan.renewal_period,
      limit: plan.monthly_report_limit,
    }),
    savings: planSavings(plan, currentPlan),
    mostPopular: isDownsellVariation
      ? false
      : mostPopularPlanLimit === plan.monthly_report_limit,
  };
}

export function getBillingFrequency(renewalPeriod: number) {
  switch (renewalPeriod) {
    case 1:
      return 'monthly';
    case 3:
      return 'every 3 months';
    case 12:
      return 'annually';
    default:
      return 'N/A';
  }
}
