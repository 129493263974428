import { useState, useEffect } from 'react';
import {
  NEXT_ACTION,
  PREVIOUS_ACTION,
  DELAY_SHOW_DURATION,
  DELAY_HIDE_DURATION,
} from './constants';
import {
  PersonalityTraitsQuestionsProps,
  QuestionProps,
  AnswersProps,
  usePostTraitsAnswers,
} from '../index';
import { useSession } from '@ltvco/refresh-lib/v1';

const getSavedQuestion = (questions: QuestionProps[]) => {
  const savedQuestionIndex = localStorage.getItem('activeQuestionIndex') || '';
  if (savedQuestionIndex) {
    return questions[+savedQuestionIndex];
  }
  return questions[0];
};

const saveActiveQuestionIndex = (
  questions: QuestionProps[],
  activeQuestion: QuestionProps
) => {
  const index = questions.findIndex(
    (question) => question.screen_id === activeQuestion.screen_id
  );
  localStorage.setItem('activeQuestionIndex', JSON.stringify(index));
};

export const usePersonalityTraitsWizard = (
  initialQuestions: PersonalityTraitsQuestionsProps
) => {
  const {
    session: { account },
  } = useSession();
  const { postAnswers } = usePostTraitsAnswers();
  const [planId] = useState<string>(initialQuestions.question_plan);
  const [questions] = useState<QuestionProps[]>(initialQuestions.questions);

  const shuffledQuestions = questions;

  const savedQuestion = getSavedQuestion(shuffledQuestions);
  const [activeQuestion, setActiveQuestion] =
    useState<QuestionProps>(savedQuestion);
  const savedQuestionIndex = localStorage.getItem('activeQuestionIndex')
    ? JSON.parse(localStorage.getItem('activeQuestionIndex')!)
    : 0;
  const [questionNumber, setQuestionNumber] = useState<number>(
    savedQuestionIndex + 1
  );
  const [progressBarPercentage, setProgressBarPercentage] = useState<number>(
    (savedQuestionIndex / questions.length) * 100
  );
  const [questionSwitching, setQuestionSwitching] = useState<boolean>(false);
  const [isReadyForSubmit, setIsReadyForSubmit] = useState<boolean>(false);
  const [answersStorage, setAnswersStorage] = useState<AnswersProps[]>(() => {
    const savedAnswers = localStorage.getItem('answersStorage');
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  useEffect(() => {
    if (isReadyForSubmit) {
      setIsReadyForSubmit(false);
      _postAnswers();
    }
  }, [isReadyForSubmit]);

  const storeQuestionAnswer = (answer: AnswersProps) => {
    if (answer.response && _isLastQuestion()) {
      _updateAnswersStorage(answer);
      setIsReadyForSubmit(true);
      _nextQuestion();
      return;
    }
    if (!answer.response || _isLastQuestion()) {
      _translateQuestionToLeft();
      setIsReadyForSubmit(true);
      return;
    }

    _updateAnswersStorage(answer);
    _nextQuestion();
  };

  const _postAnswers = () => {
    const storedAnswers = localStorage.getItem('answersStorage');
    let answersToPost: AnswersProps[] = storedAnswers
      ? JSON.parse(storedAnswers)
      : answersStorage;

    // Filter out the middle answer before posting
    let filteredAnswers = answersToPost.filter(
      (answer) => !(answer.screen_id === 'TRAIT-BV-01-26')
    );

    // Update screen IDs after removing the middle question
    filteredAnswers = filteredAnswers.map((answer, index) => {
      if (parseInt(answer.screen_id.split('-').pop()!, 10) > 26) {
        return {
          ...answer,
          screen_id: decreaseLastNumber(answer.screen_id),
        };
      }
      return answer;
    });

    postAnswers({
      user_code: account?.account.user_info.user_code,
      plan_id: planId,
      identifier: 'personality-test-v1',
      answers: filteredAnswers,
    });

    // Update the local state with the decremented screen IDs
    setAnswersStorage(filteredAnswers);

    // Save the updated answers to localStorage
    localStorage.setItem('answersStorage', JSON.stringify(filteredAnswers));
  };

  const _updateAnswersStorage = (answer: AnswersProps) => {
    setAnswersStorage((prevAnswersStorage) => {
      const updatedAnswers = prevAnswersStorage.filter(
        (storedAnswer) => storedAnswer.screen_id !== 'TRAIT-BV-01-26'
      );

      const existingAnswerIndex = updatedAnswers.findIndex(
        (storedAnswer) => storedAnswer?.screen_id === answer.screen_id
      );

      if (existingAnswerIndex !== -1) {
        updatedAnswers.splice(existingAnswerIndex, 1);
      }

      const newAnswersStorage = [...updatedAnswers, answer];

      localStorage.setItem('answersStorage', JSON.stringify(newAnswersStorage));

      return newAnswersStorage;
    });
  };

  const _identifyQuestion = (index: number): QuestionProps => {
    // Ensure the index is within bounds
    if (index < 0 || index >= shuffledQuestions.length) {
      throw new Error('Index out of bounds');
    }
    return shuffledQuestions[index];
  };

  const _isFirstQuestion = () =>
    shuffledQuestions[0].screen_id === activeQuestion.screen_id;

  const _isLastQuestion = () =>
    shuffledQuestions[shuffledQuestions.length - 2].screen_id ===
    activeQuestion.screen_id;

  const _nextQuestion = () => {
    _translateQuestionToLeft();
    _hideAndShowNextQuestion(NEXT_ACTION);
  };

  const prevQuestion = () => {
    if (_isFirstQuestion()) return;

    _translateQuestionToLeft();
    _hideAndShowNextQuestion(PREVIOUS_ACTION);
  };

  // HTML Manipulation Functions
  const _translateQuestionToLeft = () => {
    setQuestionSwitching(true);
    const element = document.getElementById(
      `container-${activeQuestion.screen_id}`
    );
    if (element) {
      const elementWidth = 1000;
      element.style.transform = `translateX(-${elementWidth}px)`;
    }
  };

  const decreaseLastNumber = (screenId: string) => {
    const parts = screenId.split('-');
    const lastNumber = parseInt(parts[parts.length - 1], 10) - 1;
    parts[parts.length - 1] = String(lastNumber).padStart(2, '0');
    return parts.join('-');
  };

  const _hideAndShowNextQuestion = (action: string) => {
    let questionToRenderScreen = 0;
    const element = document.getElementById(
      `container-${activeQuestion.screen_id}`
    );
    if (!element) return;

    setTimeout(() => {
      _hideAndRestoreStyleHtmlElement(element);

      if (action === NEXT_ACTION) {
        questionToRenderScreen = parseInt(savedQuestionIndex) + 1;
        setQuestionNumber(questionNumber + 1);
      } else {
        questionToRenderScreen = parseInt(savedQuestionIndex) - 1;
        setQuestionNumber(questionNumber - 1);
      }

      const questionToRender = _identifyQuestion(questionToRenderScreen);
      setActiveQuestion(questionToRender);
      setProgressBarPercentage(
        (questionNumber / shuffledQuestions.length) * 100
      );
      _showHtmlElement(element);
    }, DELAY_HIDE_DURATION);
  };

  const _hideAndRestoreStyleHtmlElement = (element: HTMLElement) => {
    if (element) {
      element.classList.add('d-none');
      element.style.transform = 'translateY(-0px)';
    }
  };

  const _showHtmlElement = (element: HTMLElement) => {
    if (element) {
      setTimeout(() => {
        element.classList.remove('d-none');
        setQuestionSwitching(false);
      }, DELAY_SHOW_DURATION);
    }
  };

  // Save active question index whenever the active question changes
  useEffect(() => {
    saveActiveQuestionIndex(shuffledQuestions, activeQuestion);
  }, [activeQuestion, questions]);

  return {
    prevQuestion,
    questionSwitching,
    activeQuestion,
    progressBarPercentage,
    storeQuestionAnswer,
  };
};
