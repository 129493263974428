import { PersonalityStrengthWeakness } from 'routes/Dashboard/PersonalityTraits/usePersonalityDescriptions/types';
import { StrengthsWeaknessesListItem } from './components';

export const renderStrengthsWeaknesses = (
  attributes: PersonalityStrengthWeakness[]
) => {
  return attributes.map((attribute, index) => {
    return (
      <StrengthsWeaknessesListItem key={index + attribute.label}>
        <strong>{attribute.label}:</strong> {attribute.description}
      </StrengthsWeaknessesListItem>
    );
  });
};
