import React from 'react';
import {
  SerializedContactTeaserData,
  useSearchContact,
} from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getContactSearchParams,
} from '@ltvco/refresh-lib/v2';

interface ContactFetcherProps {
  searchData: AdvancedSearchURLParams;
  setContactResults: (results: SerializedContactTeaserData | undefined) => void;
}

export function ContactFetcher({
  searchData,
  setContactResults,
}: ContactFetcherProps) {
  const { data, isError, isFetched } = useSearchContact({
    contactSearchParams: getContactSearchParams(searchData),
  });

  const result = data as SerializedContactTeaserData | undefined;

  if (isFetched && !isError) {
    setContactResults(result);
  } else {
    setContactResults(undefined);
  }

  return <React.Fragment />;
}
