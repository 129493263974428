import { Stack, Tab, TabsNavigation, styled } from '@ltvco/refresh-lib/theme';
import { ClaimedReportMonitorTiles } from 'components/idMonitor/ClaimedReportMonitorsContainer/ClaimedReportMonitorTiles';
import { IdMonitorContainer } from 'components/idMonitor/IdMonitorContainer';
import { OneTimePasscodeProvider } from 'components/idMonitor/Oxford/OneTimePasscodeContext';
import { useEffect, useState, useContext } from 'react';
import { MainHeader } from 'components/idMonitor/MainHeader';
import { useQueryParams } from 'utils/useQueryParams';
import { SettingsTiles } from 'components/idMonitor/SettingsTiles/SettingsTiles';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const StyledTabsNavigation = styled(TabsNavigation)(({ theme }) => ({
  color: theme.palette.secondary.light,
  borderBottom: 'solid',
  marginBottom: theme.spacing(2.5),
}));

export function MyReports() {
  const [activeTab, setActiveTab] = useState(0);

  const { trackEvent } = useContext(AppConfig);
  const queryParams = useQueryParams();
  const escalatedParam = queryParams.get('escalated');

  useEffect(() => {
    const tabParam = queryParams.get('tab');
    if (tabParam === 'settings') {
      setActiveTab(0);
    } else if (escalatedParam === 'true' || tabParam === 'monitor') {
      setActiveTab(1);
    } else {
      window.history.replaceState({}, '', `?tab=settings`);
      setActiveTab(0);
    }
  }, [queryParams]);

  useEffect(() => {
    const newTab = activeTab === 0 ? 'settings' : 'monitor';
    // Update URL with selected tab parameter
    const escalatedParamUrl: string =
      escalatedParam === 'true' ? 'escalated=true&' : '';
    window.history.replaceState({}, '', `?${escalatedParamUrl}tab=${newTab}`);
  }, [activeTab]);

  return (
    <>
      <ClaimedReportMonitorTiles />
      <Stack direction="column" padding={2.5}>
        <MainHeader />
        <StyledTabsNavigation
          externalActiveTab={activeTab}
          onTabChanged={(currentTab: number) => {
            const newTab = currentTab === 0 ? 'settings' : 'monitor';
            trackEvent(
              newTab === 'settings' ? 'settings tab' : 'monitor tab',
              'navigation click',
              'id monitor settings'
            );
            setActiveTab(currentTab);
          }}
          tab={Tab}
          tabs={[
            {
              content: (
                <Stack gap={2.5}>
                  <SettingsTiles setIsSettingsTab={() => setActiveTab(1)} />
                </Stack>
              ),
              label: 'Settings',
            },
            {
              content: (
                <OneTimePasscodeProvider>
                  <IdMonitorContainer />
                </OneTimePasscodeProvider>
              ),
              label: 'Monitor',
            },
          ]}
        />
      </Stack>
    </>
  );
}
