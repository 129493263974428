import { Button, styled } from '@ltvco/refresh-lib/theme';

export const StyledButton = styled(Button)(() => ({
  minWidth: '113px',
  padding: '1px',
  height: '42px',
}));

export const Container = styled('div')(() => ({
  maxWidth: '540px',
  justifyContent: 'start',
  display: 'flex',
}));
