import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { Loading } from 'components/idMonitor/Loading';
import { NotificationAlertsSummaries } from 'components/idMonitor/NotificationsAlertsSummaries';
import { useOxford } from 'components/idMonitor/Oxford/Oxford';
import { sessionEscalationURL } from 'components/idMonitor/Oxford/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AlertDetails = () => {
  const navigate = useNavigate();

  let { isError: isServiceTokenError } = useServiceToken(
    'oxford',
    sessionEscalationURL
  );

  const {
    data,
    isError: isNotificationsError,
    markAllNotificationsAsDisplayed,
  } = useOxford().useNotifications();

  if (isNotificationsError || isServiceTokenError) {
    navigate('/dashboard/id-monitor?escalated=true');
  }

  useEffect(() => {
    if (data) {
      markAllNotificationsAsDisplayed();
    }
  }, [data]);

  if (data) {
    return (
      <NotificationAlertsSummaries
        notifications={data?.notifications.notifications}
      />
    );
  }

  return <Loading />;
};
