import { styled, Stack } from '@ltvco/refresh-lib/theme';
import { Height } from '@mui/icons-material';

export const ContentContainer = styled(Stack)(({ theme }) => ({
  marginBottom: '20px',
  minHeight: '400px',
  overflow: 'hidden',
  padding: '20px 15px 0',
  position: 'relative',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    minHeight: '600px',
    padding: '40px 24px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '400px',
    padding: '40px 24px',
    height: '520px',
  },

  '#questionText-TRAIT-BV-01-26': {
    textAlign: 'start',
    justifyContent: 'start',
    padding: '0',
  },
}));

export const TransitionStack = styled(Stack)(() => ({
  transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
  '&.entering': {
    transform: 'translateX(0)',
  },
  '&.exiting': {
    transform: 'translateX(100%)',
  },
}));

export const InlineContainer = styled('div')({
  display: 'inline-flex',
  height: '20px',
});
