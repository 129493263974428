import { Stack, styled } from '@ltvco/refresh-lib/theme';
import { traitsTheme } from '../constants';

export const ModalContent = styled('div')`
  background-color: ${traitsTheme.firstStepBackground};
  width: 100%;
  z-index: 1;
  border-radius: 8px;
  position: relative;
`;

export const StepContainer = styled(Stack)(({ theme }) => ({
  padding: '20px 16px',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: '20px 40px 86px',
  },
}));

export const TraitCategoriesContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  flexFlow: 'wrap',
  justifyContent: 'space-evenly',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const TraitCategoryContainer = styled('div')(({ theme }) => ({
  width: '89px',
  borderRadius: '8px',
  boxShadow: traitsTheme.categoryBoxShadow,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  [theme.breakpoints.up('sm')]: {
    width: '110px',
    marginBottom: '10px',
  },
}));

export const TraitResultContainer = styled(Stack)(({ theme }) => ({
  background: traitsTheme.resultContainer,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: '3px solid #fff',
  boxShadow: traitsTheme.categoryBoxShadow,
  padding: '10px',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

export const TraitImage = styled('img')(({ theme }) => ({
  width: '100px',
  height: '100px',
  [theme.breakpoints.up('sm')]: {
    width: '65px',
    height: '65px',
  },
}));

export const TraitResultContent = styled(Stack)(({ theme }) => ({
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
}));

export const TextContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
