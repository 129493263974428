type SearchDataValues = Record<string, string>;

export const formatListingsText = (
  queryData: SearchDataValues,
  searchType: string
) => {
  if (!queryData) return;

  const resultsTextMapByResultType: SearchDataValues = {
    pre_foreclosures: 'Pre-foreclosures',
    for_sale: 'Homes For Sale',
  };

  const zip = queryData.zip5;
  const city = queryData.city;
  const state = queryData.state;
  const lat = queryData.lat;
  const long = queryData.long;

  if (zip) {
    return zip;
  } else if (city && state) {
    return `${city}, ${state}`;
  } else if (lat && long) {
    return 'Current Location';
  } else {
    return resultsTextMapByResultType[
      searchType
    ] as keyof typeof resultsTextMapByResultType;
  }
};

export const paginationResultsRangeText = (
  currentPage: string,
  perPage: number,
  totalResults: number
) => {
  const page = currentPage ? Number(currentPage) : 1;
  const start = (page - 1) * perPage + 1;
  const end = start - 1 + perPage;
  return `${start} - ${end > totalResults ? totalResults : end}`;
};
