import { darkWebScanFaq } from './darkWebScanConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  type SearchData,
  useSession,
  useDarkWebReport,
  ReportLoading,
  DarkWebHeader,
  DarkWebBreaches,
  type SerializedDarkWebBreach,
  DarkWebFindOutWhatElse,
  Faq,
} from '@ltvco/refresh-lib/v1';

export function DarkWebScan() {
  const {
    session: { account },
  } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Dark Web Scan - BeenVerified';
  }, []);

  const { data, isLoading } = useDarkWebReport({
    email: account?.account.user_info?.email as string,
  });

  if (isLoading) return <ReportLoading />;

  const breaches = data?.data?.breaches;
  const updatedAt = data?.data?.rawData?.meta?.updated_at;

  return (
    <>
      <DarkWebHeader updatedAt={updatedAt || ''} />
      <DarkWebBreaches breaches={breaches as SerializedDarkWebBreach[]} />
      <DarkWebFindOutWhatElse
        onSuccess={({ reportType, searchParams, permalink }: SearchData) => {
          const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
          navigate(url);
        }}
      />
      <Faq faqItems={darkWebScanFaq} />
    </>
  );
}
