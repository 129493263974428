import { Button, Divider, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { constants } from 'appConstants';

import {
  PreTestBottomSection,
  PreTestSuggestionContainer,
  PreTestTopSection,
  PreTestTopSubtitle,
} from './components';
import { Favorite, People, Work } from '@mui/icons-material';

type PreTestPersonalityTraitsProps = {
  onSearch: () => void;
};

export const PreTestPersonalityTraits = ({
  onSearch,
}: PreTestPersonalityTraitsProps) => {
  const { isMobile } = useScreenSize();
  const fourMainPersonalityTypes = constants.images.fourMainPersonalityTypes;
  const fourMainPersonalityTypesMobile =
    constants.images.fourMainPersonalityTypesMobile;

  return (
    <Stack alignItems="center">
      <PreTestTopSection>
        <Text variant="h1" marginBottom="16px">
          Discover Your Personality
        </Text>
        <PreTestTopSubtitle>
          Unlock the secrets to your unique strengths and attributes with our
          comprehensive personality test. By understanding your personality
          type, you can gain insights that will help you in every aspect of your
          life.
        </PreTestTopSubtitle>
        <Button
          variant="contained"
          color="success"
          sx={{ marginBottom: '36px' }}
          fullWidth={isMobile}
          onClick={onSearch}
        >
          Find Your Personality Type
        </Button>
        <img
          src={
            isMobile ? fourMainPersonalityTypesMobile : fourMainPersonalityTypes
          }
          alt="four-main-personality-types"
          width={isMobile ? '100%' : '95%'}
        />
      </PreTestTopSection>
      <Stack width="95%" marginTop="36px">
        <Text variant="h2">Why Take the Test?</Text>
        <Text>
          By taking our test, you’ll be on your way to understanding yourself
          better, improving your relationships, and achieving your personal and
          professional goals.
        </Text>
      </Stack>
      <PreTestBottomSection>
        <PreTestSuggestionContainer>
          <Favorite />
          <Text fontWeight="bold" marginTop="16px">
            Understand Yourself Better:
          </Text>
          <Text marginTop="8px">
            Gain insights into your personality traits, preferences, and
            potential to make informed decisions and live more authentically.
          </Text>
        </PreTestSuggestionContainer>
        <Divider orientation="vertical" flexItem sx={{ margin: '8px' }} />
        <PreTestSuggestionContainer>
          <People />
          <Text fontWeight="bold" marginTop="16px">
            Improve Your Relationships:
          </Text>
          <Text marginTop="8px">
            Enhance communication and empathy to build stronger, more fulfilling
            personal and professional relationships.
          </Text>
        </PreTestSuggestionContainer>
        <Divider orientation="vertical" flexItem sx={{ margin: '8px' }} />
        <PreTestSuggestionContainer>
          <Work />
          <Text fontWeight="bold" marginTop="16px">
            Achieve Your Goals:
          </Text>
          <Text marginTop="8px">
            Set realistic goals and develop strategies by understanding your
            strengths and areas for growth, guiding you to success.
          </Text>
        </PreTestSuggestionContainer>
      </PreTestBottomSection>
    </Stack>
  );
};
