import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@ltvco/refresh-lib/theme';
import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { SidenavContainer } from 'components/sidenav/SidenavContainer';
import {
  searchReport,
  SearchReportResponseData,
  mapReportTypesToReportParams,
  useCaptcha,
} from '@ltvco/refresh-lib/v1';
import { useMutation } from '@ltvco/refresh-lib/vendors';
interface CustomWindow extends Window {
  reportVelocityCaptchaCallback?: (response: string) => void;
  hcaptcha?: {
    render: (id: string) => void;
  };
}

const captchaId = 'report-velocity-captcha';

export const ReportVelocityCaptcha: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, captchaReady, sitekey] = useCaptcha({
    customConfigResponse: { session: { reportVelocity: true } },
  });
  const captchaClassName = 'h-captcha';
  const body = location.state?.requestData?.body;
  const permalinkMutation = useMutation({ mutationFn: searchReport });

  useEffect(() => {
    if (!location?.state?.requestData) {
      // This route was likely navigated to directly, so redirect to dashboard
      navigate('/dashboard');
    }
  }, [navigate, location]);

  (window as CustomWindow).reportVelocityCaptchaCallback = async (response) => {
    body['h-captcha-response'] = response;
    const permalink = (
      (await permalinkMutation.mutateAsync(body)) as SearchReportResponseData
    ).report.permalink;
    const reportParam =
      mapReportTypesToReportParams[
        body.report_type as keyof typeof mapReportTypesToReportParams
      ];
    navigate(`/report/${reportParam}?permalink=${permalink}`);
  };

  useEffect(() => {
    if (captchaReady) {
      (window as CustomWindow).hcaptcha?.render(captchaId);
    }
  }, [captchaReady]);

  return (
    <SidenavContainer>
      <Stack
        marginTop={6.5}
        spacing={3}
        alignItems={'center'}
        textAlign={'center'}
        padding={3}
      >
        <Stack alignItems={'center'}>
          <Text variant="h3" fontSize={24}>
            Hey there
          </Text>
          <Text variant="h3" fontSize={24}>
            You've generated a lot of reports
          </Text>
        </Stack>
        <Text width={{ xs: '100%', md: '30%' }} margin={'auto'}>
          Please verify that you're not a robot, and then you can continue
          searching as much as you'd like.
        </Text>
        <div
          className={captchaClassName}
          data-sitekey={sitekey}
          id={captchaId}
          data-callback="reportVelocityCaptchaCallback"
        />
        {!captchaReady && <CircularProgress sx={{ p: 1 }} color="secondary" />}
      </Stack>
    </SidenavContainer>
  );
};
