import { useContext } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  PropertyReport,
  VehicleReport,
  ObituaryReport,
  ContactReport,
  EmailFraudReport,
  PhoneFraudReport,
  UrlFraudReport,
  AncestorReport,
  PersonReport,
  UnclaimedMoneyReport,
  IpFraudReport,
  SocialReport,
  PhoneReport,
  EmailReport,
  ContactReportV2,
} from 'components/reports';
import { Sidenav } from 'components/sidenav/Sidenav';
import {
  AppConfig,
  AppConstants,
  ReportContextProvider,
} from '@ltvco/refresh-lib/ctx';
import { Grid, Box, styled } from '@ltvco/refresh-lib/theme';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { SearchFooter } from '@ltvco/refresh-lib/v2';
import {
  useQueryClient,
  useGrowthBook,
  useMutation,
} from '@ltvco/refresh-lib/vendors';
import {
  type SearchFormProps,
  reportGetters,
  ReportLoading,
  type SearchReportResponseData,
  useSession,
  useReportMonitors,
  UnclaimedMoneyReportLoading,
} from '@ltvco/refresh-lib/v1';
import imgUnclaimedMoney from '../images/img_unclaimed_money.svg';

function useQueryParams(params: string) {
  return useMemo(() => new URLSearchParams(params), [params]);
}

const ReportBody = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',

  [theme.breakpoints.up('md')]: {
    margin: 'auto',
  },

  [theme.breakpoints.up('lg')]: {
    margin: 'auto',
    maxWidth: '1100px',
  },
}));

const FooterSearchContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2.5),

  // Not only the ReportBody, but also the AppContent (App.tsx) are limited to 1600px max width
  // So with this, we make sure the footer search is always 100% of the screen width
  [theme.breakpoints.up(1600)]: {
    width: '100vw',
    // marginLeft: 'calc(-100vw / 2 + 1600px / 2)',
    // marginRight: 'calc(-100vw / 2 + 1600px / 2)',
  },
}));

interface CommonReportProps {
  onSearchSuccess?: SearchFormProps['onSuccess'];
  onSearchError?: SearchFormProps['onError'];
}

export function Report({ onSearchSuccess, onSearchError }: CommonReportProps) {
  const { search } = useLocation();
  const { reportType } = useParams();
  const { logError } = useContext(AppConfig);
  const {
    featureFlags: { propertyReportFlags },
  } = useContext(AppConstants);
  const queryClient = useQueryClient();
  const queryParams = useQueryParams(search);
  const permalinkQuery = queryParams.get('permalink') || '';
  const [permalink, setPermalink] = useState(permalinkQuery);
  const { session, setSession, useAccount } = useSession();
  const { refetch: refetchAccount } = useAccount(session.isAuthenticated);
  const reportGetter = reportGetters[reportType as keyof typeof reportGetters];
  if (typeof reportGetter !== 'function') {
    throw new Error(`Invalid report type: ${reportType}`);
  }
  const { reportIsMonitored, reportMonitorIsLoading } = useReportMonitors();
  const loading = !permalink || reportMonitorIsLoading;

  const { planName } = useLimitedPlanInfo();

  const { isInternationalVinTest } = checkPlanVariations(planName);

  if (reportType === 'property') {
    // Append report flags before generating the new report
    queryParams.set('report_flags', JSON.stringify(propertyReportFlags));
  }

  let growthbook = useGrowthBook();

  const permalinkMutation = useMutation({
    mutationFn: () => reportGetter({ queryParams }),
    onSuccess: async (data: SearchReportResponseData) => {
      const { data: accountData } = await refetchAccount();
      setSession({
        account: accountData,
      });
      setPermalink(data.report.permalink);
    },
    onError: (error: Error, variables, context) => {
      logError('Report Route', error);

      const defaultOptions = queryClient.getDefaultOptions();

      // if mutation/query onError is overridden, we must still call the
      // global handler to make sure backend error codes are handled properly
      if (defaultOptions.mutations && defaultOptions.mutations.onError) {
        defaultOptions.mutations.onError(error, variables, context);
      }
    },
  });

  useEffect(() => {
    if (
      !growthbook?.ready ||
      Object.keys(growthbook?.getAttributes()).length === 0
    ) {
      return;
    }

    if (
      !permalink &&
      !permalinkMutation.isError &&
      !permalinkMutation.isLoading
    ) {
      const isInPartialAddressesVariation = growthbook?.isOn('oar-1075');

      if (reportType === 'person') {
        queryParams.set(
          'showPartialAddresses',
          isInPartialAddressesVariation.toString()
        );
      }
      if (reportType === 'phone') {
        const phoneReportPlanValue = growthbook.getFeatureValue(
          'rev-364',
          'control'
        );

        const phoneReportPlans: { [key: string]: string[] } = {
          control: [],
          emu: ['dd_plan=emu'],
          eider: ['dd_plan=eider'],
        };

        queryParams.set(
          'report_flags',
          JSON.stringify(phoneReportPlans[phoneReportPlanValue] ?? [])
        );
      }

      permalinkMutation.mutate();
    } else if (permalinkQuery && permalink !== permalinkQuery) {
      setPermalink(permalinkQuery);
    }
  }, [permalink, permalinkMutation, permalinkQuery, growthbook]);

  const isOnContactReportV2 = growthbook?.isOn('RFRSH-2652');

  const renderLoadingState = () => {
    if (reportType === 'unclaimed-money') {
      return (
        <UnclaimedMoneyReportLoading
          variation="default"
          topImage={imgUnclaimedMoney}
        />
      );
    }
    return <ReportLoading menuItems={10} />;
  };

  return (
    <ReportContextProvider permalink={permalink} reportType={reportType}>
      <>
        {loading ? (
          renderLoadingState()
        ) : (
          <Grid sx={{ bgcolor: 'background.default' }} container>
            <Sidenav onReport={true} />
            <ReportBody>
              {reportType === 'person' && (
                <PersonReport permalink={permalink} />
              )}

              {reportType === 'phone' && <PhoneReport permalink={permalink} />}
              {reportType === 'property' && (
                <PropertyReport permalink={permalink} />
              )}
              {reportType === 'email' && <EmailReport permalink={permalink} />}
              {reportType === 'username' && (
                <SocialReport permalink={permalink} />
              )}
              {reportType === 'vehicle' && (
                <VehicleReport permalink={permalink} />
              )}
              {reportType === 'contact' && !isOnContactReportV2 && (
                // TODO: Keep this isMonitored here
                <ContactReport permalink={permalink} />
              )}
              {reportType === 'contact' && isOnContactReportV2 && (
                <ContactReportV2
                  permalink={permalink}
                />
              )}
              {reportType === 'email-fraud' && (
                <EmailFraudReport
                  onSearchSuccess={onSearchSuccess}
                  onSearchError={onSearchError}
                  permalink={permalink}
                />
              )}
              {reportType === 'phone-fraud' && (
                <PhoneFraudReport
                  permalink={permalink}
                  onSearchSuccess={onSearchSuccess}
                  onSearchError={onSearchError}
                />
              )}
              {reportType === 'ip-fraud' && (
                <IpFraudReport
                  onSearchSuccess={onSearchSuccess}
                  onSearchError={onSearchError}
                  permalink={permalink}
                />
              )}
              {reportType === 'obituary' && (
                <ObituaryReport permalink={permalink} />
              )}
              {reportType === 'ancestor' && (
                <AncestorReport permalink={permalink} />
              )}
              {reportType === 'unclaimed-money' && (
                <UnclaimedMoneyReport permalink={permalink} />
              )}
              {reportType === 'url-fraud' && (
                <UrlFraudReport
                  onSearchSuccess={onSearchSuccess}
                  onSearchError={onSearchError}
                  permalink={permalink}
                />
              )}
            </ReportBody>
          </Grid>
        )}

        {!isInternationalVinTest && (
          <FooterSearchContainer>
            <SearchFooter
              onSearchSuccess={onSearchSuccess}
              onSearchError={onSearchError}
            />
          </FooterSearchContainer>
        )}
      </>
    </ReportContextProvider>
  );
}
