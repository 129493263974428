import { PersonalityTraitsAnswersProps } from './types';
import { useContext } from 'react';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';

export const usePersonalityTraitsQuestions = () => {
  const { data: tokenData } = useServiceToken('bow_tie');
  const { logError } = useContext(AppConfig);

  return useQuery({
    queryKey: ['bow_questions'],
    queryFn: () => getQuestions(tokenData?.token),
    onError: (error) => {
      // If the request to the Questions fails
      // We set the dqs_completed to avoid a loop of redirect to the onboarding page
      // due we redirect on the auth route
      sessionStorage.setItem('dqs_completed', 'true');
      logError('Error requesting personality traits questions', error as Error);
    },
    enabled: !!tokenData?.token,
    retry: 1,
  });
};

export const getQuestions = async (token: string) => {
  const response = await fetch('/bow/v1/question_set?plan_id=TRAIT_BV_01', {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`status ${response.status}`);
  }

  const questions = await response.json();

  const middleQuestion = {
    page_header: 'Personality Test',
    page: 1,
    screen: 1,
    screen_id: 'TRAIT-BV-01-26',
    question_text: "Halfway There! You're doing great! Ready to...",
    response: {
      answer_set: ['Continue'],
      custom_attribute: 'middle_screen',
      data_type: 'string',
      input_type: 'button',
    },
  };

  const endQuestion = {
    page_header: 'Personality Test',
    page: 1,
    screen: 1,
    screen_id: 'TRAIT-BV-01-52',
    question_text: 'Generating Your Personality Profile, Just a Moment...',
    response: {
      answer_set: ['Finish'],
      custom_attribute: 'end_screen',
      data_type: 'string',
      input_type: 'button',
    },
  };

  const middleIndex = Math.floor(questions.question_set.questions.length / 2);

  // Insert the middle question at the middle index
  questions.question_set.questions.splice(middleIndex, 0, middleQuestion);

  // Update screen_id of subsequent questions
  for (
    let i = middleIndex + 1;
    i < questions.question_set.questions.length;
    i++
  ) {
    const currentQuestion = questions.question_set.questions[i];
    const newScreenId = `TRAIT-BV-01-${String(i + 1).padStart(2, '0')}`;
    currentQuestion.screen_id = newScreenId;
  }

  // Add the end question at the end
  questions.question_set.questions.push(endQuestion);

  return questions;
};

export const usePostTraitsAnswers = () => {
  const { data: tokenData, isLoading: tokenIsLoading } =
    useServiceToken('bow_tie');
  const { logError } = useContext(AppConfig);

  async function postAnswers(answers?: PersonalityTraitsAnswersProps) {
    try {
      const response = await fetch('/bow/v1/answer_sets', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${tokenData?.token}`,
        },
        body: JSON.stringify(answers),
      });

      // As the try catch doesn't catch the 404 422 500 status code
      // we check it manually
      // 422 for this, means an user already submitted the answers
      if (!response.ok) {
        throw new Error(`status ${response.status}`);
      } else {
        return response.json();
      }
    } catch (error) {
      logError('Error posting traits answers', error as Error);
    }
  }

  return {
    postAnswers,
    tokenIsLoading,
  };
};

export const usePersonalityTraitsAnswers = () => {
  const { data: tokenData } = useServiceToken('bow_tie');
  const { logError } = useContext(AppConfig);

  return useQuery({
    queryKey: ['bow_answers'],
    queryFn: async () => await getAnswers(tokenData?.token),
    onError: (error) => {
      // If the request to the Questions fails
      // We set the dqs_completed to avoid a loop of redirect to the onboarding page
      // due we redirect on the auth route
      logError('Error requesting personality traits answers', error as Error);
    },
    enabled: !!tokenData?.token,
    retry: 1,
  });
};

export const getAnswers = async (token: string) => {
  const response = await fetch(
    '/bow/v1/answer_sets/latest?plan_id=TRAIT_BV_01',
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`status ${response.status}`);
  }

  const answers = await response.json();

  return answers;
};
