import React from 'react';
import {
  PersonSearchNoResults,
  type Record,
  ResultCard,
} from '@ltvco/refresh-lib/v1';
import { ConsolidatedSearchResultCard } from '@ltvco/refresh-lib/v2';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
interface PersonCardProps {
  searchPersonResult: Record[];
}

export const PersonCards = ({ searchPersonResult }: PersonCardProps) => {
  if (!searchPersonResult || searchPersonResult.length === 0) {
    return <PersonSearchNoResults />;
  }

  return (
    <>
      {searchPersonResult.map((person: Record, index: number) => {
        const allBvIds = Array.isArray(person.relativesNotSerialized)
          ? person.relativesNotSerialized
              .map((relative) => relative.bvid)
              .join(', ')
          : '';

        return (
          <React.Fragment key={index}>
            <ConsolidatedSearchResultCard
              {...person}
              isConsolidatedSearch={true}
              relativeBvId={allBvIds}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
