import type { Session } from '@ltvco/refresh-lib/v1';

export function hasRunReports({ session }: { session: Session }) {
  const retentionInfo = session?.account?.account?.retention_info;
  if (retentionInfo) {
    return (
      retentionInfo.ran_email_search ||
      retentionInfo.ran_people_search ||
      retentionInfo.ran_property_search ||
      retentionInfo.ran_reverse_phone_search ||
      retentionInfo.ran_username_search ||
      retentionInfo.ran_vehicle_search
    );
  }

  return false;
}
