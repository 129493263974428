import {
  Accordion,
  AccordionProps,
  Box,
  Button,
  styled,
  TabsNavigation,
} from '@ltvco/refresh-lib/theme';

interface FilterAccordionProps extends AccordionProps {
  shouldDisplayReportSearch?: boolean;
}

export const FilterAccordion = styled((props: FilterAccordionProps) => (
  <Accordion defaultExpanded disableGutters square {...props} />
))(({ theme, shouldDisplayReportSearch }) => ({
  margin: 0,
  border: 0,
  color: theme.palette.text.primary,

  '&:hover': {
    boxShadow: 'none',
  },

  '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },

  '& .MuiAccordionSummary-content .MuiTypography-root': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
    color: shouldDisplayReportSearch
      ? `${theme.palette.primary.main} !important`
      : theme.palette.text.primary,
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: `0 ${theme.spacing(2)}`,
  width: '90%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const StyledTabsNavigation = styled(TabsNavigation)`
  .MuiTab-root {
    min-height: 50px;
    padding: 0;
    width: 50%;

    @media (min-width: 767px) {
      padding: 0 20px;
    }

    @media (min-width: 1218px) {
      padding: 0;
    }
  }
`;

export const SidebarContainer = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  '& div:first-of-type': {
    paddingTop: '2px',
  },
  [theme.breakpoints.up('md')]: {
    '& div:first-of-type': {
      paddingTop: 0,
    },
  },
}));
