export type PersonalityTraitsTestHashMap = {
  [key: string]: string;
};

export type PersonalityTraitsTooltipsCopyHashMap = {
  [key: string]: string;
};

export type PersonalityTraitsHashMap = {
  [key: string]: {
    [key: string]: string;
  };
};

export const personalityTestTraitsHashMap: PersonalityTraitsTestHashMap = {
  bigFive: 'Big Five',
  disc: 'DISC',
  fourAspects: 'Four Aspects',
};

export const personalityTraitsTooltipsCopyHashMap: PersonalityTraitsTooltipsCopyHashMap =
  {
    bigFive:
      'The Big Five Aspects Scale is a personality test that measures five traits: neuroticism, agreeableness, conscientiousness, extraversion, and openness/intellect.',
    disc: 'The DISC test is a personality and behavioral self-assessment tool that categorizes people into four personality traits: dominance, influence, stability, and compliance.',
    fourAspects:
      'The Four Aspects test is a personality self-assessment that uses the unique combination of four scales to determine a personality type. The four scales are: outgoingness (introversion vs. extraversion), creativity vs. realism, bias vs. objectivity, and spontaneity vs. realism.',
  };

export const personalityTraitsHashMap: PersonalityTraitsHashMap = {
  bigFive: {
    neuroticism: 'Neuroticism',
    agreeableness: 'Agreeableness',
    conscientiousness: 'Conscientiousness',
    extraversion: 'Extraversion',
    openness: 'Open Mindedness',
  },
  disc: {
    dominance: 'Dominance',
    influence: 'Influence',
    stability: 'Stability',
    compliance: 'Compliance',
  },
  fourAspects: {
    extraversion2: 'Extraversion',
    realism: 'Realism',
    objectivity: 'Objectivity',
    planning: 'Planning',
  },
};
