import { PersonalityTestResponse } from './types';

export type PersonalityTestScore = {
  [key: string]: number;
};

export type PersonalityTestScoreAnalysis = {
  [key: string]: string;
};

export type PersonalityScore = {
  [key: string]: PersonalityTestScore;
};

export const usePersonalityScore = (response: PersonalityTestResponse) => {
  const responseMap = {
    'Strongly Disagree': 0,
    Disagree: 1,
    Neutral: 2,
    Agree: 3,
    'Strongly Agree': 4,
  } as { [response: string]: number };

  const createAnswersMap = () => {
    const answersMap = {} as { [custom_attribute: string]: number };

    for (let index = 0; index < response.answers.length; index++) {
      const element = response.answers[index];
      answersMap[element.custom_attribute] = responseMap[element.response];
    }

    return answersMap;
  };

  const scoringQuestionsMap = {
    bigFiveNeuroticism: [
      'trait_bigfive_neur_v1',
      'trait_bigfive_neur_v2',
      'trait_bigfive_neur_w1',
      'trait_bigfive_neur_w2',
    ],
    bigFiveAgreeableness: [
      'trait_bigfive_agre_c1',
      'trait_bigfive_agre_c2',
      'trait_bigfive_agre_p1',
      'trait_bigfive_agre_p2',
    ],
    bigFiveConscientiousness: [
      'trait_bigfive_cons_i1',
      'trait_bigfive_cons_i2',
      'trait_bigfive_cons_o1',
      'trait_bigfive_cons_o2',
    ],
    bigFiveExtraversion: [
      'trait_bigfive_extr_e1',
      'trait_bigfive_extr_e2',
      'trait_bigfive_extr_a1',
      'trait_bigfive_extr_a2',
    ],
    bigFiveOpenness: [
      'trait_bigfive_open_i1',
      'trait_bigfive_open_i2',
      'trait_bigfive_open_o1',
      'trait_bigfive_open_o2',
    ],
    discDominance: [
      'trait_disc_domi1',
      'trait_disc_domi2',
      'trait_disc_domi3',
      'trait_disc_domi4',
      'trait_disc_domi5',
      'trait_disc_domi6',
      'trait_disc_domi7',
    ],
    discInfluence: [
      'trait_disc_influ1',
      'trait_disc_influ2',
      'trait_disc_influ3',
      'trait_disc_influ4',
      'trait_disc_influ5',
      'trait_disc_influ6',
      'trait_disc_influ7',
      'trait_disc_influ8',
    ],
    discStability: [
      'trait_disc_stea1',
      'trait_disc_stea2',
      'trait_disc_stea3',
      'trait_disc_stea4',
      'trait_disc_stea5',
      'trait_disc_stea6',
      'trait_disc_stea7',
    ],
    discCompliance: [
      'trait_disc_cons1',
      'trait_disc_cons2',
      'trait_disc_cons3',
      'trait_disc_cons4',
      'trait_disc_cons5',
      'trait_disc_cons6',
      'trait_disc_cons7',
      'trait_disc_cons8',
    ],
    fourAspectsExtraversion: [
      'trait_bigfive_extr_e1',
      'trait_bigfive_extr_e2',
      'trait_disc_domi2',
      'trait_disc_domi4',
      'trait_disc_influ3',
      'trait_disc_influ4',
      'trait_disc_influ7',
      'trait_disc_influ8',
    ],
    fourAspectsRealism: [
      'trait_disc_stea2',
      'trait_disc_stea3',
      'trait_disc_cons1',
      'trait_disc_cons4',
      'trait_disc_cons5',
      'trait_disc_cons6',
      'trait_disc_cons7',
      'trait_disc_cons8',
    ],
    fourAspectsObjectivity: [
      'trait_bigfive_neur_v2',
      'trait_bigfive_neur_w2',
      'trait_bigfive_agre_c1',
      'trait_bigfive_cons_o2',
      'trait_bigfive_open_o1',
      'trait_disc_influ5',
      'trait_disc_stea5',
      'trait_disc_cons2',
    ],
    fourAspectsPlanning: [
      'trait_bigfive_cons_i1',
      'trait_bigfive_cons_i2',
      'trait_bigfive_cons_o1',
      'trait_bigfive_extr_a1',
      'trait_bigfive_extr_a2',
      'trait_disc_domi1',
      'trait_disc_domi3',
      'trait_disc_cons3',
    ],
  } as { [category: string]: string[] };

  const answersMap = createAnswersMap();

  const calculateScore = (category: string) => {
    const scores: number[] = scoringQuestionsMap[category].map(
      (questionCustomAttribute) => answersMap[questionCustomAttribute]
    );
    return (
      scores.reduce((acc: number, el: number) => acc + el, 0) /
      (scoringQuestionsMap[category].length * 4)
    );
  };

  const analyzeDiscScore = (score: number) => {
    if (score >= 0.83) {
      return 6;
    } else if (score >= 0.66) {
      return 5;
    } else if (score >= 0.5) {
      return 4;
    } else if (score >= 0.33) {
      return 3;
    } else if (score >= 0.16) {
      return 2;
    } else {
      return 1;
    }
  };

  const analyzeFourAspectsScore = (score: number, category: string) => {
    switch (category) {
      case 'extraversion2':
        if (score >= 0.5) {
          return 'extravert';
        } else {
          return 'intravert';
        }
      case 'realism':
        if (score >= 0.5) {
          return 'realist';
        } else {
          return 'creative';
        }
      case 'objectivity':
        if (score >= 0.5) {
          return 'objective';
        } else {
          return 'biased';
        }
      case 'planning':
        if (score >= 0.5) {
          return 'planned';
        } else {
          return 'spontaneous';
        }
      default:
        return '';
    }
  };

  return {
    scores: {
      bigFive: {
        neuroticism: calculateScore('bigFiveNeuroticism'),
        agreeableness: calculateScore('bigFiveAgreeableness'),
        conscientiousness: calculateScore('bigFiveConscientiousness'),
        extraversion: calculateScore('bigFiveExtraversion'),
        openness: calculateScore('bigFiveOpenness'),
      } as PersonalityTestScore,
      disc: {
        dominance: calculateScore('discDominance'),
        influence: calculateScore('discInfluence'),
        stability: calculateScore('discStability'),
        compliance: calculateScore('discCompliance'),
      } as PersonalityTestScore,
      fourAspects: {
        extraversion2: calculateScore('fourAspectsExtraversion'),
        realism: calculateScore('fourAspectsRealism'),
        objectivity: calculateScore('fourAspectsObjectivity'),
        planning: calculateScore('fourAspectsPlanning'),
      } as PersonalityTestScore,
    } as PersonalityScore,
    analysis: {
      discAnalysis: {
        dominance: analyzeDiscScore(calculateScore('discDominance')),
        influence: analyzeDiscScore(calculateScore('discInfluence')),
        stability: analyzeDiscScore(calculateScore('discStability')),
        compliance: analyzeDiscScore(calculateScore('discCompliance')),
      },
      fourAspectsAnalysis: {
        extraversion2: analyzeFourAspectsScore(
          calculateScore('fourAspectsExtraversion'),
          'extraversion2'
        ),
        realism: analyzeFourAspectsScore(
          calculateScore('fourAspectsRealism'),
          'realism'
        ),
        objectivity: analyzeFourAspectsScore(
          calculateScore('fourAspectsObjectivity'),
          'objectivity'
        ),
        planning: analyzeFourAspectsScore(
          calculateScore('fourAspectsPlanning'),
          'planning'
        ),
      },
    },
  };
};
