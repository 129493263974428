import { RawFamilySearchPersonResults } from '@ltvco/refresh-lib/fmt';
import { PersonSearchNoResults } from '@ltvco/refresh-lib/v1';
import { AdvancedSearchURLParams } from '@ltvco/refresh-lib/v2';
import { FamilySearchResults } from '../FamilySearchResults';
import { PersonCardLoading } from '../Loading/PersonCardLoading';

interface FamilySearchResultsContainerProps {
  searchData: AdvancedSearchURLParams;
  results: RawFamilySearchPersonResults | undefined;
}

export function FamilySearchResultsContainer({
  searchData,
  results,
}: FamilySearchResultsContainerProps) {
  const counts = results?.meta.counts.options.people ?? 0;

  return !results ? (
    <PersonCardLoading />
  ) : counts === 0 ? (
    <PersonSearchNoResults />
  ) : (
    <FamilySearchResults
      familySearchInfo={searchData}
      familySearchData={results}
    />
  );
}
