import { Stack } from '@ltvco/refresh-lib/theme';
import { MyGarageContainer } from '@ltvco/refresh-lib/v1';
import { useEffect } from 'react';

export function MyGarage() {
  useEffect(() => {
    document.title = 'My Vehicle - BeenVerified';
  }, []);

  return (
    <Stack justifyContent={'space-around'} sx={{ ml: 0, mt: 1, px: 1 }}>
      <MyGarageContainer />
    </Stack>
  );
}
