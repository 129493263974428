import { CollectionType, OptionType } from '@ltvco/refresh-lib/v2';
import {
  NavButton,
  OptionResults,
  SearchHeader,
} from 'routes/SearchPerson/components';
import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { useContext } from 'react';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { capitalizeEachWord } from '@ltvco/refresh-lib/utils';

export interface SearchHeaderProps {
  options: OptionType[];
  selectedOption: CollectionType;
  setSelectedOption: (value: CollectionType) => void;
  searchData: {
    fullName: string;
  };
}

export const SearchHeaderDesktop = ({
  options,
  selectedOption,
  setSelectedOption,
  searchData,
}: SearchHeaderProps) => {
  const capitalizeFullName = capitalizeEachWord(searchData.fullName);
  const { trackEventGA4 } = useContext(AppConfig);

  return (
    <SearchHeader flexDirection="column" alignItems="left" pt={2.5} pb={2.5}>
      <Stack flexDirection="row" flexWrap="wrap" ml={1.25} gap={1}>
        {options
          .filter((option) => option.visible)
          .map((option) => (
            <NavButton
              key={option.value}
              onClick={() => {
                setSelectedOption(option.value);
                trackEventGA4({
                  event_name: 'search_results_tab_click',
                  type: option.label,
                });
              }}
              className={selectedOption === option.value ? 'active' : ''}
            >
              <Stack flexDirection="row" mt={0} alignItems="center">
                <OptionResults
                  className="options-results"
                  sx={{
                    width: parseInt(option.length) > 9 ? '40px' : '25px',
                    marginRight: '5px',
                  }}
                >
                  {option.length ? option.length : '0'}
                </OptionResults>
                <Text variant="body1" m={0}>
                  {option.label}
                </Text>
              </Stack>
            </NavButton>
          ))}
      </Stack>
      <Stack className="results-for" flexDirection="row" ml={1.25} mt={2}>
        <Text
          variant="h3"
          sx={{
            fontWeight: 'normal',
            marginRight: '5px',
            marginBottom: '0',
          }}
        >
          Results for:
        </Text>
        <Text variant="h3" m={0}>
          {capitalizeFullName}
        </Text>
      </Stack>
    </SearchHeader>
  );
};
