import { StyledButton, Container, TextContainer } from './constants';
import { SimpleQuestionSurveyProps } from '../QuestionSurveyService/constants';
import { Text } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { constants } from 'appConstants';
import React from 'react';

export const PersonalityTraitsRadioButton = ({
  answerSet,
  screenId,
  customAttribute,
  storeAnswer,
}: SimpleQuestionSurveyProps) => {
  const { isMobile } = useScreenSize();
  return (
    <Container>
      {answerSet.map((answer: string, index: number) => (
        <React.Fragment key={answer}>
          <StyledButton
            color="primary"
            variant="outlined"
            onClick={() =>
              storeAnswer({
                screen_id: screenId,
                custom_attribute: customAttribute,
                response: answer,
              })
            }
            sx={{
              borderColor:
                constants.personalityTraitsColors.radioButtonColors[index],
              '&:hover': {
                border: `1px solid ${constants.personalityTraitsColors.radioButtonColors[index]} !important`,
                backgroundColor: isMobile
                  ? 'inherit'
                  : constants.personalityTraitsColors.radioButtonHoverColors[
                      index
                    ],
              },
              '&:active': {
                border: `1px solid ${constants.personalityTraitsColors.radioButtonColors[index]} !important`,
                backgroundColor:
                  constants.personalityTraitsColors.radioButtonHoverColors[
                    index
                  ],
              },
            }}
          >
            <Text
              variant="h4"
              color={constants.personalityTraitsColors.radioButtonColors[index]}
              mb={0}
            >
              {answer}
            </Text>
          </StyledButton>
        </React.Fragment>
      ))}
    </Container>
  );
};
