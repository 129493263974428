import { Stack } from '@ltvco/refresh-lib/theme';
import { useContext } from 'react';
import { constants } from 'appConstants';
import {
  type ContactCompany,
  type ContactDetail,
  NoContactResults,
  type SerializedContactTeaserData,
  ContactResult,
  openReportInNewTab,
  useReportRedirect,
} from '@ltvco/refresh-lib/v1';
import { ProfessionalCard } from '@ltvco/refresh-lib/v2';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';

interface ContactCardProps {
  searchContactResult: SerializedContactTeaserData;
  fullName?: string;
  company?: string;
}

export const ContactCards = ({
  searchContactResult,
  fullName,
  company,
}: ContactCardProps) => {
  const { contacts = [] } = searchContactResult;
  const { routingUtils } = useContext(AppConfig);
  const { redirectToSearchContactUrl } = useReportRedirect();

  const getCompanyUrl = (company: string) =>
    routingUtils.searchContactUrl({ company: company });

  const handleCompanyClick = (company: string | ContactCompany) => {
    if (typeof company === 'string') {
      redirectToSearchContactUrl({ company });

      return;
    }

    redirectToSearchContactUrl({ company: company.name });
  };

  const handleViewReport = (contactId: string) => {
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };

  if (!contacts) {
    return <NoContactResults />;
  }

  return (
    <Stack spacing={2}>
      {contacts.map((result: ContactDetail) => (
        <ProfessionalCard
          key={result.id}
          {...result}
          nameMatch={
            result.name.toLowerCase() === (fullName || '').toLowerCase()
          }
          companyToMatch={company || ''}
          onCompanyClick={handleCompanyClick}
          getCompanyUrl={getCompanyUrl}
          onViewReport={handleViewReport}
          subsection=""
        />
      ))}
    </Stack>
  );
};
