import { useSearchParams } from 'react-router-dom';
import { Stack, Text, Box } from '@ltvco/refresh-lib/theme';
import { RapidsInput, useReportRedirect } from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getSearchQueryParams,
} from '@ltvco/refresh-lib/v2';
import { RapidsSearchData } from '../SearchFilter/interfaces';
import { constants } from 'appConstants';

const SearchLogo = function SearchLogo() {
  const searchLogo = constants.images.searchLogo;

  return (
    <picture style={{ display: 'inline-block' }}>
      <img src={searchLogo} alt="Search Logo" width={40} height={40} />
    </picture>
  );
};

export function ResidentNoResults() {
  const [searchParams] = useSearchParams();
  const { redirectToSearchPersonUrlv2 } = useReportRedirect();
  const searchData: AdvancedSearchURLParams =
    getSearchQueryParams(searchParams);

  const handleSearchLocation = (propertyData: RapidsSearchData) => {
    const { city, state, zipcode, street_line } = propertyData;
    const data = {
      ...searchData,
      city: '',
      state: '',
      address: '',
    };
    let locationValue = '';
    if (zipcode && state && city && street_line) {
      locationValue = `${street_line} ${city}, ${state} ${zipcode}`;
      data.location;
      data.address = locationValue;
      data.state = state;
      data.city = city;
    } else {
      data.state = state;
      data.city = city;
    }

    redirectToSearchPersonUrlv2(data);
  };

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <SearchLogo />

      <Text variant="h5" fontWeight={400} display="inline-block">
        No results found try removing filters or try searching again
      </Text>
      <Box width={'75%'}>
        <RapidsInput
          parentFormSubmitHandler={handleSearchLocation}
          includeCounties
          updateEmptyOptions
          inputOptions={{
            placeholder: 'City, County, or State',
            hideSearchButton: true,
            hideInputLabel: true,
            customStyles: {
              width: '100%',
              '.MuiGrid-root': { width: '100%' },
              '.MuiStack-root': { display: 'none' },
            },
          }}
        />
      </Box>
    </Stack>
  );
}
