import React from 'react';
import { Record, useSearchPerson } from '@ltvco/refresh-lib/v1';
import {
  AdvancedSearchURLParams,
  getPersonSearchParams,
} from '@ltvco/refresh-lib/v2';

interface PersonFetcherProps {
  searchData: AdvancedSearchURLParams;
  setPersonResults: (results: Record[] | undefined) => void;
}

export function PersonFetcher({
  searchData,
  setPersonResults,
}: PersonFetcherProps) {
  const { data, isFetched, isError } = useSearchPerson({
    searchPersonBy: 'name',
    options: {
      personSearchParams: getPersonSearchParams(searchData),
      isOptout: false,
    },
  });

  const result = data as Record[] | undefined;

  if (isFetched && !isError) {
    setPersonResults(result);
  } else {
    setPersonResults(undefined);
  }

  return <React.Fragment />;
}
