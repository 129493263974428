import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useAccountLists, AccountLists } from '@ltvco/refresh-lib/v1';
import { useEffect } from 'react';
import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { FormatListBulleted } from '@mui/icons-material';

export function Lists() {
  const { data: lists, isLoading } = useAccountLists();
  const isInListV2Variation = useFeatureIsOn('prop-2834');

  useEffect(() => {
    document.title = 'Report Lists - BeenVerified';
  }, []);

  if (isInListV2Variation) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormatListBulleted
          color="primary"
          sx={{ marginRight: '4px', height: '30px', width: '30px' }}
          fontSize="large"
        />
        <Text variant="h1" my={0}>
          My Lists
        </Text>
      </Stack>
    );
  }

  return <AccountLists lists={lists || []} isBrandLoading={isLoading} />;
}
