import {
  PersonalityTraitsRadioButton,
  PersonalityTraitsButton,
  PersonalityTraitsQuestionsProps,
} from '../index';

export type ActiveComponentProps = {
  radio: React.ComponentType<SimpleQuestionSurveyProps>;
  button: React.ComponentType<SimpleQuestionSurveyProps>;
};

export const ActiveComponent: ActiveComponentProps = {
  radio: PersonalityTraitsRadioButton,
  button: PersonalityTraitsButton,
};

export interface QuestionsSurveyProps {
  questionsSet: PersonalityTraitsQuestionsProps;
  onTimeoutComplete: () => void;
}

export interface SimpleQuestionSurveyProps {
  /** Array of answers to the question */
  answerSet: string[];
  /** ID value is generated generated from the question plan and screen number */
  screenId: string;
  /** ID name of the question */
  customAttribute: string;
  /** Function to store the answer in the  Wizard's answersStorage */
  storeAnswer: (answerData: {
    screen_id: string;
    custom_attribute: string;
    response: string | string[] | null;
  }) => void;
}
