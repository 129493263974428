import { Box, styled } from '@ltvco/refresh-lib/theme';

export const SearchHeaderContainerCarousel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  zIndex: '2',
  backgroundColor: theme.palette.primary.contrastText,
  paddingTop: '15px',
  paddingBottom: '15px',
  top: '150px',
  behavior: 'smooth',
  [theme.breakpoints.up('sm')]: {
    top: '148px',
    padding: '15px',
  },
}));
