import { Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import { PersonalityTraitsTestResults } from './PersonalityTraitsTestResults/PersonalityTraitsResults';
import { PersonalityScore } from '../usePersoniltyScore/usePersonailyScore';
import { Refresh } from '@mui/icons-material';

type PersonalityTraitsResultsContainerProps = {
  personalityScores: PersonalityScore;
  fourAspectsAnalysis: any;
  retakeTest: () => void;
};

export const PersonalityTraitsResultsContainer = ({
  personalityScores,
  fourAspectsAnalysis,
  retakeTest,
}: PersonalityTraitsResultsContainerProps) => {
  const renderAllPersonalityTraits = () => {
    return Object.keys(personalityScores).map((key) => {
      if (key === 'fourAspects') {
        return (
          <>
            <PersonalityTraitsTestResults
              key={key}
              personalityTraitsResults={personalityScores[key]}
              personalityTraitsResultKey={key}
              fourAspectsAnalysis={fourAspectsAnalysis}
            />
          </>
        );
      } else {
        return (
          <PersonalityTraitsTestResults
            key={key}
            personalityTraitsResults={personalityScores[key]}
            personalityTraitsResultKey={key}
          />
        );
      }
    });
  };

  return (
    <Stack width="100%">
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        margin="16px"
        alignItems="flex-start"
      >
        <Text variant="h2">Your personality profile</Text>
        <Button
          variant="outlined"
          size="small"
          onClick={retakeTest}
          sx={(theme) => ({
            width: 'max-content',
            textWrap: 'nowrap',
            [theme.breakpoints.down('sm')]: {
              width: '50%',
            },
          })}
        >
          <Refresh />{' '}
          {localStorage.getItem('answersStorage')
            ? 'Continue Test'
            : 'Retake Test'}
        </Button>
      </Stack>
      {renderAllPersonalityTraits()}
    </Stack>
  );
};
